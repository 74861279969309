import { toast } from "@/components/ui/toast";
import { standardAuthFetch } from "@/data-access/core/authenticatedFetch";
import {
  dataServiceBaseUrl,
  wsServiceBaseUrl,
} from "@/data-access/core/constants";
import { getAuthToken } from "@/data-access/core/tokenManagement";
import { devtoolsExchange } from "@urql/devtools";
import { createClient as createWSClient } from "graphql-ws";
import {
  Client,
  type Exchange,
  type Operation,
  Provider,
  cacheExchange,
  fetchExchange,
  mapExchange,
  subscriptionExchange,
} from "urql";
import { pipe, tap } from "wonka";

// Create a custom exchange to track operations by their ID
const requestIdExchange: Exchange =
  ({ forward }) =>
  (ops$) => {
    return pipe(
      ops$,
      tap((operation: Operation) => {
        // Only for queries and mutations
        if (operation.kind === "query" || operation.kind === "mutation") {
          // Get operation name safely
          let opName = "unknown";
          const opDefs = operation.query.definitions;
          const firstOpDef = opDefs[0];
          if (firstOpDef) {
            try {
              if (
                firstOpDef.kind === "OperationDefinition" &&
                firstOpDef.name
              ) {
                opName = firstOpDef.name.value;
              }
            } catch (e) {
              console.error("Error getting operation name:", e);
            }
          }

          // Get feedId if it exists
          const feedId = operation.variables?.feedId || "none";

          // Create consistent request ID
          const requestId = `${opName}:${feedId}`;

          // Store the request ID in the operation context
          operation.context = {
            ...operation.context,
            requestId,
          };

          // Log the request ID for debugging
          console.log(
            `Operation ${opName} with feedId ${feedId} has request ID ${requestId}`,
          );
        }
      }),
      forward,
    );
  };

// Instead of adding a custom header, we'll add the request ID to the query variables
const requestIdVariableExchange: Exchange =
  ({ forward }) =>
  (ops$) => {
    return pipe(
      ops$,
      tap((operation: Operation) => {
        if (operation.kind === "query" || operation.kind === "mutation") {
          // Get the request ID from the context
          const requestId = operation.context.requestId;

          if (requestId) {
            // Add the request ID to the query variables
            operation.variables = {
              ...operation.variables,
              // Use a variable name that won't conflict with existing variables
              __requestId: requestId,
            };
          }
        }
      }),
      forward,
    );
  };

//https://the-guild.dev/graphql/ws/recipes#ws-server-and-client-auth-usage-with-token-expiration-validation-and-refresh
const wsClient = createWSClient({
  url: `${wsServiceBaseUrl}/graphql/`,
  connectionParams: async () => {
    try {
      const accessToken = await getAuthToken();

      if (!accessToken) {
        return {};
      }

      return {
        headers: {
          token: `Bearer ${accessToken}`,
        },
      };
    } catch (err) {
      console.error("Error getting WS connection params:", err);
      return {};
    }
  },
  on: {
    closed: console.error,
    connected: (socket: unknown) => {
      console.info("client connected.", socket);
    },
    error: (err) => {
      console.log(err);
    },
  },
});

// Create a function to create a new URQL client
const createUrqlClient = () => {
  return new Client({
    url: `${dataServiceBaseUrl}/graphql/`,
    exchanges: [
      requestIdExchange,
      requestIdVariableExchange,
      devtoolsExchange,
      cacheExchange,
      mapExchange({
        onError(error) {
          const errorMessages = error.graphQLErrors.map((err) => err.message);
          if (error.networkError) {
            errorMessages.push(error.networkError.message);
          }
          if (errorMessages.length > 0) {
            toast.error(errorMessages);
          }

          console.error(error.stack);
        },
      }),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription(request) {
          const input = { ...request, query: request.query || "" };
          return {
            subscribe(sink) {
              const unsubscribe = wsClient.subscribe(input, sink);
              return { unsubscribe };
            },
          };
        },
      }),
    ],
    // Use the original standardAuthFetch without modifications
    fetch: standardAuthFetch,
  });
};

// Create the initial URQL client
export let client = createUrqlClient();

// Add reset capability to clear URQL cache and state
export const resetClient = async () => {
  try {
    // Close websocket connection
    wsClient.terminate();

    client = createUrqlClient();
    return true;
  } catch (error) {
    console.error("Error resetting URQL client:", error);
    return false;
  }
};

const UrqlProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <Provider value={client}>{children}</Provider>;

export default UrqlProvider;
