import { toast } from "@/components/ui/toast";
import { apiEndpointBaseUrl } from "@/data-access/core/constants";
import { clearAllStores } from "@/data-access/core/createStore";
import { decodeToken } from "@/lib/auth/tokenUtils";
import useAuth from "@/store/auth.slice";
import { useEffect, useRef } from "react";

/**
 * Handles session expiration by clearing all stores and redirecting to login
 * This function is separated to avoid circular dependencies
 */
export const handleSessionExpired = () => {
  // Clear all stores and redirect to login
  clearAllStores("/auth/login");

  // Notify the user
  toast.error("Your session has expired. Please log in again.");
};

const REFRESH_BUFFER_MS = 60000; // Refresh token 1 minute before expiration

export const useTokenManager = () => {
  const refreshTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const loggedIn = useAuth((state) => state.loggedIn);

  const clearRefreshTimeout = () => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }
  };

  const refreshToken = async () => {
    const { refreshToken } = useAuth.getState();
    if (!refreshToken) {
      handleSessionExpired();
      return;
    }

    try {
      const response = await fetch(
        `${apiEndpointBaseUrl}/auth/token/refresh/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refresh: refreshToken }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const data = await response.json();
      useAuth.setState({
        accessToken: data.access,
        refreshToken: data.refresh || refreshToken, // Keep existing refresh token if not provided
      });

      // Set up the next refresh cycle
      setupRefreshTimer();
    } catch (error) {
      console.error("Token refresh failed:", error);
      handleSessionExpired();
    }
  };

  const setupRefreshTimer = () => {
    clearRefreshTimeout();

    const { accessToken } = useAuth.getState();
    if (!accessToken) return;

    const decoded = decodeToken(accessToken);
    if (!decoded) return;

    const expirationMs = decoded.exp * 1000; // Convert to milliseconds
    const timeUntilRefresh = expirationMs - Date.now() - REFRESH_BUFFER_MS;

    if (timeUntilRefresh <= 0) {
      // Token is already expired or will expire very soon
      refreshToken();
    } else {
      refreshTimeoutRef.current = setTimeout(refreshToken, timeUntilRefresh);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      setupRefreshTimer();
    }

    return () => {
      clearRefreshTimeout();
    };
  }, [loggedIn]);
};
