import { Menu, MenuPopover, MenuSeparator } from "@/components/ui/menu";
import { CustomMenuItem, type MenuItemConfig } from "@/components/ui/menu-item";
import { updateKeyMessage } from "@/store/key-messages/key-message-async.actions";
import {
  setCreateKeyMessageGroupModalOpen,
  setCreateKeyMessageModalOpen,
  setEditingKeyMessageId,
  setExpandedSearchTerm,
  setIsEditingKeyMessage,
  setIsEditingKeyMessageGroup,
  setKeyMessageGroupName,
  setKeyMessageName,
  setSelectedGroup,
} from "@/store/key-messages/key-message.actions";
import type {
  KeyMessage,
  KeyMessageGroup,
} from "@/store/key-messages/key-message.slice";
import {
  Folder,
  FolderMinus,
  FolderPlus,
  Intersect,
  Pencil,
  PencilSimpleLine,
  Plus,
  Trash,
} from "@phosphor-icons/react";
import { Fragment } from "react";

interface GroupHeaderMenuProps {
  keyMessageGroups: KeyMessageGroup[];
  setCreateKeyMessageModalOpen: (b: boolean) => void;
  group: KeyMessageGroup;
  setSelectedGroup: (group: { id: string; name: string } | null) => void;
}

interface FilterMenuProps {
  item: KeyMessage;
  keyMessageGroups: KeyMessageGroup[];
  feedId: string;
  setCreateKeyMessageModalOpen: (b: boolean) => void;
  setCreateKeyMessageGroupModalOpen: (b: boolean) => void;
  setSelectedGroup: (group: { id: string; name: string } | null) => void;
}

const createSubMenuItems = (
  keyMessageGroups: KeyMessageGroup[],
  item: KeyMessage,
  feedId: string,
) => [
  ...keyMessageGroups.map((group) => ({
    id: group.id,
    name:
      group.name === "Ungrouped" ? (
        <span className="italic text-gray-700">
          <span className="text-gray-200">&mdash;</span> None{" "}
          <span className="text-gray-200">&mdash;</span>
        </span>
      ) : (
        group.name
      ),
    icon: group.name !== "Ungrouped" && <Folder className="text-gray-600" />,
    checked: item.group?.id === group.id,
    onItemClick: (id: string) => {
      const input = {
        itemId: item.id,
        groupId: item.group ? id : null,
        feedId,
      };
      updateKeyMessage(input);
    },
  })),
  {
    id: "new",
    name: "New group",
    icon: <FolderPlus className="text-gray-600" />,
    onItemClick: () => setCreateKeyMessageGroupModalOpen(true),
  },
];

const GroupHeaderMenu = ({ keyMessageGroups, group }: GroupHeaderMenuProps) => {
  const menuItems: MenuItemConfig[] = [
    {
      icon: <PencilSimpleLine className="text-gray-600" />,
      label: "Rename group",
      onClick: () => {
        setSelectedGroup(group);
        setCreateKeyMessageGroupModalOpen(true);
        setIsEditingKeyMessageGroup(true);
        setKeyMessageGroupName(group.name);
      },
    },
    {
      icon: <Plus className="text-gray-600" />,
      label: "New key message",
      onClick: () => {
        setCreateKeyMessageModalOpen(true);
        setSelectedGroup(group);
      },
    },
    {
      icon: <Intersect className="text-gray-600" />,
      label: "Merge with group",
      subMenu: {
        items: keyMessageGroups,
      },
    },
    {
      icon: <Trash className="text-red-750" />,
      label: "Delete group",
    },
  ];

  return (
    <MenuPopover className="bg-red" placement="left">
      <Menu>
        {menuItems.map((item, index) => (
          <Fragment key={item.label}>
            <CustomMenuItem config={item} />
            {index === menuItems.length - 2 && (
              <MenuSeparator className="mx-1" />
            )}
          </Fragment>
        ))}
      </Menu>
    </MenuPopover>
  );
};

const FilterMenu = ({ feedId, item, keyMessageGroups }: FilterMenuProps) => {
  const subMenuItems = createSubMenuItems(keyMessageGroups, item, feedId);

  const menuItems: MenuItemConfig[] = [
    {
      icon: <Pencil className="text-gray-600" />,
      label: "Edit",
      onClick: () => {
        setIsEditingKeyMessage(true);
        setCreateKeyMessageModalOpen(true);
        setSelectedGroup(item.group);
        setKeyMessageName(item.name);
        setExpandedSearchTerm(item.searchTerms);
        setEditingKeyMessageId(item.id);
      },
    },
    {
      icon: <Folder className="text-gray-600" />,
      label: item.group ? "Move to group" : "Add to group",
      subMenu: {
        items: subMenuItems,
      },
      separator: true,
    },
    ...(item.group
      ? [
          {
            icon: <FolderMinus className="text-gray-600" />,
            label: "Remove from group",
          },
        ]
      : []),
    {
      icon: <Trash className="text-red-750" />,
      label: "Delete key message",
    },
  ];

  return (
    <MenuPopover placement="end">
      <Menu>
        {menuItems.map((menuItem, index) => (
          <Fragment key={menuItem.label}>
            <CustomMenuItem config={menuItem} />
            {index === menuItems.length - 2 && (
              <MenuSeparator className="mx-1" />
            )}
          </Fragment>
        ))}
      </Menu>
    </MenuPopover>
  );
};

FilterMenu.displayName = "FilterMenu";
GroupHeaderMenu.displayName = "GroupHeaderMenu";

export { FilterMenu, GroupHeaderMenu };
