import { trimKeyMessageName } from "@/store/key-messages/key-message.selectors";
import useKeyMessageStore from "./key-message.slice";

export const setCreateKeyMessageGroupModalOpen = (isOpen: boolean) => {
  useKeyMessageStore.setState(
    { isCreateKeyMessageGroupModalOpen: isOpen },
    false,
    "[User] KeyMessage Group: toggle key message group modal",
  );
};

export const setSaveSearchKeyMessageModalOpen = (isOpen: boolean) => {
  useKeyMessageStore.setState(
    { isSaveSearchKeyMessageModalOpen: isOpen },
    false,
    "[User] KeyMessage: Toggle set save key message modal",
  );
};

export const setCreateKeyMessageModalOpen = (isOpen: boolean) => {
  useKeyMessageStore.setState(
    { isCreateKeyMessageModalOpen: isOpen },
    false,
    "[User] KeyMessage: Toggle create key message modal",
  );
};

export const setSelectedKeyMessageIds = (
  updateFn: (prevSelected: Set<string>) => Set<string>,
) => {
  useKeyMessageStore.setState((state) => ({
    selectedKeyMessageIds: updateFn(new Set(state.selectedKeyMessageIds)),
  }));
};

export const setKeyMessageName = (name: string) =>
  useKeyMessageStore.setState({ keyMessageName: name });

export const setExpandedSearchTerm = (term: string) =>
  useKeyMessageStore.setState({ expandedSearchTerm: term });

export const setIsAdvancedSearch = (isAdvancedSearch: boolean) => {
  useKeyMessageStore.setState((state) => ({
    isAdvancedSearch,
    keyMessageName: isAdvancedSearch
      ? trimKeyMessageName(state.keyMessageName)
      : state.keyMessageName,
  }));
};

export const setSelectedGroup = (group: { name: string; id: string } | null) =>
  useKeyMessageStore.setState({ selectedGroup: group });

export const setIsEditingKeyMessageGroup = (isEditing: boolean) =>
  useKeyMessageStore.setState({ isEditingKeyMessageGroup: isEditing });

export const setKeyMessageGroupName = (name: string) =>
  useKeyMessageStore.setState({ keyMessageGroupName: name });

export const setIsEditingKeyMessage = (isEditing: boolean) =>
  useKeyMessageStore.setState({ isEditingKeyMessage: isEditing });

export const setEditingKeyMessageId = (id: string | null) =>
  useKeyMessageStore.setState({ editingKeyMessageId: id });

export const setSelectedGroups = (groups: Set<string>) =>
  useKeyMessageStore.setState({ selectedGroups: groups });
