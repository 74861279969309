import {
  SearchField,
  SearchFieldClear,
  SearchFieldInput,
} from "@/components/ui/controls/searchfield";
import { MagnifyingGlass, X } from "@phosphor-icons/react";
import type React from "react";

type SearchBarProps = {
  searchTerm: string;
  title: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
  isOpen: boolean;
};

const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  title,
  onSearchChange,
  onClearSearch,
  isOpen,
}) => {
  return (
    <div className="bg-white z-10 pl-1.5 pt-0.5 pr-0.5">
      <SearchField aria-label="search">
        <MagnifyingGlass
          aria-hidden
          size={12}
          className="text-slate-300 mb-1.5"
        />
        <SearchFieldInput
          className="pl-1 text-ellipsis bg-white pt-0"
          placeholder={`Search ${title.toLowerCase()}`}
          value={searchTerm}
          onChange={onSearchChange}
          tabIndex={isOpen ? 0 : -1}
        />
        {searchTerm && (
          <SearchFieldClear onPress={onClearSearch}>
            <X aria-hidden className="h-4 w-4" />
          </SearchFieldClear>
        )}
      </SearchField>
    </div>
  );
};

export default SearchBar;
