import { derive } from "@/lib/derive-zustand";
import type { DerivedFilterItem, FilterData } from "@/store/news/feed.slice";
import { useStore } from "zustand";
import useKeyMessageStore, { type KeyMessage } from "./key-message.slice";

const deriveKeyMessageFilterItems = derive<DerivedFilterItem[]>((get) => {
  const { keyMessageGroups, selectedKeyMessageIds } = get(useKeyMessageStore);

  return keyMessageGroups.flatMap(({ keyMessageItems }) =>
    keyMessageItems.map(({ id, name, total = 0 }) => ({
      id,
      value: name,
      count: total,
      totalCount: total,
      selected: selectedKeyMessageIds.has(id),
    })),
  );
});

export const useGetKeyMessageFilterItems = (
  searchTerm: string,
): DerivedFilterItem[] => {
  const items = useStore(deriveKeyMessageFilterItems);
  return searchTerm
    ? items.filter((item) =>
        item.value.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : items;
};

export const getSelectedFeedArticleIds = (
  keyMessages: KeyMessage[],
  selectedKeyMessageIds: Set<string>,
) => {
  return new Set(
    keyMessages.reduce((acc: number[], km) => {
      if (selectedKeyMessageIds.has(km.id)) {
        acc.push(...km.feedArticleIds);
      }
      return acc;
    }, []),
  );
};

/**
 * @param name
 * @returns {string}
 *
 * if key message name exceeds 3 words, it will be trimmed to 3 words
 */
export const trimKeyMessageName = (name: string): string => {
  const maxLength = 3;
  const words = name.trim().split(" ");

  return words.length > maxLength
    ? `${words.slice(0, maxLength).join(" ")}...`
    : name;
};

/**
 * @param filters
 * @returns {string}
 *
 * get selected filters for create key message modal
 */
export const getSelectedFilters = (filters: FilterData[]): string => {
  return filters.flatMap(({ selectedOptions }) => selectedOptions).join(", ");
};
