import { useLocation, useNavigate, useParams } from "react-router-dom";

/**
 * Navigation utility that provides consistent URL management across the app
 */
export const useAppNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const buildUrl = (path: string, search?: string) => {
    const cleanPath = path.startsWith("/") ? path : `/${path}`;
    return search ? `${cleanPath}${search}` : cleanPath;
  };

  return {
    /**
     * Navigate to a new path while preserving existing search params
     */
    navigatePreserveQuery: (path: string) => {
      navigate(buildUrl(path, location.search));
    },

    /**
     * Navigate to a new path with new search params
     */
    navigateWithQuery: (path: string, searchParams: Record<string, string>) => {
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(searchParams)) {
        params.set(key, value);
      }
      navigate(buildUrl(path, `?${params.toString()}`));
    },

    /**
     * Navigate to a new path, merging new search params with existing ones
     */
    navigateMergeQuery: (
      path: string,
      searchParams: Record<string, string>,
    ) => {
      const currentParams = new URLSearchParams(location.search);
      for (const [key, value] of Object.entries(searchParams)) {
        currentParams.set(key, value);
      }
      navigate(buildUrl(path, `?${currentParams.toString()}`));
    },

    /**
     * Get the current search params as an object
     */
    getSearchParams: () => {
      const searchParams = new URLSearchParams(location.search);
      const params: Record<string, string> = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      return params;
    },

    /**
     * Get the current route params
     */
    getRouteParams: () => params,

    /**
     * Navigate to a newsfeed while preserving search params
     */
    navigateToFeed: (feedId: number | string | null) => {
      const path = feedId ? `/newsfeeds/${feedId}` : "/newsfeeds";
      const currentSearch = location.search;

      // Navigate with search params only if going to a specific feed
      if (feedId) {
        navigate(buildUrl(path, currentSearch));
      } else {
        // Clear params when going to root level
        navigate(path);
      }
    },

    /**
     * Get the current feed ID from the route params
     */
    getCurrentFeedId: () => params.feedId,

    /**
     * Navigate to article preview with query params
     */
    navigateToArticlePreview: (
      feedId: string | number,
      articleId: string | number,
      queryParams?: Record<string, string>,
    ) => {
      const path = `/newsfeeds/${feedId}/article-preview/${articleId}`;
      const params = new URLSearchParams(location.search);
      if (queryParams) {
        for (const [key, value] of Object.entries(queryParams)) {
          params.set(key, value);
        }
      }
      navigate(buildUrl(path, `?${params.toString()}`));
    },
  };
};
