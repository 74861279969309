import { isLocal } from "@/config";
import useAuth from "@/store/auth.slice";
import ReactGA from "react-ga";
import { loadDatadog } from "./datadog";
import { logSystemInfo, setupSystemInfoListeners } from "./logSystemInfo";

const initializePendo = () => {
  const { loggedIn, user } = useAuth.getState();

  if (loggedIn && window.pendo) {
    try {
      window.pendo.initialize({
        visitor: {
          id: user.id ? user.id.toString() : "unknown",
          email: user.email || "unknown",
        },
        account: {
          id: user.default_tenant?.slug || "unknown",
          accountName: user.default_tenant?.name || "Unknown Account",
        },
      });
    } catch (error) {
      console.error("Error initializing Pendo:", error);
    }
  }
};

export const initializeAnalytics = () => {
  // Only run analytics in production and stage environments
  if (isLocal) {
    console.log("Skipping analytics in local environment");
    return () => {};
  }

  // Create an array to store cleanup functions
  const cleanupFunctions: Array<() => void> = [];

  ReactGA.initialize("G-DWBS59VVDF");

  // Subscribe to auth state changes
  const unsubscribe = useAuth.subscribe((state) => {
    if (state.loggedIn && window.pendo) {
      initializePendo();
    }
  });
  // Add auth unsubscribe to cleanup functions
  cleanupFunctions.push(unsubscribe);

  // Initialize Pendo if already logged in
  if (window.pendo) {
    initializePendo();
  } else {
    // If Pendo script is not loaded, wait for it
    window.addEventListener("load", () => {
      if (window.pendo) {
        initializePendo();
      }
    });
  }

  loadDatadog().then((datadogRum) => {
    datadogRum.init({
      applicationId: "cad0c07e-468f-41c0-a96a-b271c04d6325",
      clientToken: "pubbdfc955a344c1f8eae4f7b37d55c10e5",
      site: "us5.datadoghq.com",
      service: "newsamp",
      env: `${import.meta.env.VITE_ENVIRONMENT}`,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      enableExperimentalFeatures: ["feature_flags"],
    });

    datadogRum.startSessionReplayRecording();
    // Log system information to DataDog
    logSystemInfo();
    // Set up listeners to update system information when viewport changes and add to cleanup
    const cleanupListeners = setupSystemInfoListeners();
    cleanupFunctions.push(cleanupListeners);
  });

  // Return a combined cleanup function that calls all cleanup functions
  return () => {
    for (const cleanup of cleanupFunctions) {
      cleanup();
    }
  };
};
