import { transformSummary } from "@/components/news/shared/utils";
import { ArrowUpRight, Eye, Question } from "@phosphor-icons/react";
import { formatNumber, formattedDateToUTC } from "../shared/utils";

import ArticleImage from "@/components/news/feed/NewsImage";

import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";

// import ArticleMentionText from "@/components/news/article/ArticleMentionText";
import type { FeedWithAugTypes } from "@/data-access/news";
import { Separator } from "react-aria-components";
import {
  ARTICLE_READERSHIP_DESCRIPTION,
  DOMAIN_AUTHORITY_DESCRIPTION,
  ENGAGEMENT_DESCRIPTION,
} from "../news.consts";
import { ArticleBadgeRow } from "../shared/ArticleBadgeDisplay";

const ArticleDetailPanel = ({
  articleUrl,
  summary,
  maxScore,
  maxDomainAuthority,
  maxSocial,
  imageUrl,
  articleAuthors,
  articleLastUpdateDate,
  articlePublisher,
  articleReadership,
  headline,
  prominence,
  articleNewsCategory,
  tierLevel,
}: Partial<FeedWithAugTypes>) => {
  const onViewArticle = () => {
    if (articleUrl) window.open(articleUrl, "_blank");
  };

  const transformedSummary = transformSummary(summary ?? "");

  return (
    <div className="flex flex-col gap-5 -mx-6 h-screen">
      <div className="flex px-8 justify-between items-center">
        <h2>Article Summary</h2>
        <div className="flex items-center gap-3">
          <Button
            variant="secondary"
            // className="text-text-default text-sm flex flex-row justify-start items-center"
            onPress={onViewArticle}
          >
            View Article
            <ArrowUpRight className="ml-2 w-4 h-4" />
          </Button>
        </div>
      </div>
      <Separator orientation="vertical" />
      <div className="flex flex-col gap-10 px-10 overflow-auto flex-grow pt-4 border-t border-slate-200">
        <div className="inline-flex items-center justify-between pb-4 border-b border-slate-200">
          <div className="h-[76px] flex flex-col gap-2 justify-start items-start border-r border-slate-200 pr-2">
            <div className="inline-flex space-x-2 items-center">
              <div>Article score</div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Question />
                  </TooltipTrigger>
                  <TooltipContent>
                    Article score is a score developed by Handraise to compare
                    the quality of articles relative to the newsfeed.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="inline-flex flex-col items-center justify-center px-2 py-2 bg-blue-100 rounded w-11 aspect-square">
              <p className="text-xl font-semibold leading-7 text-blue-600">
                {maxScore}
              </p>
            </div>
          </div>
          <div className="h-[76px] flex flex-col gap-2 justify-start items-start border-r border-slate-200 pr-2">
            <div className="inline-flex space-x-2 items-center">
              <div>Domain Authority</div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Question />
                  </TooltipTrigger>
                  <TooltipContent>
                    {DOMAIN_AUTHORITY_DESCRIPTION}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="inline-flex flex-col items-center justify-center px-2 py-2 bg-yellow-100 rounded w-11 aspect-square">
              <p className="text-xl font-semibold leading-7 text-yellow-900">
                {maxDomainAuthority}
              </p>
            </div>
          </div>
          <div className="h-[76px] flex flex-col gap-2 justify-start items-start border-r border-slate-200 pr-2">
            <div className="inline-flex space-x-2 items-center">
              <div>Total Engagement</div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Question />
                  </TooltipTrigger>
                  <TooltipContent>{ENGAGEMENT_DESCRIPTION}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="inline-flex flex-col items-center justify-center px-2 py-2 h-11">
              <p className="text-xl font-semibold leading-7 text-slate-800">
                {maxSocial}
              </p>
            </div>
          </div>
          <div className="h-[76px] flex flex-col gap-2 justify-start items-start pr-2">
            <div className="inline-flex space-x-2 items-center">
              <div>Readership</div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Eye />
                  </TooltipTrigger>
                  <TooltipContent>
                    {ARTICLE_READERSHIP_DESCRIPTION}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="inline-flex flex-col items-center justify-center px-2 py-2 h-11">
              <p className="text-xl font-semibold leading-7 text-slate-800">
                {formatNumber(articleReadership ?? 0)}
              </p>
            </div>
          </div>
        </div>
        <div className="inline-flex flex-col gap-10 bg-white">
          <div className="flex flex-col space-y-5">
            <h2 className="text-4xl text-left font-serif leading-tight text-slate-800">
              {headline}
            </h2>
            <div className="inline-flex space-x-3 justify-center leading-5">
              <p className="text-sm font-medium leading-tight text-slate-500">
                {articlePublisher?.name || "Publication Name"}
              </p>
              <div className="h-full w-[1px] bg-slate-200" />
              <div className="inline-flex space-x-1 items-start justify-start text-sm text-slate-500">
                {articleAuthors && (
                  <p className="text-ellipsis overflow-hidden line-clamp-1">
                    {articleAuthors
                      .slice(0, 2)
                      .map((author) => author.name)
                      .join(", ")}
                    {articleAuthors.length > 2 &&
                      ` and ${articleAuthors.length - 2} more`}
                  </p>
                )}
                {articleAuthors && articleAuthors.length > 0 && (
                  <Separator orientation="vertical" className="mr-1" />
                )}
                <p className="min-w-24">
                  {formattedDateToUTC(articleLastUpdateDate)}
                </p>
              </div>
            </div>
          </div>
          <div className="self-center mb-3">
            <ArticleBadgeRow
              prominence={prominence}
              articleNewsCategory={articleNewsCategory}
              tierLevel={tierLevel}
            />
          </div>
          <div className="inline-flex gap-3 mx-auto items-center justify-center">
            {articlePublisher?.logoUrl && (
              <img
                alt={articlePublisher?.name || "Publication Name"}
                src={articlePublisher?.logoUrl}
                className="h-9 w-auto"
              />
            )}
          </div>
          {imageUrl && (
            <ArticleImage
              size="stretch"
              imageUrl={imageUrl}
              additionalClasses="self-center"
            />
          )}
          <div className="flex flex-col gap-10 mb-10">
            {summary && (
              <div className="w-[90%] mx-auto text-left flex flex-col gap-4 text-gray-800">
                <h3>Summary</h3>
                {transformedSummary.map((paragraph, index) => (
                  <p
                    key={`${index}-${paragraph}`}
                    className="text-xs tracking-wide leading-tight break-words"
                    title={summary ?? ""}
                  >
                    {paragraph}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleDetailPanel;
