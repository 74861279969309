import { Button } from "@/components/ui/button";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
} from "@/components/ui/dialog";
import Checkbox from "@/components/ui/forms/checkbox";
import {
  Menu,
  MenuItem,
  MenuPopover,
  MenuSeparator,
  MenuTrigger,
} from "@/components/ui/menu";
import { JollyTextField } from "@/components/ui/textfield";
import { cn } from "@/lib/utils";
import { createKeyMessage } from "@/store/key-messages/key-message-async.actions";
import {
  setCreateKeyMessageGroupModalOpen,
  setKeyMessageName,
  setSelectedGroup,
} from "@/store/key-messages/key-message.actions";
import { getSelectedFilters } from "@/store/key-messages/key-message.selectors";
import useKeyMessageStore from "@/store/key-messages/key-message.slice";
import type { FilterData } from "@/store/news/feed.slice";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import {
  CaretDown,
  Check,
  CircleNotch,
  FolderPlus,
} from "@phosphor-icons/react";
import { useState } from "react";

type GroupDropdownProps = {
  selectedGroup: { id: string; name: string } | null;
  setSelectedGroup: (group: { id: string; name: string } | null) => void;
};

export const GroupDropdown = ({
  selectedGroup,
  setSelectedGroup,
}: GroupDropdownProps) => {
  const keyMessageGroups = useKeyMessageStore(
    (state) => state.keyMessageGroups,
  );

  const handleSelectGroup = (group: { id: string; name: string } | null) => {
    setSelectedGroup(group);
  };

  const selectedGroupDetails = keyMessageGroups.find(
    (group) => selectedGroup && selectedGroup.id === group.id,
  );

  return (
    <div className={"w-[263px]"}>
      <MenuTrigger>
        <Button
          variant={"outline"}
          className={
            "h-9 text-xs px-2 font-normal tracking-wide w-full flex justify-between items-center"
          }
        >
          {selectedGroupDetails?.name ?? "Select a group"}
          <CaretDown />
        </Button>
        <MenuPopover className={"w-[263px] min-h-min"}>
          <Menu>
            {keyMessageGroups.map((group) => {
              return (
                <MenuItem
                  key={group.id}
                  className={"text-xs font-normal tracking-wide pr-2"}
                  onAction={() => handleSelectGroup(group)}
                >
                  {group.name}
                  {selectedGroup?.id === group.id && (
                    <Check className={"ml-auto"} />
                  )}
                </MenuItem>
              );
            })}
            <MenuItem
              className={"text-xs font-normal tracking-wide pr-2"}
              onAction={() => handleSelectGroup(null)}
            >
              <span className={"italic text-gray-700"}>
                <span className="text-gray-200">&mdash;</span> None{" "}
                <span className="text-gray-200">&mdash;</span>
              </span>
              {selectedGroup === null && <Check className={"ml-auto"} />}
            </MenuItem>
            <MenuSeparator className={"mx-1"} />
            <MenuItem
              className={"text-xs font-normal tracking-wide"}
              onAction={() => setCreateKeyMessageGroupModalOpen(true)}
            >
              <div className="flex items-center gap-2">
                <FolderPlus size={16} className="text-gray-600" />
                <span>New group</span>
              </div>
            </MenuItem>
          </Menu>
        </MenuPopover>
      </MenuTrigger>
    </div>
  );
};

interface SaveSearchAsKeyMessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  filterData: FilterData[];
}

const SaveSearchAsKeyMessageModal = ({
  isOpen,
  onClose,
  filterData,
}: SaveSearchAsKeyMessageModalProps) => {
  const selectedGroup = useKeyMessageStore((state) => state.selectedGroup);
  const [isNestedInaGroup, setIsNestedInaGroup] = useState<boolean>(
    Boolean(selectedGroup?.id),
  );
  const keyMessageName = useKeyMessageStore((state) => state.keyMessageName);

  const isAdvancedSearch = useKeyMessageStore(
    (state) => state.isAdvancedSearch,
  );

  /** set up for save input */
  const searchTerms = useFeedParamsStore((state) => state.searchTerms);
  const feedId = useFeedStore((state) => state.feedId);
  const expandedSearchTerm = useKeyMessageStore(
    (state) => state.expandedSearchTerm,
  );
  const isCreatingKeyMessage = useKeyMessageStore(
    (state) => state.isCreatingKeyMessage,
  );

  const handleCancel = () => {
    onClose();
    setIsNestedInaGroup(false);
    setCreateKeyMessageGroupModalOpen(false);
    setKeyMessageName("");
  };

  const handleSave = () => {
    const input = {
      feedId: feedId?.toString() as string,
      searchTerms: isAdvancedSearch ? expandedSearchTerm : searchTerms,
      name: keyMessageName,
      groupId: selectedGroup?.id ?? null,
      selectedFilters: getSelectedFilters(filterData),
    };

    createKeyMessage(input).then(() => handleCancel());
  };

  const handleCheckboxChange = () => {
    if (isNestedInaGroup) {
      // Uncheck and clear selectedGroup
      setIsNestedInaGroup(false);
      setSelectedGroup(null);
    } else {
      // Check it (but don't change selectedGroup)
      setIsNestedInaGroup(true);
    }
  };

  return (
    <DialogOverlay isOpen={isOpen}>
      <DialogContent onClose={handleCancel} className={"!w-[540px]"}>
        <DialogHeader title="Save search as new key message" />
        <DialogBody className="space-y-4">
          <JollyTextField
            value={keyMessageName}
            onChange={(e) => setKeyMessageName(e)}
            label={"Key Message name"}
            required={true}
            size={"sm"}
            labelVariant={"secondary"}
            autoFocus
          />
          <Checkbox
            checked={isNestedInaGroup}
            onCheckedChange={handleCheckboxChange}
          >
            <span className={"text-xs"}>Nest under group:</span>
          </Checkbox>
          <GroupDropdown
            selectedGroup={selectedGroup}
            setSelectedGroup={(group) => {
              setSelectedGroup(group);
              setIsNestedInaGroup(!!group);
            }}
          />
        </DialogBody>
        <DialogFooter>
          <div className={"flex w-full justify-between"}>
            <Button variant={"ghost"} size={"sm"}>
              More Options
            </Button>
            <div className="flex space-x-2">
              <Button variant="outline" onPressChange={handleCancel} size="sm">
                Cancel
              </Button>
              <Button
                isDisabled={Boolean(!keyMessageName.length)}
                className={cn(!keyMessageName.length && "disabled:bg-gray-600")}
                onPress={handleSave}
                size={"sm"}
              >
                {isCreatingKeyMessage && (
                  <CircleNotch size={12} className={"mr-1 animate-spin"} />
                )}
                <span> {isCreatingKeyMessage ? "Saving" : "Save"}</span>
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </DialogOverlay>
  );
};
export default SaveSearchAsKeyMessageModal;
