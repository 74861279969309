import { useEffect, useRef, useState, useCallback } from "react";
import { useSubscription } from "urql";
import { StoryInsightsSubscription, type StoryInsightUpdate } from "./story-insights.gql";

interface QueuedUpdate {
  sequenceId: number;
  update: StoryInsightUpdate;
  processed: boolean;
}

interface InsightData {
  whatToKnow: string;
  whyItMatters: string;
  currentStatus: string;
  startDate?: Date;
  endDate?: Date;
}

interface StoryInsightState {
  insightData: InsightData;
  metrics: Record<string, string | number>;
  isContentLoaded: boolean;
}

interface UseStoryInsightsSubscriptionProps {
  storyId: string;
  enabled: boolean;
  onUpdate?: (update: StoryInsightUpdate) => void;
}

interface UseStoryInsightsSubscriptionResult extends StoryInsightState {
  isLoading: boolean;
  error?: Error;
}

export function useStoryInsightsSubscription({
  storyId,
  onUpdate,
  enabled,
}: UseStoryInsightsSubscriptionProps): UseStoryInsightsSubscriptionResult {
  const [insightData, setInsightData] = useState<InsightData>({
    whatToKnow: "",
    whyItMatters: "",
    currentStatus: STORY_CLUSTER_INSIGHT_MODAL.STATUS.INITIALIZING,
  });

  const [metrics, setMetrics] = useState<Record<string, string | number>>({});
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  
  // Add ref to track previous story ID
  const previousStoryIdRef = useRef<string>("");

  const updateQueue = useRef<QueuedUpdate[]>([]);
  const isProcessing = useRef(false);
  const processTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Reset state when story ID changes
  useEffect(() => {
    if (previousStoryIdRef.current && previousStoryIdRef.current !== storyId) {
      setIsContentLoaded(false);
      setInsightData({
        whatToKnow: "",
        whyItMatters: "",
        currentStatus: STORY_CLUSTER_INSIGHT_MODAL.STATUS.INITIALIZING,
      });
      setMetrics({});
      updateQueue.current = [];
      isProcessing.current = false;
    }
    previousStoryIdRef.current = storyId;
  }, [storyId]);

  const processUpdateQueue = useCallback(() => {
    if (isProcessing.current) return;

    isProcessing.current = true;

    const processNext = () => {
      const nextUpdateIndex = updateQueue.current.findIndex(item => !item.processed);

      if (nextUpdateIndex === -1) {
        isProcessing.current = false;
        return;
      }

      const queueItem = updateQueue.current[nextUpdateIndex];
      const update = queueItem?.update;

      if (!update) return;

      setInsightData(prevData => {
        const newData = { ...prevData };
        if (update.whatToKnow !== null && update.whatToKnow !== undefined) {
          newData.whatToKnow = update.whatToKnow.trim();
        }
        if (update.whyItMatters !== null && update.whyItMatters !== undefined) {
          newData.whyItMatters = update.whyItMatters.trim();
        }
        if (update.status !== null && update.status !== undefined) {
          newData.currentStatus = update.status;
        }
        if (update.startDate) {
          newData.startDate = new Date(update.startDate);
        }

        if (update.endDate) {
          newData.endDate = new Date(update.endDate);
        }
        return newData;
      });

      if (update.metrics) {
        setMetrics(prev => {
          const newMetrics: Record<string, string | number> = { ...prev };
          for (const metric of update.metrics ?? []) {
            if (metric.value !== undefined) {
              newMetrics[metric.key] = metric.value;
            }
          }
          return newMetrics;
        });
      }

      if (updateQueue.current[nextUpdateIndex]) {
        updateQueue.current[nextUpdateIndex].processed = true;
      }

      processTimeoutRef.current = setTimeout(() => {
        const hasUnprocessed = updateQueue.current.some(item => !item.processed);
        if (hasUnprocessed) {
          processNext();
        } else {
          isProcessing.current = false;
          updateQueue.current = updateQueue.current.filter(item => !item.processed);
        }
      }, 0);
    };

    processNext();
  }, []);

  const handleUpdate = useCallback((update: StoryInsightUpdate) => {
    updateQueue.current.push({
      sequenceId: 0,
      update,
      processed: false,
    });

    if (!isProcessing.current) {
      processUpdateQueue();
    }

    onUpdate?.(update);
  }, [processUpdateQueue, onUpdate]);

  const [result] = useSubscription(
    {
      query: StoryInsightsSubscription,
      variables: { storyId },
      pause: !enabled || !storyId,
      // Add context to handle cache invalidation
      context: {
        // This ensures a new subscription is created when the story ID changes
        // and the previous content wasn't fully loaded
        requestPolicy: !isContentLoaded ? 'network-only' : 'cache-first',
      }
    },
    (_, response) => response,
  );

  useEffect(() => {
    if (result.data?.storyInsights) {
      handleUpdate(result.data.storyInsights);
    }
  }, [result.data, handleUpdate]);

  useEffect(() => {
    if (result.error) {
      setInsightData(prev => ({
        ...prev,
        currentStatus: STORY_CLUSTER_INSIGHT_MODAL.STATUS.ERROR,
      }));
    }
  }, [result.error]);

  useEffect(() => {
    if (insightData.whatToKnow && insightData.whyItMatters && insightData.currentStatus !== STORY_CLUSTER_INSIGHT_MODAL.STATUS.INITIALIZING) {
      setIsContentLoaded(true);
    }
  }, [insightData.whatToKnow, insightData.whyItMatters, insightData.currentStatus]);

  useEffect(() => {
    return () => {
      if (processTimeoutRef.current) {
        clearTimeout(processTimeoutRef.current);
      }
      updateQueue.current = [];
      isProcessing.current = false;
    };
  }, []);

  return {
    isLoading: result.fetching,
    error: result.error,
    insightData,
    metrics,
    isContentLoaded,
  };
}

export const STORY_CLUSTER_INSIGHT_MODAL = {
  TITLE: "AI Story Cluster Summary",
  STATUS: {
    INITIALIZING: "Initializing...",
    ERROR: "Error loading insights",
    NO_INFO: "No information available",
    LOADING: "Loading...",
  },
  SECTIONS: {
    STORY_STATS: "Story stats:",
    WHAT_TO_KNOW: "What to know:",
    WHY_IT_MATTERS: "Why it matters:",
    TERMS_GUIDE: "TERMS GUIDE",
  },
  BUTTONS: {
    COPY_SUMMARY: "Copy summary",
    COPY_SUMMARY_TERMS: "Copy summary + terms",
    SHOW_TERMS: "show terms guide",
    HIDE_TERMS: "hide terms guide",
  },
  TERMS: {
    STORY_CLUSTERS: {
      TITLE: "1. Story Clusters:",
      DESCRIPTION: "Groups of articles, where Handraise AI looks at the content and headlines of all articles we are monitoring for the brand and groups similar articles together and generates an AI summary headline for the story cluster.",
    },
    ARTICLE_IMPACT_SCORE: {
      TITLE: "2. Article Impact Score:",
      DESCRIPTION: "A proprietary scoring system that is based on the article's prominence (headline, feature, lede, or passing mention classification), readership, social engagement, domain authority, and sentiment. The score is on a scale of -100 to 100, with -100 being the most high negative impact and 100 being the most high positive impact.",
    },
    NET_IMPACT_SCORE: {
      TITLE: "3. Net Impact Score:",
      DESCRIPTION: "The summation of all individual article impact scores (positive scores + neutral scores - negative scores, so you can see how positively or negatively impactful this story is compared to other stories).",
    },
    GROSS_IMPACT_SCORE: {
      TITLE: "4. Gross Impact Score:",
      DESCRIPTION: "The absolute value summation of all individual article impact scores (we treat all article impact scores as positive values so you can compare overall impact against other stories regardless of sentiment).",
    },
    NET_SENTIMENT: {
      TITLE: "5. Net Sentiment:",
      DESCRIPTION: "The net impact score representation of sentiment (meaning if net impact is a positive number, then net sentiment is positive and if net impact is a negative number, then net sentiment is negative).",
    },
    SOCIAL_ENGAGEMENTS: {
      TITLE: "6. Social Engagements:",
      DESCRIPTION: "The total shares, likes, and reactions from Facebook, X, Reddit, and Pinterest on all articles in the story cluster.",
    },
    TIERS: {
      TITLE: "7. Tier 1, Tier 2, and Tier 3:",
      DESCRIPTION: "Publications based on your custom values assigned to each tier based on readership or domain authority (and any manually assigned target publications for each tier).",
    },
    HEADLINE_MENTIONS: {
      TITLE: "8. Headline Mentions:",
      DESCRIPTION: "The primary brand is mentioned in the headline.",
    },
    FEATURE_MENTIONS: {
      TITLE: "9. Feature Mentions:",
      DESCRIPTION: "The primary brand is mentioned at least 3 times in the article (and not in the headline).",
    },
    PASSING_MENTIONS: {
      TITLE: "10. Passing Mentions:",
      DESCRIPTION: "The primary brand is only mentioned once or twice in the article (and not in the headline).",
    },
    TOP_ARTICLE: {
      TITLE: "11. Top Article:",
      DESCRIPTION: "The article with the highest impact score in the story cluster that is not a press release or part of an aggregator website.",
    },
    WHAT_TO_KNOW: {
      TITLE: "12. What to Know:",
      DESCRIPTION: "Handraise AI-generated summary of the key story from all the combined articles in the story cluster.",
    },
    WHY_IT_MATTERS: {
      TITLE: "13. Why it Matters:",
      DESCRIPTION: "Handraise AI-generated summary of why this story is important and relevant to the primary brand it is referencing in your newsfeed.",
    },
  },
} as const;
