import type { StateCreator } from "zustand";

export const MIN_FILTER_WIDTH = 150;
export const DEFAULT_FILTER_WIDTH = 260;

export interface LayoutPreferencesSlice {
  filterPanelWidth: number;
  leftSidebarVisible: boolean;
  setLeftSidebarVisible: (visible: boolean) => void;
  setFilterPanelWidth: (width: number) => void;
}

export const createLayoutPreferencesSlice: StateCreator<
  LayoutPreferencesSlice,
  [["zustand/devtools", never]],
  []
> = (set) => ({
  filterPanelWidth: DEFAULT_FILTER_WIDTH,
  leftSidebarVisible: true,
  setFilterPanelWidth: (width: number) =>
    set(
      { filterPanelWidth: Math.max(width, MIN_FILTER_WIDTH) },
      false,
      "updated filter panel width",
    ),
  setLeftSidebarVisible: (visible: boolean) =>
    set({ leftSidebarVisible: visible }),
});
