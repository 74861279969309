import Sidebar from "@/components/common/Sidebar";
import { MEDIUM_WINDOW_WIDTH } from "@/config";
import { useTokenManager } from "@/hooks/useTokenManager";
import { cn } from "@/lib/utils";
import useLayoutPreferencesStore from "@/store/useLayoutPreferencesStore";
import { useWindowSize } from "@uidotdev/usehooks";
import { type ReactNode, useLayoutEffect, useState } from "react";

interface MainLayoutProps {
  children: ReactNode | ReactNode[];
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  useTokenManager();
  const { width } = useWindowSize();
  const [isSmallWindow, setSmallWindow] = useState(false);
  const isSidebarVisible = useLayoutPreferencesStore(
    (state) => state.leftSidebarVisible,
  );

  const setLeftSidebarVisible = useLayoutPreferencesStore(
    (state) => state.setLeftSidebarVisible,
  );

  useLayoutEffect(() => {
    if (!width) return;
    const isSmall = width <= MEDIUM_WINDOW_WIDTH;
    setSmallWindow(isSmall);
    if (isSmall) {
      setLeftSidebarVisible(false);
    }
  }, [width]);

  const shouldHoverExpanded = isSmallWindow && isSidebarVisible;

  return (
    <div
      className={cn(
        "h-screen overflow-hidden relative",
        !shouldHoverExpanded && "grid grid-cols-[min-content_1fr]",
      )}
    >
      <Sidebar
        collapsed={!isSidebarVisible}
        className={cn(shouldHoverExpanded && "absolute z-40")}
      />
      <div className={cn(shouldHoverExpanded && "pl-[55px]")}>{children}</div>
    </div>
  );
};

export default MainLayout;
