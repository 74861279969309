import { create } from "@/data-access/core/createStore";
import type { StateCreator } from "zustand";

export type FeedPanelSlice = {
  isOpen: boolean;
  mode: "add" | "edit" | null;
  openAddPanel: () => void;
  openEditPanel: () => void;
  closePanel: () => void;
};

const createFeedPanelSlice: StateCreator<FeedPanelSlice, [], []> = (set) => ({
  isOpen: false,
  mode: null,
  openAddPanel: () => set({ isOpen: true, mode: "add" }),
  openEditPanel: () => set({ isOpen: true, mode: "edit" }),
  closePanel: () => set({ isOpen: false, mode: null }),
});

const useFeedPanelStore = create<FeedPanelSlice>("feed-panel")((...a) => ({
  ...createFeedPanelSlice(...a),
}));

export default useFeedPanelStore;
