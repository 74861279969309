import { useFeature } from "@/lib/feature-management";
import type { DerivedFilterItem } from "@/store/news/feed.slice";
import { memo, useState } from "react";
import { StoryClusterInsightModal } from "../../story-cluster-insight-modal";
import FilterComponentItem from "../FilterComponentItem";
import { StoryClusterMenu } from "../filters/StoryClusterMenu";

interface FilterListProps {
  items: DerivedFilterItem[];
  isOpen: boolean;
  handleFilterChange: (filter: DerivedFilterItem) => void;
  handleEditClick?: (tier: string) => Promise<void>;
  editKnownTag?: (tags: string[]) => void;
  filters: DerivedFilterItem[];
  addEditFilter: (updatedValue: string, filterId?: string) => void;
  showMore?: boolean;
}

function FilterList({
  items,
  isOpen,
  handleFilterChange,
  handleEditClick,
  editKnownTag,
  filters,
  addEditFilter,
  showMore = false,
}: FilterListProps) {
  // Display all items when showMore is true, otherwise only show first 10
  const displayItems = showMore ? items : items.slice(0, 10);

  const [isAISummaryModalOpen, setIsAISummaryModalOpen] = useState(false);
  const [storyId, setStoryId] = useState("");
  const [storyName, setStoryName] = useState("");
  const showStoryInsights = useFeature("story-insights", true);

  // Extract id and name from filterValue
  const extractIdAndName = (filterValue: string) => {
    const match = filterValue.match(/^(\d+)\s+(.*)$/);
    if (!match) return;

    const id = match[1];
    const name = match[2];

    if (!id || !name) return;
    return { id, name };
  };

  const handleStoryClusterMenuItemClick = ({
    id,
    name,
  }: {
    id: string;
    name: string;
  }) => {
    setStoryId(id);
    setStoryName(name);
    setIsAISummaryModalOpen(true);
  };

  return (
    <>
      <div
        role="menu"
        aria-label="Filter options"
        className="space-y-1 pb-0.5 pr-0.5"
      >
        {displayItems.map((filter) => {
          let title = filter.value;
          let storyId: string | undefined;
          const isStoryCluster =
            showStoryInsights && filter.id.startsWith("Story Clusters");

          if (isStoryCluster) {
            const extract = extractIdAndName(filter.value);
            if (extract) {
              title = extract.name;
              storyId = extract.id;
            }
          }
          if (filter.id.startsWith("PUBLISHER"))
            title = title.replace(/[0-9]/g, '')

          return (
            <FilterComponentItem
              key={filter.id}
              title={title}
              count={filter.count}
              selected={filter.selected}
              toggleFilter={() => handleFilterChange(filter)}
              tabIndex={isOpen ? 0 : -1}
              handleEditClick={handleEditClick}
              confirmValue={(update) => addEditFilter(update, filter.id)}
              className="py-1"
              confirmRemove={
                editKnownTag
                  ? () =>
                    editKnownTag(
                      filters
                        .filter((fil) => fil.id === filter.id)
                        .map((fil) => fil.value),
                    )
                  : undefined
              }
            >
              {isStoryCluster && storyId && (
                <StoryClusterMenu
                  handleMenuClick={() =>
                    handleStoryClusterMenuItemClick({
                      id: storyId,
                      name: title,
                    })
                  }
                />
              )}
            </FilterComponentItem>
          );
        })}
      </div>
      {showStoryInsights && storyId && (
        <StoryClusterInsightModal
          isOpen={isAISummaryModalOpen}
          onClose={() => setIsAISummaryModalOpen(false)}
          storyId={storyId}
          name={storyName}
        />
      )}
    </>
  );
}

export default memo(FilterList);
