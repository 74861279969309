import toast from "@/components/ui/toast";
import {
  type ArticleCreationDataType,
  type ArticleCreationStatusType,
  type ArticleNewsCategoryType,
  type ArticlePublisherType,
  type FeedWithAugTypes,
  UpdateArticleMutation,
  type UpdateArticleMutationInputType,
  createArticleSubscription,
} from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import { toSnakeCase } from "@/lib/utils/prettyName";
import type { ArticleEditFn } from "@/types/article";
import type { SortOptions } from "@/types/shared";
import useFeedStore, { useFeedParamsStore } from "../useFeedStore";
import {
  setEditChanges,
  updateArticlesInStore,
} from "./articleManagement.actions";
import useArticleManagementStore from "./articleManagement.slice";
import { updatePagedFeed } from "./feed.actions";
import { FilterCategory } from "./feed.types";

export const changeSort = (sortOrder: SortOptions) => {
  useFeedParamsStore.setState(
    { sortOrder },
    false,
    "[User] Sort: Updated order",
  );
  updatePagedFeed();
};

/**
 * Adds an article to the current feed and handles UI notifications
 * @param articleUrl URL of the article to add
 * @returns Promise with the article ID if successful, null otherwise
 */
export const addArticle = async (
  articleUrl: string,
): Promise<{ id: number } | null> => {
  const feedId = useFeedStore.getState().feedId;

  if (!feedId) {
    const errorMessage = "Could not find the feed ID in the store";
    console.error(errorMessage);

    toast({
      type: "error",
      title: "Unable to add article",
      description: "Feed not found. Please try again.",
    });

    return Promise.reject(new Error(errorMessage));
  }

  try {
    const response = await new Promise<ArticleCreationDataType>(
      (resolve, reject) => {
        client
          .subscription(createArticleSubscription, {
            articleUrl,
            feedId: `${feedId}`,
          })
          .subscribe(({ data, error }) => {
            if (error) {
              console.error(error);
              reject(error);
              return;
            }

            const validStatuses: ArticleCreationStatusType[] = [
              "COMPLETE",
              "PARTIAL_COMPLETE",
              "ERROR",
            ];

            if (
              data?.articleCreation &&
              validStatuses.includes(data.articleCreation.status)
            ) {
              resolve(data.articleCreation);
              return;
            }
          });
      },
    );

    const { uiMessage: responseMessage, feedArticle } = response;

    // Handle success case
    if (responseMessage?.type === "SUCCESS") {
      const isAlreadyInNewsfeed = responseMessage.message.includes("already");

      toast({
        type: isAlreadyInNewsfeed ? "handraise" : "success",
        title: isAlreadyInNewsfeed
          ? "Article already in newsfeed"
          : "Added to newsfeed",
        description:
          responseMessage.message || isAlreadyInNewsfeed
            ? "This article is already in your newsfeed."
            : "New article successfully added to newsfeed.",
      });
    }

    // Handle success case
    if (feedArticle?.id) return { id: feedArticle.id };

    // Handle error case
    if (responseMessage?.type === "FAIL") {
      toast({
        type: "error",
        title: "Unable to add article",
        description: responseMessage.message,
      });

      return null;
    }

    // Default case if no condition is met
    return null;
  } catch (error) {
    // Handle unexpected errors
    const errorMessage =
      error instanceof Error ? error.message : "An unexpected error occurred";

    toast({
      type: "error",
      title: "Error adding article",
      description: errorMessage,
    });

    throw error; // Re-throw to allow the component to handle it if needed
  }
};

const updateArticleInFilterSets = (
  articleId: number,
  updates: Parameters<ArticleEditFn>[0],
) => {
  const { categoryAppliedFilters } = useFeedStore.getState();

  // Check each possible override field
  for (const [category, filter] of Object.entries(categoryAppliedFilters)) {
    if (!filter.itemCounts) continue;

    // Remove from all sets in this category first
    for (const set of Object.values(filter.itemCounts)) {
      set.delete(articleId);
    }

    // Add to new set(s) based on update type
    switch (category) {
      case FilterCategory.CATEGORY:
        if (updates.category) {
          const normalizedCategory = updates.category.toLowerCase().trim();
          const matchingCategory = Object.keys(filter.itemCounts).find(
            (key) => key.toLowerCase().trim() === normalizedCategory,
          );
          if (matchingCategory) {
            filter.itemCounts[matchingCategory]?.add(articleId);
          }

          // Check if "unknown" category is now empty
          const unknownKey = Object.keys(filter.itemCounts).find(
            (key) => key.toLowerCase().trim() === "unknown",
          );
          if (unknownKey && filter.itemCounts[unknownKey]?.size === 0) {
            delete filter.itemCounts[unknownKey];
          }
        }
        break;
      case FilterCategory.PROMINENCE:
        if (updates.prominence) {
          for (const p of updates.prominence) {
            const normalizedProminence = p.toLowerCase().trim();
            const matchingProminence = Object.keys(filter.itemCounts).find(
              (key) => key.toLowerCase().trim() === normalizedProminence,
            );
            if (matchingProminence) {
              filter.itemCounts[matchingProminence]?.add(articleId);
            }
          }
        }
        break;
      case FilterCategory.SENTIMENT:
        if (updates.sentimentPolarity) {
          const normalizedSentiment = updates.sentimentPolarity
            .toLowerCase()
            .trim();
          const matchingSentiment = Object.keys(filter.itemCounts).find(
            (key) => key.toLowerCase().trim() === normalizedSentiment,
          );
          if (matchingSentiment) {
            filter.itemCounts[matchingSentiment]?.add(articleId);
          }
        }
        break;
      case FilterCategory.PUBLISHER:
        if (updates.publisher?.id) {
          // For publisher we use the ID as the key
          if (!filter.itemCounts[updates.publisher.id]) {
            filter.itemCounts[updates.publisher.id] = new Set<number>();
          }
          filter.itemCounts[updates.publisher.id]?.add(articleId);
        }
        break;
      case FilterCategory.AUTHOR:
        if (updates.author) {
          const normalizedAuthor = updates.author.toLowerCase().trim();
          let matchingAuthor = Object.keys(filter.itemCounts).find(
            (key) => key.toLowerCase().trim() === normalizedAuthor,
          );
          if (!matchingAuthor) {
            // If no matching author found, add a new one
            matchingAuthor = updates.author;
            filter.itemCounts[matchingAuthor] = new Set<number>();
          }
          filter.itemCounts[matchingAuthor]?.add(articleId);
        }
        break;
    }
  }

  useFeedStore.setState({ categoryAppliedFilters });
};

export const updateArticle: ArticleEditFn = async (changes) => {
  const { articleId, ...overrides } = changes;
  const {
    headline,
    summary,
    author,
    publisher,
    sentimentPolarity,
    sentimentRationale,
    category,
    isLede,
    prominence,
  } = overrides;

  const { feedItems, feedId } = useFeedStore.getState();

  const updateItemFn = <T extends Partial<FeedWithAugTypes>>(item: T): T => {
    if (item?.articleId !== +articleId) return item;
    if (headline) item.headline = headline;
    if (summary) item.summary = summary;
    if (author && item.articleAuthors?.[0]) {
      const [mainAuthor, ...rest] = item.articleAuthors;
      item.articleAuthors = [{ ...mainAuthor, name: author }, ...rest];
    }
    if (sentimentPolarity && sentimentRationale) {
      item.articleSentiment = {
        polarity: sentimentPolarity,
        rationale: sentimentRationale,
      };
    }
    if (category)
      item.articleNewsCategory = toSnakeCase(
        category,
      ) as ArticleNewsCategoryType;
    if (publisher) {
      item.articlePublisher = {
        ...item.articlePublisher,
        name: publisher.name,
        url: publisher.url,
        id: +publisher.id,
        logoUrl: publisher.logoUrl,
      } as ArticlePublisherType;
    }
    if (typeof isLede === "boolean") {
      item.isLede = isLede;
    }
    if (prominence) {
      item.prominence = Array.isArray(prominence) ? prominence : [prominence];
    }
    return item;
  };

  if (!feedId) return false;

  const result = client
    .mutation(UpdateArticleMutation, {
      feedId,
      feedArticleId: [+articleId],
      overrides,
    })
    .toPromise();

  const { data } = await result;

  const overrideFeedData = data?.overrideFeedArticle;

  if (!overrideFeedData) return false;

  if (overrideFeedData.__typename === "OperationInfo") {
    for (const message of overrideFeedData.messages) {
      toast.error(message.message);
    }

    return false;
  }

  if (overrideFeedData.__typename === "SuccessResponse") {
    const updatedFeedItems = feedItems.map(updateItemFn);

    useFeedStore.setState(
      {
        feedItems: updatedFeedItems,
      },
      false,
      "[User] Article: Updated details",
    );

    // Update filter sets on success
    updateArticleInFilterSets(+articleId, changes);

    return true;
  }
  return false;
};

const updateItemWithChanges = <T extends Partial<FeedWithAugTypes>>(
  item: T,
  changes: UpdateArticleMutationInputType,
): T => {
  if (changes.headline) item.overriddenHeadline = changes.headline;
  if (changes.summary) item.overriddenSummary = changes.summary;
  if (changes.category) {
    item.overriddenArticleNewsCategory = toSnakeCase(
      changes.category,
    ) as ArticleNewsCategoryType;
  }
  if (changes.publisher) {
    item.articlePublisher = {
      ...changes.publisher,
      id: +(changes.publisher?.id ?? 0),
      logoUrl: changes.publisher?.logoUrl ?? null,
      name: changes.publisher?.name ?? "",
      url: changes.publisher?.url ?? null,
    };
  }
  if (changes.datePublished) {
    item.overriddenDatePublished = changes.datePublished;
  }
  if (changes.author) {
    item.overriddenArticleAuthors = [{ id: "0", name: changes.author }];
  }
  if (changes.prominence) {
    item.prominence = [changes.prominence];
  }
  if (changes.sentimentPolarity) {
    item.overriddenArticleSentiment = {
      polarity: changes.sentimentPolarity,
      rationale: changes.sentimentRationale || "",
      intensity: 0.5,
    };
  }
  if (typeof changes.isLede === "boolean") {
    item.isLede = changes.isLede;
  }
  return item;
};

export const bulkUpdateArticles = async (
  articleIds: number[],
  changes: UpdateArticleMutationInputType,
) => {
  const { feedId } = useFeedStore.getState();

  if (!feedId) {
    console.error("Could not find the feed ID in the store");
    return Promise.reject(new Error("Feed ID is missing"));
  }

  const result = await client
    .mutation(UpdateArticleMutation, {
      feedId: feedId,
      feedArticleId: articleIds,
      overrides: changes,
    })
    .toPromise();

  if (result.data?.overrideFeedArticle.__typename === "OperationInfo") {
    const messages = result.data.overrideFeedArticle.messages;
    for (const message of messages) {
      toast.error(message.message);
    }
    return false;
  }

  // Update local state immediately after successful API response
  const feedItems = useFeedStore.getState().feedItems;

  // Create a set of IDs to update for faster lookup
  const updateIdsSet = new Set(articleIds);

  // Update each article in the feed with the new changes
  const updatedFeedItems = feedItems.map((item) => {
    if (updateIdsSet.has(Number(item.articleId))) {
      return updateItemWithChanges(item, changes);
    }
    return item;
  });

  // Update the store with modified items
  useFeedStore.setState(
    { feedItems: updatedFeedItems },
    false,
    "[User] Articles: Bulk updated",
  );

  // Update filter sets for all edited articles - do this after UI update to prevent delays
  setTimeout(() => {
    for (const id of updateIdsSet) {
      updateArticleInFilterSets(id, { articleId: id, ...changes });
    }
  }, 0);

  updateArticlesInStore([...updateIdsSet], changes);
  setEditChanges({});

  useArticleManagementStore.setState(
    {
      selectedArticleIds: [],
      editChanges: {},
    },
    false,
    "[User] Articles: Clear selection after bulk update",
  );
  return true;
};
