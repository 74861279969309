import { JollyDatePicker } from "@/components/ui/date-picker";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/field";
import PublisherSearchDropdown from "@/components/ui/molecules/PublisherSearchDropdown";
import { JollySelect, SelectItem } from "@/components/ui/select";
import { JollyTextField } from "@/components/ui/textfield";
import type { ArticlePublisherType } from "@/data-access/news";
import { usePublisherSearch } from "@/hooks/usePublisherSearch";
import { pluralize } from "@/lib/utils/prettyName";
import {
  setEditChanges,
  setEditModalOpen,
} from "@/store/news/articleManagement.actions";
import useArticleManagementStore from "@/store/news/articleManagement.slice";
import { bulkUpdateArticles } from "@/store/news/articles.actions";
import { parseDate } from "@internationalized/date";
import { useCallback, useEffect, useMemo, useState } from "react";

type ArticleCategory =
  | "NEWS"
  | "STOCKS"
  | "CLASSIFIEDS"
  | "COUPONS"
  | "CRIME"
  | "MARKET_RESEARCH"
  | "PRESS_RELEASE"
  | "SPORTS"
  | "OBITUARIES"
  | "OWNED_MEDIA"
  | "RECIPES"
  | "UNKNOWN";

type ProminenceType =
  | "HEADLINE"
  | "FEATURE"
  | "LEDE"
  | "PASSING_MENTION"
  | "UNKNOWN";

interface FeedArticleBulkEditProps {
  isOpen: boolean;
  numberOfItemsToEdit: number;
}

const categoryOptions = [
  { id: "NEWS", name: "News" },
  { id: "PRESS_RELEASE", name: "Press Release" },
  { id: "MARKET_RESEARCH", name: "Market Research" },
  { id: "STOCKS", name: "Stocks" },
  { id: "CRIME", name: "Crime" },
  { id: "SPORTS", name: "Sports" },
  { id: "OBITUARIES", name: "Obituaries" },
  { id: "OWNED_MEDIA", name: "Owned Media" },
  { id: "RECIPES", name: "Recipes" },
];

const prominenceOptions = [
  { id: "HEADLINE", name: "Headline" },
  { id: "FEATURE", name: "Feature" },
  { id: "PASSING_MENTION", name: "Passing Mention" },
];

const sentimentOptions = [
  { id: "POSITIVE", name: "Positive" },
  { id: "NEGATIVE", name: "Negative" },
  { id: "NEUTRAL", name: "Neutral" },
];

const FeedArticleBulkEdit: React.FC<FeedArticleBulkEditProps> = ({
  isOpen,
  numberOfItemsToEdit,
}) => {
  const editChanges = useArticleManagementStore((state) => state.editChanges);
  const selectedIds = useArticleManagementStore(
    (state) => state.selectedArticleIds,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSentimentRationale, setShowSentimentRationale] = useState(false);
  const {
    publishers,
    isLoading: isLoadingPublishers,
    handleSearch,
  } = usePublisherSearch();
  const [currentPublisher, setCurrentPublisher] =
    useState<ArticlePublisherType | null>(null);

  // Reset currentPublisher when modal is opened
  useEffect(() => {
    setShowSentimentRationale(!!editChanges.sentimentPolarity);
  }, [editChanges.sentimentPolarity]);

  const onClose = () => {
    setEditChanges({});
    setCurrentPublisher(null);
    setEditModalOpen(false);
  };

  const handleSubmit = async () => {
    if (!selectedIds.length) {
      return;
    }

    try {
      setIsSubmitting(true);
      const success = await bulkUpdateArticles(selectedIds, editChanges);
      if (!success) return;
      onClose();
    } catch (error) {
      console.error("Failed to update articles:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePublisherSelect = useCallback(
    async (publisherId: string) => {
      const selectedPublisher = publishers.find((p) => p.id === publisherId);
      if (!selectedPublisher) return;
      const publisher = {
        id: selectedPublisher.id,
        name: selectedPublisher.name,
        url: selectedPublisher.url,
        logoUrl: selectedPublisher.logos[0]?.image?.url ?? "",
      };
      setEditChanges({ publisher });

      setCurrentPublisher({
        ...publisher,
        id: +publisher.id,
      });
    },
    [publishers],
  );

  // Handle canceling publisher edit - reset to null
  const handleCancelPublisherEdit = useCallback(() => {
    // Only reset if the publisher was previously set
    if (currentPublisher) {
      setCurrentPublisher(null);
      setEditChanges({ ...editChanges, publisher: undefined });
    }
  }, [currentPublisher, editChanges]);

  const selectedPublishers = useMemo(
    () => ({
      selected: currentPublisher
        ? [
            {
              id: String(currentPublisher.id),
              name: currentPublisher.name,
              url: currentPublisher.url || "",
              logos: [
                {
                  image: {
                    url: currentPublisher.logoUrl || "",
                  },
                },
              ],
            },
          ]
        : [],
    }),
    [currentPublisher],
  );

  return (
    <DialogOverlay isOpen={isOpen} isDismissable>
      <DialogContent onClose={onClose} size="default">
        <DialogHeader
          title={`Edit ${numberOfItemsToEdit} ${pluralize(numberOfItemsToEdit, "article")}`}
        />
        <DialogBody className="space-y-6">
          {/* Date Published */}
          <JollyDatePicker
            label="Date Published"
            value={
              editChanges.datePublished
                ? parseDate(editChanges.datePublished)
                : undefined
            }
            onChange={(date) =>
              setEditChanges({
                datePublished: date ? date.toString() : undefined,
              })
            }
            isDisabled={isSubmitting}
            size="sm"
            className="max-w-40"
          />

          {/* Headline */}
          <JollyTextField
            label="Headline"
            value={editChanges.headline || ""}
            onChange={(headline) => setEditChanges({ headline })}
            placeholder="Add headline..."
            isDisabled={isSubmitting}
            size="sm"
          />

          <div>
            <Label className="text-xs">Publisher</Label>

            <PublisherSearchDropdown
              publishers={publishers}
              isLoading={isLoadingPublishers}
              onSearch={handleSearch}
              onSelect={handlePublisherSelect}
              selectedPublishers={selectedPublishers}
              multiSelect={false}
              maintainValueOnSelect={true}
              cancelEdit={handleCancelPublisherEdit}
            />
          </div>

          {/* Author */}
          <JollyTextField
            label="Author"
            value={editChanges.author || ""}
            onChange={(author) => setEditChanges({ author })}
            placeholder="Add author name..."
            isDisabled={isSubmitting}
            size="sm"
          />

          <JollySelect
            label="Category"
            size="sm"
            selectedKey={editChanges.category}
            placeholder="Select category"
            onSelectionChange={(selected) =>
              setEditChanges({ category: selected as ArticleCategory })
            }
            items={categoryOptions}
            isDisabled={isSubmitting}
            className="max-w-40"
          >
            {(item) => <SelectItem>{item.name}</SelectItem>}
          </JollySelect>

          <JollySelect
            label="Prominence"
            size="sm"
            selectedKey={editChanges.prominence}
            placeholder="Select prominence"
            onSelectionChange={(selected) =>
              setEditChanges({ prominence: selected as ProminenceType })
            }
            items={prominenceOptions}
            isDisabled={isSubmitting}
            className="max-w-40"
          >
            {(item) => <SelectItem>{item.name}</SelectItem>}
          </JollySelect>

          {/* Is Lede */}
          <JollySelect
            label="Is Lede"
            size="sm"
            selectedKey={
              editChanges.isLede != null
                ? editChanges.isLede.toString()
                : undefined
            }
            placeholder="Select lede status"
            onSelectionChange={(selected) =>
              setEditChanges({ isLede: selected === "true" })
            }
            items={[
              { id: "true", name: "Yes" },
              { id: "false", name: "No" },
            ]}
            isDisabled={isSubmitting}
            className="max-w-40"
          >
            {(item) => <SelectItem>{item.name}</SelectItem>}
          </JollySelect>

          {/* Sentiment */}
          <JollySelect
            label="Sentiment"
            size="sm"
            selectedKey={editChanges.sentimentPolarity}
            placeholder="Select sentiment"
            onSelectionChange={(selected) =>
              setEditChanges({ sentimentPolarity: selected as string })
            }
            items={sentimentOptions}
            isDisabled={isSubmitting}
            className="max-w-40"
          >
            {(item) => <SelectItem>{item.name}</SelectItem>}
          </JollySelect>

          {/* Sentiment Rationale - only show if sentiment is selected */}
          {showSentimentRationale && (
            <JollyTextField
              label="Sentiment Rationale"
              value={editChanges.sentimentRationale || ""}
              onChange={(sentimentRationale) =>
                setEditChanges({ sentimentRationale })
              }
              placeholder="Add rationale for sentiment..."
              isDisabled={isSubmitting}
              size="sm"
              textArea
            />
          )}

          {/* Summary */}
          <JollyTextField
            label="Summary"
            value={editChanges.summary || ""}
            onChange={(summary) => setEditChanges({ summary })}
            placeholder="Add summary..."
            isDisabled={isSubmitting}
            size="sm"
            textArea
          />
        </DialogBody>
        <DialogFooter
          primaryLabel={isSubmitting ? "Saving..." : "Save"}
          secondaryLabel="Cancel"
          primaryAction={handleSubmit}
          isPending={isSubmitting}
          secondaryAction={onClose}
        />
      </DialogContent>
    </DialogOverlay>
  );
};

export default FeedArticleBulkEdit;
