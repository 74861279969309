import type { FC, ReactNode } from "react";

import { Button } from "@/components/ui/button";
import { CircleNotch, Trash } from "@phosphor-icons/react";
import type { PressEvent } from "react-aria-components";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../ui/dialog";

type DialogProps = {
  title: string;
  children: ReactNode | ReactNode[];
  submitText: string;
  className?: string;
  submitLoading?: boolean;
  submittingText?: string;
  onSubmitClick?: (e?: PressEvent) => void;
  onCancelClick?: () => void;
  submitVariant?: "default" | "destructive";
};

const ConfirmationDialog: FC<DialogProps> = ({
  title,
  className,
  children,
  submitLoading,
  submittingText,
  submitText,
  onSubmitClick,
  onCancelClick,
  submitVariant,
}) => {
  return (
    <DialogContent className={className} onClose={onCancelClick}>
      <DialogHeader title={title} />
      <DialogBody>{children}</DialogBody>
      <DialogFooter>
        <Button variant="outline" size="sm" onPress={onCancelClick}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={submitVariant || "default"}
          size={"sm"}
          isDisabled={submitLoading}
          className={submitVariant === "destructive" ? "gap-2" : undefined}
          onPress={onSubmitClick}
        >
          {submitLoading && (
            <CircleNotch className="animate-spin h-5 w-5 mr-2" />
          )}
          {submitVariant === "destructive" && (
            <Trash size={16} className="text-red-750" />
          )}
          <span>
            {submitLoading && submittingText ? submittingText : submitText}
          </span>
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

export default ConfirmationDialog;
