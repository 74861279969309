import { cn } from "@/lib/utils";
import { useState } from "react";

const metricDescriptions: Record<string, string> = {
  "Articles Analyzed": "Total number of articles included in this analysis",
  "Company Focus": "Primary company being analyzed",
  "Coverage Period": "Time span of analyzed articles",
  "Average Impact": "Mean impact score across all articles",
  "Top Publishers": "Most prominent news sources",
  "Content Volume": "Total amount of text analyzed",
  "Content Analyzed": "Amount of processed content",
  "Source Diversity": "Number of unique publishers",
  "Key Publishers": "Most significant news sources",
  "Processing Depth": "Depth of article analysis",
  "Sentiment Mix": "Ratio of positive to negative articles",
  "High Impact": "Articles with significant influence",
  "Analysis Stage": "Current phase of processing",
  "Sentiment Split": "Distribution across sentiment categories",
  "Engagement Score": "Combined impact of all articles",
  "Coverage Quality": "Overall quality assessment",
  "Analysis Confidence": "Confidence in analysis results",
  "Peak Impact": "Highest impact score found",
  "Total Articles": "Final article count",
  "Total Impact": "Cumulative impact score",
  "Processing Time": "Time taken for analysis",
};
// Categorize metrics for better organization
const metricCategories: Record<string, string> = {
  "Articles Analyzed": "volume",
  "Company Focus": "primary",
  "Coverage Period": "time",
  "Average Impact": "primary",
  "Top Publishers": "source",
  "Content Volume": "volume",
  "Content Analyzed": "volume",
  "Source Diversity": "source",
  "Key Publishers": "source",
  "Processing Depth": "secondary",
  "Sentiment Mix": "primary",
  "High Impact": "primary",
  "Analysis Stage": "status",
  "Sentiment Split": "secondary",
  "Engagement Score": "primary",
  "Coverage Quality": "secondary",
  "Analysis Confidence": "secondary",
  "Peak Impact": "primary",
  "Total Articles": "volume",
  "Total Impact": "primary",
  "Processing Time": "time",
};
// Get category color for metrics
const getCategoryColor = (category: string) => {
  switch (category) {
    case "primary":
      return "text-violet-800 font-bold";
    case "volume":
      return "text-violet-700 font-semibold";
    case "secondary":
      return "text-violet-600 font-semibold";
    case "source":
      return "text-indigo-700 font-semibold";
    case "time":
      return "text-gray-700 font-semibold";
    case "status":
      return "text-indigo-600 font-semibold";
    default:
      return "text-gray-700 font-semibold";
  }
};
const getMetricColor = (key: string) => {
  if (key.includes("Complete")) return "text-green-500";
  if (key.includes("High")) return "text-blue-500";
  return "text-indigo-500";
};
// MetricsStepper component for handling collapsed and expanded states
interface MetricsStepperProps {
  metricsHistory: Record<string, string | number>[];
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  autoAdvance: boolean;
  setAutoAdvance: React.Dispatch<React.SetStateAction<boolean>>;
}
export function MetricsStepper({
  metricsHistory,
  currentStep,
  setCurrentStep,
  autoAdvance,
  setAutoAdvance,
}: MetricsStepperProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Get key metrics for the collapsed view
  const getKeyMetrics = (metrics: Record<string, string | number>) => {
    const keyMetricNames = [
      "Company Focus",
      "Articles Analyzed",
      "Average Impact",
      "Sentiment Mix",
      "Analysis Stage",
      "Total Articles",
      "Processing Time",
    ];

    // Filter for preferred key metrics
    const filteredMetrics = Object.entries(metrics)
      .filter(([key]) => keyMetricNames.includes(key))
      .reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, string | number>,
      );

    // If we have key metrics, return them
    if (Object.keys(filteredMetrics).length > 0) {
      return filteredMetrics;
    }

    // Otherwise, return the first few available metrics (up to 4)
    return Object.entries(metrics)
      .slice(0, 4)
      .reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, string | number>,
      );
  };

  const currentMetrics = metricsHistory[currentStep] || {};
  const keyMetrics = getKeyMetrics(currentMetrics);

  return (
    <div className="bg-white rounded-lg border border-gray-200 shadow-md">
      {/* Header with toggle */}
      <div className="flex items-center justify-between p-3 border-b border-gray-200 bg-gray-50">
        <div className="flex items-center gap-2">
          <span className="text-sm font-bold text-gray-700">Metrics</span>
          <span className="text-xs text-violet-700 font-medium bg-violet-50 px-2 py-0.5 rounded">
            Step {currentStep + 1} of {metricsHistory.length}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <button
            type="button"
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-xs font-medium px-2 py-1 rounded bg-gray-100 text-gray-700 hover:bg-gray-200"
          >
            {isExpanded ? "Collapse" : "Expand"}
          </button>
        </div>
      </div>

      {/* Collapsed view */}
      {!isExpanded && (
        <div className="p-3">
          <div className="flex flex-wrap gap-3">
            {Object.keys(keyMetrics).length > 0 ? (
              Object.entries(keyMetrics).map(([key, value]) => (
                <div
                  key={key}
                  className="bg-gray-50 px-3 py-2 rounded border border-gray-200"
                >
                  <dt
                    className={cn(
                      "text-xs",
                      getCategoryColor(metricCategories[key] || ""),
                    )}
                  >
                    {key}
                  </dt>
                  <dd className="text-sm font-bold text-gray-900">
                    {String(value)}
                  </dd>
                </div>
              ))
            ) : (
              <div className="w-full text-center py-2 text-gray-500 text-sm">
                Processing metrics... <span className="animate-pulse">•••</span>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Expanded view */}
      {isExpanded && (
        <>
          {/* Navigation controls */}
          <div className="p-3 border-b border-gray-100 flex items-center justify-between">
            <button
              type="button"
              onClick={() => {
                setAutoAdvance(() => false);
                setCurrentStep((prev) => Math.max(0, prev - 1));
              }}
              disabled={currentStep === 0}
              className={cn(
                "text-xs font-medium px-3 py-1.5 rounded-md bg-white border border-gray-200 shadow-sm hover:bg-gray-50 transition-all duration-200",
                currentStep === 0
                  ? "text-gray-400 cursor-not-allowed"
                  : "text-gray-700",
              )}
            >
              Previous
            </button>
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={() => {
                  setAutoAdvance(() => true);
                }}
                disabled={
                  autoAdvance && currentStep === metricsHistory.length - 1
                }
                className={cn(
                  "text-xs font-medium px-2 py-1 rounded-md",
                  autoAdvance
                    ? "bg-violet-100 text-violet-700"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200",
                )}
              >
                Auto
              </button>
              <button
                type="button"
                onClick={() => {
                  setAutoAdvance(() => false);
                  setCurrentStep((prev) =>
                    Math.min(metricsHistory.length - 1, prev + 1),
                  );
                }}
                disabled={currentStep === metricsHistory.length - 1}
                className={cn(
                  "text-xs font-medium px-3 py-1.5 rounded-md bg-white border border-gray-200 shadow-sm hover:bg-gray-50 transition-all duration-200",
                  currentStep === metricsHistory.length - 1
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-gray-700",
                )}
              >
                Next
              </button>
            </div>
          </div>

          {/* Detailed metrics grid */}
          <div className="p-4">
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(currentMetrics).map(([key, value]) => (
                <div
                  key={key}
                  className={cn(
                    "bg-white p-3 rounded-lg border shadow-sm hover:shadow transition-all duration-200",
                    metricCategories[key] === "primary"
                      ? "border-violet-200"
                      : "border-gray-200",
                  )}
                >
                  <dt
                    className={cn(
                      "text-xs uppercase mb-1",
                      getCategoryColor(metricCategories[key] || ""),
                    )}
                  >
                    <div className="group relative inline-block mb-1">
                      <span className={cn("cursor-help", getMetricColor(key))}>
                        {key}
                      </span>
                      <span className="invisible group-hover:visible absolute z-50 w-48 bg-gray-900 text-white text-xs p-2 -mt-2 left-full ml-2 rounded-md shadow-lg">
                        {metricDescriptions[key] || "Additional metric"}
                      </span>
                    </div>
                  </dt>
                  <dd
                    className={cn(
                      "mt-1 text-base font-bold",
                      key.includes("Complete")
                        ? "text-green-600"
                        : "text-gray-900",
                    )}
                  >
                    {String(value)}
                  </dd>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
