import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/forms/checkbox";
import IconButton from "@/components/ui/icon-button";
import { MenuTrigger } from "@/components/ui/menu";
import { Tooltip, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { Check, DotsThree, Trash } from "@phosphor-icons/react";
import {
  type FC,
  type HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

interface FilterItemProps {
  title: string;
  count?: number;
  selected: boolean;
  tabIndex: number;
  toggleFilter: () => void;
  handleEditClick?: (tier: string) => Promise<void>;
  confirmValue?: (updatedValue: string) => void;
  confirmRemove?: () => void;
}

// Shared styles
const styles = {
  container: cn(
    // Layout
    "group flex contain-layout",
    // Spacing
    "px-1.5 py-[2px]",
    // Typography
    "text-xxs",
    // Interactive
    "cursor-pointer",
    // Visual
    "rounded-sm",
    // States & Transitions
    "hover:bg-slate-300/10",
    "will-change-[background-color] transition-colors",
  ),
  button: cn(
    // Position
    "z-100 absolute right-0 top-1/2 -translate-y-1/2",
    // Layout
    "h-5 ml-2",
    // Spacing
    "px-2 py-0 p-0.5",
    // Typography
    "text-xs text-gray-700",
    // Visual
    "bg-white border-none shadow-none",
    // States & Transitions
    "opacity-0 group-hover:opacity-100",
    "data-[hovered]:bg-white",
    "will-change-[opacity,transform] transition-opacity",
  ),
  label: cn(
    // Layout
    "min-w-0 w-full relative",
    // Spacing
    "p-0",
    // Typography
    "text-xxs text-left font-normal",
    // Visual
    "border-0 bg-transparent",
    // States
    "data-[hovered]:bg-transparent",
  ),
  count: cn(
    // Layout
    "min-shrink-0",
    // Spacing
    "ml-2.5",
    // Typography
    "text-right text-gray-600",
    // Transitions
    "transition-opacity duration-200",
  ),
  iconButton: cn(
    // Position
    "z-100 absolute right-0 top-1/2 -translate-y-1/2",
    // Layout
    "h-5 ml-2",
    // Spacing
    "px-2 py-0 p-0.5",
    // Transitions
    "opacity-0 group-hover:opacity-100",
    "data-[hovered]:bg-gray-200",
    "will-change-[opacity,transform] transition-opacity",
  ),
};

const FilterComponentItem: FC<FilterItemProps & HTMLAttributes<HTMLElement>> =
  memo(
    ({
      title,
      count,
      selected,
      tabIndex,
      toggleFilter,
      handleEditClick,
      confirmValue,
      confirmRemove,
      className,
      children,
    }) => {
      const [state, setState] = useState({
        isEditing: false,
        filterValue: title,
        confirmedValue: title,
        isTextTruncated: false,
      });

      const [isMenuOpen, setIsMenuOpen] = useState(false);

      const { isEditing, filterValue, confirmedValue, isTextTruncated } = state;
      const inputRef = useRef<HTMLInputElement>(null);
      const textRef = useRef<HTMLSpanElement>(null);
      const resizeObserver = useRef<ResizeObserver | null>(null);
      const menuTriggerRef = useRef<HTMLButtonElement>(null);

      const checkTextTruncation = useCallback(() => {
        if (textRef.current) {
          const isTextOverflowing =
            textRef.current.scrollWidth > textRef.current.clientWidth;
          setState((prev) => ({ ...prev, isTextTruncated: isTextOverflowing }));
        }
      }, []);

      useEffect(() => {
        checkTextTruncation();

        if (textRef.current) {
          resizeObserver.current = new ResizeObserver(checkTextTruncation);
          resizeObserver.current.observe(textRef.current);
        }

        return () => {
          resizeObserver.current?.disconnect();
        };
      }, [checkTextTruncation, filterValue]);

      const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
          setState((prev) => ({ ...prev, confirmedValue: e.target.value }));
        },
        [],
      );
      const removeTopTopic = useCallback(() => {
        setState((prev) => ({ ...prev, isEditing: false }));
        if (!confirmRemove) return;
        confirmRemove();
      }, [confirmRemove]);

      const setIsEditing = useCallback((value: boolean) => {
        setState((prev) => ({ ...prev, isEditing: value }));
      }, []);

      const handleConfirmEditing = useCallback(
        (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
          setState((prev) => ({
            ...prev,
            isEditing: false,
            filterValue: prev.confirmedValue,
          }));
          e.preventDefault();
          e.stopPropagation();

          if (!confirmValue) return;
          confirmValue(confirmedValue);
        },
        [confirmValue, confirmedValue],
      );

      const handleKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            setState((prev) => ({
              ...prev,
              isEditing: false,
              filterValue: prev.confirmedValue,
            }));
            if (!confirmValue) return;
            confirmValue(confirmedValue);
          } else if (e.key === "Escape") {
            e.preventDefault();
            e.stopPropagation();
            setState((prev) => ({ ...prev, isEditing: false }));
          }
        },
        [confirmValue, confirmedValue],
      );

      const handleMenuToggle = () => {
        setIsMenuOpen((prev) => !prev);
      };

      const handleContextMenu = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
          if (children) {
            e.preventDefault();
            e.stopPropagation();

            if (menuTriggerRef.current) {
              menuTriggerRef.current.click();
              setIsMenuOpen(true);
            }
          }
        },
        [children, title],
      );

      useEffect(() => {
        if (isEditing && inputRef.current) {
          setTimeout(() => {
            inputRef.current?.focus();
          }, 300);
        }
      }, [isEditing]);

      const canEdit = Boolean(confirmValue) && Boolean(confirmRemove);

      const renderLabelButton = () => {
        const labelContent = (
          <span
            ref={textRef}
            className={cn(
              "text-gray-600 leading-4 block overflow-hidden text-ellipsis whitespace-nowrap",
              filterValue === "Ungrouped" && "italic",
            )}
          >
            {filterValue}
          </span>
        );

        if (isTextTruncated) {
          return (
            <TooltipTrigger>
              <Button
                variant="none"
                size="label"
                aria-label={`Filter by ${filterValue}`}
                onPress={toggleFilter}
                className={cn(styles.label)}
              >
                {labelContent}
              </Button>
              <Tooltip variant="accessibility" align="left">
                {filterValue}
              </Tooltip>
            </TooltipTrigger>
          );
        }

        return (
          <Button
            type="button"
            variant="none"
            size="label"
            aria-label={`Filter by ${filterValue}`}
            onPress={toggleFilter}
            className={styles.label}
          >
            {labelContent}
          </Button>
        );
      };

      return (
        <div
          key={filterValue}
          className={cn(styles.container, className)}
          tabIndex={0}
          role="menuitem"
          aria-label={`${filterValue} filter item${isEditing ? ", editing" : ""}`}
          onContextMenu={handleContextMenu}
        >
          <Checkbox
            aria-label={`Select ${filterValue} filter`}
            checked={selected}
            tabIndex={tabIndex}
            variant={"secondary"}
            className="mr-1.5 self-center"
            onClick={toggleFilter}
          />
          {!isEditing ? (
            <div className="flex-1 h-auto leading-none min-w-0 relative justify-center flex items-center">
              {renderLabelButton()}
              {handleEditClick && (
                <Button
                  onPress={() => {
                    handleEditClick(title);
                  }}
                  variant="ghost"
                  size="sm"
                  aria-label={`Edit ${filterValue} filter`}
                  className={cn(styles.button)}
                >
                  Edit
                </Button>
              )}
              {canEdit && (
                <Button
                  variant="ghost"
                  size="sm"
                  aria-label={`Edit ${filterValue} filter`}
                  className={cn(styles.button)}
                  onPress={() => {
                    setIsEditing(true);
                  }}
                >
                  Edit
                </Button>
              )}
            </div>
          ) : (
            <div className="flex-1 min-w-0 relative">
              <input
                id={`edit-${filterValue}`}
                type="text"
                aria-label={`Edit ${filterValue} filter name`}
                className="w-full text-sm border rounded-md px-2 pr-5 py-1 border-slate-200 text-slate-700 focus-visible:outline-none focus-visible:ring-1"
                value={confirmedValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                onClick={(e) => e.stopPropagation()}
                ref={inputRef}
              />
              <Check
                size={16}
                aria-label="Confirm edit"
                tabIndex={0}
                role="button"
                aria-pressed="false"
                className="text-slate-600 absolute right-1 top-1/2 -translate-y-1/2 z-10 cursor-pointer"
                onClick={handleConfirmEditing}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleConfirmEditing(
                      new MouseEvent("click") as unknown as React.MouseEvent<
                        SVGSVGElement,
                        MouseEvent
                      >,
                    );
                  }
                }}
              />
            </div>
          )}
          {isEditing ? (
            <Trash
              size={16}
              aria-label={`Delete ${filterValue} filter`}
              tabIndex={0}
              role="button"
              aria-pressed="false"
              className="text-slate-600 ml-2 flex-shrink-0"
              onClick={(e) => {
                e.stopPropagation();
                removeTopTopic();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  removeTopTopic();
                }
              }}
            />
          ) : (
            count !== undefined && (
              <output
                className={cn(
                  styles.count,
                  children && "group-hover:opacity-0",
                  isMenuOpen && children && "opacity-0",
                )}
                aria-label={`${count.toLocaleString()} items`}
                htmlFor={`edit-${filterValue}`}
              >
                {count.toLocaleString()}
              </output>
            )
          )}
          {children && (
            <MenuTrigger onOpenChange={setIsMenuOpen}>
              <IconButton
                ref={menuTriggerRef}
                icon={<DotsThree size={20} />}
                aria-label={"More options"}
                className={cn(
                  styles.iconButton,
                  isMenuOpen ? "opacity-100" : "opacity-0",
                )}
                variant={"ghost"}
                onPressChange={handleMenuToggle}
              />
              {children}
            </MenuTrigger>
          )}
        </div>
      );
    },
  );

export default FilterComponentItem;
