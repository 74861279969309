import { CaretLeft } from "@phosphor-icons/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { HandraiseLogoMark } from "@/assets/handraise";
import { AuthForm } from "@/components/auth/AuthForm";
import { Button } from "@/components/ui/button";
import {
  type ForgotPasswordData,
  forgotPasswordSchema,
} from "@/lib/auth/authValidation";
import { cn } from "@/lib/utils";
import { startResetPassword } from "@/store/auth.actions";

const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [email, setEmail] = useState("");

  const handleResetPassword = async (data: ForgotPasswordData) => {
    try {
      await startResetPassword(data.email);
      setEmail(data.email);
      setIsEmailSent(true);
    } catch (error) {
      // Error handling is done in the auth slice
    }
  };

  if (isEmailSent) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div
          className={cn(
            "w-full max-w-md px-8 pt-6 pb-8 mb-28",
            "bg-white/80 shadow-2xl rounded-xl",
            "border border-gray-100",
          )}
        >
          <div className="w-full flex items-center justify-center mb-6">
            <HandraiseLogoMark className="w-48" />
          </div>

          <h1 className="text-center text-lg font-serif text-gray-800">
            Check your email
          </h1>
          <p className="text-center text-gray-500 text-sm mb-6">
            We've sent a password reset link to <strong> {email}</strong>.
            Please check your inbox and follow the instructions.
          </p>

          <Button onPress={() => navigate("/auth/login")} className="w-full">
            Back to Login
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="absolute top-5 left-5">
        <Button
          variant="ghost"
          onPress={() => navigate("/auth/login")}
          className="flex items-center"
        >
          <CaretLeft className="mr-2" />
          Back to Login
        </Button>
      </div>

      <div
        className={cn(
          "w-full max-w-md px-8 pt-6 pb-8 mb-28",
          "bg-white/80 shadow-2xl rounded-xl",
          "border border-gray-100",
        )}
      >
        <div className="w-full flex items-center justify-center mt-3 mb-6">
          <HandraiseLogoMark className="w-48" />
        </div>

        <h1 className="text-center text-lg font-serif text-gray-700 mb-2">
          Forgot Password
        </h1>
        <p className="text-center text-gray-500 text-sm mb-4">
          Enter your email to reset your password
        </p>

        <AuthForm
          schema={forgotPasswordSchema}
          onSubmit={handleResetPassword}
          fields={[
            {
              name: "email",
              label: "Email",
              type: "email",
            },
          ]}
          submitLabel="Reset Password"
        />

        <div className="mt-4 text-center text-sm text-gray-700">
          Remember your password?{" "}
          <Link to="/auth/login" className="text-violet-700 hover:underline">
            Log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
