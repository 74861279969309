import {
  create,
  createJSONStorage,
  persist,
} from "@/data-access/core/createStore";
import { type UserInfo, defaultUserInfo } from "@/types/user/userInfo";

export type AuthStatus = "idle" | "loading" | "succeeded" | "failed";

export interface AuthState {
  // User state
  user: UserInfo;
  status: AuthStatus;
  loggedIn: boolean;
  error: string | null;
  isEmployee: boolean;
  accessToken: string | null;
  refreshToken: string | null;

  // Session state (consolidated from session.slice.ts)
  isExpired: boolean;
  showExpirationWarning: boolean;
}

const initialState: AuthState = {
  user: defaultUserInfo,
  status: "idle",
  loggedIn: false,
  error: null,
  isEmployee: false,
  accessToken: null,
  refreshToken: null,
  isExpired: false,
  showExpirationWarning: false,
};

const useAuth = create<AuthState>("auth-state")(
  persist(() => initialState, {
    name: "auth-storage",
    storage: createJSONStorage(() => localStorage),
  }),
);

export default useAuth;
