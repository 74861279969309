export enum FilterCategory {
  STORY_CLUSTERS = "STORY_CLUSTERS",
  IMPACT_SCORE = "IMPACT_SCORE",
  PROMINENCE = "PROMINENCE",
  PUBLICATION_TIER = "TIER",
  SENTIMENT = "SENTIMENT",
  SOCIAL = "SOCIAL",
  READERSHIP = "READERSHIP",
  DOMAIN_AUTHORITY = "DOMAIN_AUTHORITY",
  CATEGORY = "CATEGORY",
  PUBLISHER = "PUBLISHER",
  AUTHOR = "AUTHOR",
}

export const feedMessages = {
  articleDelete: {
    success: (count: number) => ({
      type: "success" as const,
      title: "Done! Articles removed",
      description: `Removed ${count} article${count === 1 ? "" : "s"} from the feed.`,
    }),
    error: {
      failed: {
        type: "error" as const,
        title: "Oops! Something went wrong",
        description: "We couldn't remove those articles right now.",
      },
    },
  },
} as const;
