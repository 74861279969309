import { create } from "@/data-access/core/createStore";
import { persist } from "zustand/middleware";
import createUrlStorage from "./createUrlStorage";
import { type ArticlesSlice, createArticlesSlice } from "./news/articles.slice";
import { type FeedSlice, createFeedSlice } from "./news/feed.slice";
import { FilterCategory } from "./news/feed.types";
import createFeedParamsSlice, {
  type ParamsStorageSlice,
} from "./news/feedParams.slice.ts";
import {
  type FilterAndSearchSlice,
  createFilterSearchSlice,
} from "./news/filterSearch.slice";

type FeedStore = FeedSlice & ArticlesSlice & FilterAndSearchSlice;

const useFeedStore = create<FeedStore>("feed")((...a) => ({
  ...createFeedSlice(...a),
  ...createArticlesSlice(...a),
  ...createFilterSearchSlice(...a),
}));

const useFeedParamsStore = create<ParamsStorageSlice>("feed-param")(
  persist(
    (...a) => ({
      ...createFeedParamsSlice(...a),
    }),
    {
      name: "fd",
      storage: createUrlStorage(),
      version: 1,
      migrate: (persistedState: unknown, version: number) => {
        const defaultState: ParamsStorageSlice = {
          selected: {
            [FilterCategory.CATEGORY]: ["News", "Press Release", "Owned Media"],
          },
          searchTerms: "",
          filterDays: 30,
          isCustomDaysRange: false,
          period: "day",
          sortOrder: "DATE_DESC",
        };

        // Handle version upgrades if needed in the future
        if (!persistedState || version === 0) return defaultState;

        const state = persistedState as Partial<ParamsStorageSlice>;

        return {
          ...defaultState,
          ...state,
          // Ensure required fields have default values
          selected: {
            ...defaultState.selected,
            ...(state.selected || {}),
          },
        };
      },
    },
  ),
);

export default useFeedStore;
export { useFeedParamsStore };
