import toast from "@/components/ui/toast";
import {
  CreateKeyMessage,
  CreateKeyMessageGroup,
  type CreateKeyMessageGroupVariablesType,
  type CreateKeyMessageVariablesType,
  GetKeyMessages,
  GetKeyMessagesAndGroups,
  type GetKeyMessagesAndGroupsVariablesType,
  type GetKeyMessagesVariablesType,
  UpdateKeyMessage,
  UpdateKeyMessageGroup,
  type UpdateKeyMessageGroupVariablesType,
  type UpdateKeyMessageVariablesType,
} from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import { omit } from "@/lib/utils/utils";
import { getDateParameters } from "@/store/news/filterSearch.actions";
import useFeedStore from "@/store/useFeedStore";
import type { KeyMessage, KeyMessageGroup } from "./key-message.slice";
import useKeyMessageStore from "./key-message.slice";

export const createKeyMessageGroup = async (
  input: CreateKeyMessageGroupVariablesType["input"],
): Promise<{ id: string; name: string } | undefined> => {
  useKeyMessageStore.setState({ isCreatingKeyMessageGroup: true });

  try {
    const result = await client
      .mutation(CreateKeyMessageGroup, { input })
      .toPromise();

    if (result.error) {
      toast.error("Failed to create key message group.");
      useKeyMessageStore.setState(
        { isCreatingKeyMessageGroup: false },
        false,
        "create key message group error",
      );
      return undefined;
    }

    const createKeyMessageGroup = result.data?.createKeyMessageGroup;
    if (
      !createKeyMessageGroup ||
      createKeyMessageGroup.__typename !== "KeyMessageGroupType"
    ) {
      toast.error("Failed to create key message group.");
      useKeyMessageStore.setState(
        { isCreatingKeyMessageGroup: false },
        false,
        "create key message group error",
      );
      return undefined;
    }

    const newKeyMessageGroup: KeyMessageGroup = {
      id: createKeyMessageGroup.id,
      name: createKeyMessageGroup.name,
      keyMessageItems: [],
    };

    useKeyMessageStore.setState(
      (state) => ({
        keyMessageGroups: [...state.keyMessageGroups, newKeyMessageGroup],
        isCreatingKeyMessageGroup: false,
        createKeyMessageGroupState: "complete",
      }),
      false,
      "create key message group success",
    );
    toast({
      type: "success",
      title: "New group created!",
      description: `"${createKeyMessageGroup.name}" key message has been created and added to your filters.`,
    });
    return omit(createKeyMessageGroup, "__typename");
  } catch (error) {
    console.error("Error creating key message group:", error);
    toast.error("An error occurred while creating the key message group.");
    useKeyMessageStore.setState({ isCreatingKeyMessageGroup: false });
    return undefined;
  }
};

export const createKeyMessage = async (
  input: CreateKeyMessageVariablesType["input"],
) => {
  useKeyMessageStore.setState({ isCreatingKeyMessage: true });

  try {
    const result = await client
      .mutation(CreateKeyMessage, { input })
      .toPromise();

    if (result.error) {
      toast.error("Failed to create key message.");
      useKeyMessageStore.setState(
        { isCreatingKeyMessage: false },
        false,
        "create key message error",
      );
      return;
    }

    const createKeyMessage = result.data?.createKeyMessageItem;
    if (
      !createKeyMessage ||
      createKeyMessage.__typename !== "KeyMessageItemType"
    ) {
      toast.error("Failed to create key message.");
      useKeyMessageStore.setState({ isCreatingKeyMessage: false });
      return;
    }

    // Get feed ID and date parameters
    const feedId = useFeedStore.getState().feedId;
    const { startDateFormatted: startDate, endDateFormatted: endDate } =
      getDateParameters();

    // Fetch fresh data
    await fetchKeyMessagesAndGroups({
      feedId,
      dateRange: {
        startDate,
        endDate,
      },
    });

    useKeyMessageStore.setState(
      {
        isCreatingKeyMessage: false,
        createKeyMessageState: "complete",
      },
      false,
      "create key message success",
    );

    toast({
      type: "success",
      title: "New key message created!",
      description: `"${createKeyMessage.name}" key message has been created and added to your filters.`,
    });
  } catch (error) {
    console.error("Error creating key message:", error);
    toast.error("An error occurred while creating the key message.");
    useKeyMessageStore.setState({ isCreatingKeyMessage: false });
  }
};

export const fetchKeyMessages = async (
  filters: GetKeyMessagesVariablesType["filters"],
) => {
  useKeyMessageStore.setState({ isFetchingKeyMessages: true });

  try {
    const result = await client.query(GetKeyMessages, { filters }).toPromise();

    if (result.error) {
      toast.error("Failed to fetch key messages.");
      useKeyMessageStore.setState(
        { isFetchingKeyMessages: false },
        false,
        "fetch key messages error",
      );
      return;
    }

    if (!result.data?.keyMessageItems) {
      toast.error("Failed to fetch key messages.");
      useKeyMessageStore.setState({ isFetchingKeyMessages: false });
      return;
    }

    useKeyMessageStore.setState(
      {
        keyMessages: result.data.keyMessageItems,
        isFetchingKeyMessages: false,
      },
      false,
      "fetch key messages success",
    );
  } catch (error) {
    console.error("Error fetching key messages:", error);
    toast.error("An error occurred while fetching key messages.");
    useKeyMessageStore.setState(
      { isFetchingKeyMessages: false },
      false,
      "fetch key messages error",
    );
  }
};

export const updateKeyMessageGroup = async (
  input: UpdateKeyMessageGroupVariablesType["input"],
) => {
  try {
    const result = await client
      .mutation(UpdateKeyMessageGroup, { input })
      .toPromise();

    if (result.error) {
      toast.error(`Failed to update ${input.name} group.`);
      return;
    }

    const updatedGroup = result.data?.updateKeyMessageGroup;

    if (!updatedGroup || updatedGroup.__typename !== "KeyMessageGroupType") {
      toast.error(`Failed to update ${input.name} group.`);
      return;
    }

    try {
      // Get feed ID and date parameters
      const feedId = useFeedStore.getState().feedId;
      const { startDateFormatted: startDate, endDateFormatted: endDate } =
        getDateParameters();

      // Fetch fresh data first
      await fetchKeyMessagesAndGroups({
        feedId,
        dateRange: {
          startDate,
          endDate,
        },
      });

      // Only update UI state after successful fetch
      useKeyMessageStore.setState((state) => ({
        ...state,
        isEditingKeyMessageGroup: false,
        selectedGroup: null,
      }));

      toast({
        type: "success",
        title: "Key message group updated!",
        description: `Key message group has been updated to "${updatedGroup.name}".`,
      });
    } catch (fetchError) {
      console.error("Error fetching updated data:", fetchError);
      toast.error("Failed to update group. Please try again.");
      return; // Don't update UI state if fetch fails
    }
  } catch (error) {
    console.error("Error updating key message group:", error);
    toast.error(
      `An error occurred while updating the ${input.name} key message group.`,
    );
  }
};

export const updateKeyMessage = async (
  input: UpdateKeyMessageVariablesType["input"],
) => {
  try {
    const result = await client
      .mutation(UpdateKeyMessage, { input })
      .toPromise();

    if (result.error) {
      toast.error(`Failed to update ${input.name} message.`);
      return;
    }

    const updatedMessage = result.data?.updateKeyMessageItem;

    if (!updatedMessage || updatedMessage.__typename !== "KeyMessageItemType") {
      toast.error(`Failed to update ${input.name} message.`);
      return;
    }

    useKeyMessageStore.setState((state) => ({
      keyMessages: state.keyMessages.map((message) =>
        message.id === updatedMessage.id
          ? {
              ...message,
              ...omit(updatedMessage, "__typename"),
              group: updatedMessage.group
                ? {
                    id: updatedMessage.group.id,
                    name: updatedMessage.group.name,
                  }
                : null,
            }
          : message,
      ),
      keyMessageGroups: state.keyMessageGroups.map((group) => {
        // If this message was previously in this group but is no longer, remove it
        if (
          !updatedMessage.group &&
          group.keyMessageItems.some((item) => item.id === updatedMessage.id)
        ) {
          return {
            ...group,
            keyMessageItems: group.keyMessageItems.filter(
              (item) => item.id !== updatedMessage.id,
            ),
          };
        }
        // If this is the new group for the message
        if (group.id === updatedMessage.group?.id) {
          // If the message wasn't already in this group, add it
          if (
            !group.keyMessageItems.some((item) => item.id === updatedMessage.id)
          ) {
            const newKeyMessage: KeyMessage = {
              id: updatedMessage.id,
              name: updatedMessage.name,
              group: {
                id: group.id,
                name: group.name,
              },
              feedArticleIds: updatedMessage.feedArticleIds,
              searchTerms: updatedMessage.searchTerms,
              feedArticleFilters: updatedMessage.feedArticleFilters,
              total: updatedMessage.total,
            };
            return {
              ...group,
              keyMessageItems: [...group.keyMessageItems, newKeyMessage],
            };
          }
          // If the message was already in this group, update it
          return {
            ...group,
            keyMessageItems: group.keyMessageItems.map((item) =>
              item.id === updatedMessage.id
                ? {
                    ...item,
                    ...omit(updatedMessage, "__typename"),
                    group: {
                      id: group.id,
                      name: group.name,
                    },
                  }
                : item,
            ),
          };
        }
        // If this message was previously in a different group, remove it
        if (
          group.keyMessageItems.some((item) => item.id === updatedMessage.id)
        ) {
          return {
            ...group,
            keyMessageItems: group.keyMessageItems.filter(
              (item) => item.id !== updatedMessage.id,
            ),
          };
        }
        return group;
      }),
    }));
    toast({
      type: "success",
      title: "Key message updated!",
      description: `Key message "${updatedMessage.name}" has been updated.`,
    });
  } catch (error) {
    console.error("Error updating key message:", error);
    toast.error(
      `An error occurred while updating the ${input.name} key message.`,
    );
  }
};

export const fetchKeyMessagesAndGroups = async (
  filters: GetKeyMessagesAndGroupsVariablesType["filters"],
) => {
  useKeyMessageStore.setState({ isFetchingKeyMessagesAndGroups: true });

  try {
    const result = await client
      .query(
        GetKeyMessagesAndGroups,
        { filters },
        {
          requestPolicy: "network-only", // ⬅️ Busts the cache!
        },
      )
      .toPromise();

    if (result.error) {
      toast.error("Failed to fetch key messages and groups.");
      useKeyMessageStore.setState(
        { isFetchingKeyMessagesAndGroups: false },
        false,
        "fetch key messages and groups error",
      );
      return;
    }

    if (!result.data?.keyMessageGroups) {
      toast.error("Failed to fetch key messages and groups.");
      useKeyMessageStore.setState({ isFetchingKeyMessagesAndGroups: false });
      return;
    }

    const keyMessageGroups = result.data.keyMessageGroups;
    const keyMessages = keyMessageGroups.flatMap((group) => {
      return group.keyMessageItems;
    });
    useKeyMessageStore.setState(
      {
        keyMessageGroups,
        keyMessages,
        isFetchingKeyMessagesAndGroups: false,
      },
      false,
      "fetch key messages and groups success",
    );
  } catch (error) {
    console.error("Error fetching key messages and groups:", error);
    toast.error("An error occurred while fetching key messages and groups.");
    useKeyMessageStore.setState({ isFetchingKeyMessagesAndGroups: false });
  }
};
