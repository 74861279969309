import { FilterCategory } from "./feed.types";

export const FilterDisplayNames: Record<FilterCategory, string> = {
  [FilterCategory.STORY_CLUSTERS]: "Story Clusters",
  [FilterCategory.IMPACT_SCORE]: "Impact Score",
  [FilterCategory.PROMINENCE]: "Prominence",
  [FilterCategory.PUBLICATION_TIER]: "Publication Tier",
  [FilterCategory.SENTIMENT]: "AI Sentiment",
  [FilterCategory.SOCIAL]: "Social Engagement",
  [FilterCategory.READERSHIP]: "Readership",
  [FilterCategory.DOMAIN_AUTHORITY]: "Domain Authority",
  [FilterCategory.CATEGORY]: "Content Category",
  [FilterCategory.PUBLISHER]: "Publications",
  [FilterCategory.AUTHOR]: "Authors",
};
