import UrlStateManager from "@/utils/urlStateManager";
import { type StateStorage, createJSONStorage } from "zustand/middleware";

const createUrlStorage = <T>() => {
  const urlStorage: StateStorage = {
    getItem: (): string | null => {
      // Return null at root level to use default state
      if (window.location.pathname === "/newsfeeds") {
        return null;
      }

      const searchParams = new URLSearchParams(window.location.search);
      const state = UrlStateManager.parseFromUrl(searchParams);

      return JSON.stringify({ state });
    },

    setItem: (_, valueStr): void => {
      try {
        // Don't set params if we're at the root newsfeeds path
        if (window.location.pathname === "/newsfeeds") {
          window.history.replaceState(null, "", window.location.pathname);
          return;
        }

        const { state } = JSON.parse(valueStr);
        const newParams = UrlStateManager.convertToUrlParams(state);
        const search = newParams.toString();
        const newUrl = search
          ? `${window.location.pathname}?${search}`
          : window.location.pathname;

        // Only update if the URL would actually change
        if (window.location.href !== newUrl) {
          window.history.replaceState(null, "", newUrl);
        }
      } catch (error) {
        console.error("Error updating URL storage:", error);
      }
    },

    removeItem: (): void => {
      window.history.replaceState(null, "", window.location.pathname);
    },
  };

  return createJSONStorage<T>(() => urlStorage);
};

export default createUrlStorage;
