import FeedArticleBulkEdit from "@/components/news/article/FeedArticleBulkEdit";
import FeedArticleRemove from "@/components/news/article/FeedArticleRemove";
import FeedEmptyState from "@/components/news/feed/FeedEmptyState";
import FeedItem from "@/components/news/feed/FeedItem";
import CreateKeyMessageGroupModal from "@/components/news/feed/modals/CreateKeyMessageGroupModal";
import CreateKeyMessageModal from "@/components/news/feed/modals/CreateKeyMessageModal.tsx";
import SaveSearchAsKeyMessageModal from "@/components/news/feed/modals/SaveSearchAsKeyMessageModal";
import { Button } from "@/components/ui/button";
import ActionBar from "@/components/ui/molecules/ActionBar";
import type { FeedWithAugTypes } from "@/data-access/news";
import { cn } from "@/lib/utils";
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName";
import useKeyMessageStore from "@/store/key-messages/key-message.slice";
import { clearSelection, setDeleteModalOpen, setEditModalOpen, } from "@/store/news/articleManagement.actions";
import useArticleManagementStore from "@/store/news/articleManagement.slice";
import { closeDeleteFeedModal, deleteFeed, fetchNextPage, } from "@/store/news/feed.actions";
import type { FilterData } from "@/store/news/feed.slice";
import { useFeedMetadata } from "@/store/news/news.selectors";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import { PencilSimple, Trash } from "@phosphor-icons/react";
import { useCallback, useEffect, useRef, useState } from "react";
import FeedFilterHeader from "./FeedFilterHeader";
import FeedLoadingIndicator from "./FeedLoadingIndicator";

import FeedRemove from "../article/FeedRemove";
import { FeedSearch } from "./FeedSearch";
import FeedVolumeGraph from "./FeedVolumeGraph/FeedVolumeGraph";

interface FeedContentProps {
  feedId: string;
  searchTerms: string;
  feedItems: Partial<FeedWithAugTypes>[];
  className?: string;
}

const FeedContent: React.FC<FeedContentProps> = ({
  feedId,
  searchTerms,
  feedItems,
  className,
}) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const graphRef = useRef<HTMLDivElement>(null);
  const [feedSearchTop, setFeedSearchTop] = useState(0);
  const [isActionBarVisible, setIsActionBarVisible] = useState(false);
  const [displayedCount, setDisplayedCount] = useState(0);

  const { name: feedName } = useFeedMetadata();
  const isDeleteFeedModalOpen = useFeedStore(
    (state) => state.isDeleteFeedModalOpen,
  );
  const fetchingFeed = useFeedStore((state) => state.fetchingFeed);
  const fetchingSearch = useFeedStore((state) => state.fetchingSearch);
  const fetchingFilters = useFeedStore((state) => state.fetchingFilters);
  const isEmptyFeed = useFeedStore((state) => state.feedItems.length === 0);
  const categoryAppliedFilters = useFeedStore(
    (state) => state.categoryAppliedFilters,
  );
  const hasNextPage = useFeedStore((state) => state.hasNextPage);
  const continueFetchingFeed = useFeedStore(
    (state) => state.continueFetchingFeed,
  );

  const fetching = fetchingFeed || fetchingSearch || fetchingFilters;

  const selected = useFeedParamsStore((state) => state.selected);

  const isDeleteModalOpen = useArticleManagementStore(
    (state) => state.isDeleteModalOpen,
  );
  const isEditModalOpen = useArticleManagementStore(
    (state) => state.isEditModalOpen,
  );

  const numberOfArticles = useArticleManagementStore(
    (state) => state.selectedArticleIds.length,
  );

  //* Key message states*/
  const setSaveSearchKeyMessageModalOpen = useKeyMessageStore(
    (state) => state.setSaveSearchKeyMessageModalOpen,
  );
  const isSaveSearchKeyMessageModalOpen = useKeyMessageStore(
    (state) => state.isSaveSearchKeyMessageModalOpen,
  );
  const setCreateKeyMessageGroupModalOpen = useKeyMessageStore(
    (state) => state.setCreateKeyMessageGroupModalOpen,
  );
  const isCreateKeyMessageGroupModalOpen = useKeyMessageStore(
    (state) => state.isCreateKeyMessageGroupModalOpen,
  );
  const isCreateKeyMessageModalOpen = useKeyMessageStore(
    (state) => state.isCreateKeyMessageModalOpen,
  );
  const setCreateKeyMessageModalOpen = useKeyMessageStore(
    (state) => state.setCreateKeyMessageModalOpen,
  );

  const prevSearchTermsRef = useRef(searchTerms);
  const prevFeedIdRef = useRef(feedId);

  useEffect(() => {
    prevSearchTermsRef.current = searchTerms;
    prevFeedIdRef.current = feedId;
  });

  useEffect(() => {
    if (mainRef.current) {
      const shouldAdjustScroll =
        searchTerms !== prevSearchTermsRef.current ||
        feedId !== prevFeedIdRef.current;

      if (shouldAdjustScroll) {
        const scrollToPosition = () => {
          const graphHeight =
            graphRef.current?.getBoundingClientRect().height || 0;
          const currentScrollTop = mainRef.current?.scrollTop || 0;
          const targetScrollTop = Math.min(currentScrollTop, graphHeight);
          mainRef.current?.scrollTo(0, targetScrollTop);
        };
        scrollToPosition();
      }
    }
  }, [feedId, searchTerms, feedItems]);

  // Observer for triggering data fetching well in advance for paged feeds
  const handlePreloadIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (!target) return;

      if (
        target.isIntersecting &&
        !fetching &&
        !fetchingSearch &&
        !continueFetchingFeed &&
        hasNextPage
      ) {
        fetchNextPage();
      }
    },
    [fetching, fetchingSearch, continueFetchingFeed, hasNextPage],
  );

  useEffect(() => {
    const ITEM_HEIGHT = 500;
    const ITEMS_BEFORE_LOAD = 3.5;
    const marginBottom = Math.round(ITEM_HEIGHT * ITEMS_BEFORE_LOAD);

    const preloadObserver = new IntersectionObserver(
      handlePreloadIntersection,
      {
        root: mainRef.current,
        rootMargin: `0px 0px ${marginBottom}px 0px`,
        threshold: 0,
      },
    );

    if (loaderRef.current) {
      preloadObserver.observe(loaderRef.current);
    }

    return () => {
      preloadObserver.disconnect();
    };
  }, [handlePreloadIntersection]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === headerRef.current) {
          const newHeight = entry.contentRect.height;
          setFeedSearchTop(newHeight);
        }
      }
    });
    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        resizeObserver.unobserve(headerRef.current);
      }
    };
  }, []);

  const handleClearSelection = useCallback(() => {
    setIsActionBarVisible(false);
    clearSelection();
  }, []);

  const handleCloseFeedArticleRemoveModal = () => {
    setDeleteModalOpen(false);
  };

  const filterData: FilterData[] = Object.entries(categoryAppliedFilters).map(
    ([key, value]) => {
      const options = Object.entries(value.itemCounts ?? {});
      return {
        options,
        selectedOptions: selected[key] ?? [],
        title: value.name ?? key,
        categoryKey: key,
      };
    },
  );

  const showLoader = hasNextPage || continueFetchingFeed;

  useEffect(() => {
    // Only show action bar when there are selected articles
    if (numberOfArticles > 0) {
      setDisplayedCount(numberOfArticles);
      setIsActionBarVisible(true);
    } else {
      setIsActionBarVisible(false);
    }
  }, [numberOfArticles]);

  return (
    <div className={className}>
      <ActionBar onClose={handleClearSelection} isVisible={isActionBarVisible}>
        <div className="flex justify-between items-center w-full">
          <div className="text-sm">
            <span className="font-semibold pr-1">
              {addCommasToNumbers(displayedCount)}
            </span>
            <span>{pluralize(displayedCount, "article")} selected</span>
          </div>
          <div className="flex gap-x-2">
            <Button
              variant="destructive"
              onPressChange={() => setDeleteModalOpen(true)}
            >
              <Trash size={16} className="text-red-750" />
              <span className="ml-2">Delete</span>
            </Button>
            <Button
              variant="outline"
              onPressChange={() => {
                setEditModalOpen(true);
              }}
            >
              <PencilSimple size={16} className="text-gray-700" />
              <span className="ml-2">Edit</span>
            </Button>
          </div>
        </div>
      </ActionBar>

      <FeedRemove
        feedName={feedName}
        isOpen={isDeleteFeedModalOpen}
        onDelete={() => deleteFeed()}
        onClose={() => closeDeleteFeedModal()}
      />
      <FeedArticleRemove
        isOpen={isDeleteModalOpen}
        onClose={handleCloseFeedArticleRemoveModal}
        numberOfItemsToDelete={numberOfArticles}
      />
      <FeedArticleBulkEdit
        isOpen={isEditModalOpen}
        numberOfItemsToEdit={numberOfArticles}
      />
      <SaveSearchAsKeyMessageModal
        isOpen={isSaveSearchKeyMessageModalOpen}
        onClose={() => setSaveSearchKeyMessageModalOpen(false)}
        filterData={filterData}
      />
      <CreateKeyMessageGroupModal
        isOpen={isCreateKeyMessageGroupModalOpen}
        onClose={() => setCreateKeyMessageGroupModalOpen(false)}
      />
      <CreateKeyMessageModal
        isOpen={isCreateKeyMessageModalOpen}
        onClose={() => setCreateKeyMessageModalOpen(false)}
        filterData={filterData}
      />

      <div
        ref={mainRef}
        className="bg-white overflow-y-auto overflow-x-hidden h-[calc(100vh-80px)] min-w-[932px] margin-auto flex-grow"
      >
        <FeedFilterHeader filterData={filterData} ref={headerRef} />
        <div ref={graphRef}>
          <FeedVolumeGraph />
        </div>
        <FeedSearch style={{ top: `${feedSearchTop}px` }} />

        <div
          className={cn(
            "lg:max-w-[932px] lg:mx-auto pb-7 grid grid-cols-1",
            searchTerms && "gap-7",
          )}
        >
          {fetching && !continueFetchingFeed ? (
            Array.from({ length: 4 }, (_, index) => (
              <FeedItem
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={index}
                isLoading={true}
                id={index}
                setFeedArticleRemoveModalOpen={() => { }}
              />
            ))
          ) : feedItems?.length === 0 ? (
            <FeedEmptyState
              title={
                isEmptyFeed
                  ? "We're gathering your news"
                  : "No items match your filters"
              }
              body={
                isEmptyFeed
                  ? "We're searching billions of sources. Please check back soon."
                  : "Try adjusting your filters to find the news you're looking for."
              }
            />
          ) : (
            <>
              {feedItems.map((item) => (
                <FeedItem
                  key={`${item.id}-${JSON.stringify(item)}`}
                  setFeedArticleRemoveModalOpen={setDeleteModalOpen}
                  {...item}
                />
              ))}
              <div ref={loaderRef}>
                {showLoader && <FeedLoadingIndicator />}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedContent;
