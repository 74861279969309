import { Sheet, SheetContent } from "@/components/ui/controls/sheet";
import Checkbox from "@/components/ui/forms/checkbox";
import type { FeedWithAugTypes } from "@/data-access/news";
import { CategoryQuery } from "@/data-access/news/categoryQuery";
import { useAppNavigation } from "@/lib/navigation";
import { toggleArticleSelection } from "@/store/news/articleManagement.actions";
import useArticleManagementStore from "@/store/news/articleManagement.slice";
import { updateArticle } from "@/store/news/articles.actions";
import useFeedStore from "@/store/useFeedStore";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import ArticleDetailPanel from "../article/ArticleDetailPanel";
import FeedArticle from "../article/FeedArticle";

export type FeedItemProps = {
  image?: HTMLImageElement;
  isNewsFeed?: boolean;
  parentFeedId?: number;
  setFeedArticleRemoveModalOpen: (isOpen: boolean) => void;
  isLoading?: boolean;
} & Partial<FeedWithAugTypes>;

const FeedItem = ({
  id,
  articleUrl,
  image,
  isNewsFeed = false,
  parentFeedId = 0,
  setFeedArticleRemoveModalOpen,
  isLoading = false,
  ...rest
}: FeedItemProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [{ data: categoryData }] = useQuery({ query: CategoryQuery });
  const staleScore = useFeedStore((state) => state.refreshingScore);
  const { feedId, articleId } = useParams();
  const { navigateToFeed, navigatePreserveQuery } = useAppNavigation();

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (id === undefined) return;

    const unsubscribe = useArticleManagementStore.subscribe((state) => {
      const isArticleSelected = state.selectedArticleIds.includes(id);
      setIsSelected(isArticleSelected);
    });

    // Initial state
    setIsSelected(
      useArticleManagementStore.getState().selectedArticleIds.includes(id),
    );

    return () => unsubscribe();
  }, [id]);

  const deletedArticleIds = useArticleManagementStore(
    (state) => state.deletedArticleIds,
  );

  const isArticleSelected = articleId === id?.toString();
  const [isSheetOpen, setIsSheetOpen] = useState(isArticleSelected);

  useEffect(() => {
    setIsSheetOpen(isArticleSelected);
  }, [isArticleSelected]);

  const updateNavUrl = useCallback(() => {
    if (isNewsFeed && parentFeedId) {
      navigateToFeed(parentFeedId);
    } else {
      navigatePreserveQuery(
        `/newsfeeds/${feedId}${!isArticleSelected ? `/articles/${id}` : ""}`,
      );
    }
  }, [feedId, isArticleSelected, navigateToFeed, navigatePreserveQuery]);

  const handleDeleteClick = () => {
    setFeedArticleRemoveModalOpen(true);
  };

  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    if (id !== undefined && deletedArticleIds.includes(id)) {
      setIsDeleting(true);
      // Wait for animation to complete before removing from DOM
      const timeout = setTimeout(() => {
        setIsRemoved(true);
      }, 350);
      return () => clearTimeout(timeout);
    }
  }, [deletedArticleIds, id]);

  if (id === undefined || isRemoved) {
    return null;
  }

  return (
    <div
      className={`flex items-center will-change-[opacity,transform,max-height] transition-all duration-200 ease-out ${
        isDeleting
          ? "opacity-0 max-h-0 my-0 overflow-hidden translate-y-[-8px] scale-95"
          : "opacity-100 max-h-[500px] my-3.5 translate-y-0 scale-100"
      }`}
      aria-label={`Feed item: ${rest.headline || "Article"}`}
    >
      {!!feedId && (
        <Checkbox
          className="mr-4"
          id={`checkbox-${id}`}
          checked={isSelected}
          onCheckedChange={() => id !== undefined && toggleArticleSelection(id)}
          aria-label={`Select ${rest.headline || "article"}`}
        >
          <span className="sr-only">Select article</span>
        </Checkbox>
      )}
      <FeedArticle
        categories={categoryData?.categories ?? []}
        handleClick={updateNavUrl}
        handleDeleteClick={handleDeleteClick}
        onChange={updateArticle}
        image={image}
        staleScore={staleScore}
        article={{ ...rest, id, articleUrl }}
        isSelected={isSelected}
        isLoading={isLoading}
      />
      <Sheet open={isSheetOpen}>
        <SheetContent
          className="w-[680px] sm:max-w-[680px]"
          hasClose={false}
          onInteractOutside={updateNavUrl}
        >
          <ArticleDetailPanel {...rest} id={id} articleUrl={articleUrl} />
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default FeedItem;
