import { isTokenExpired } from "@/lib/utils";
import useAuth from "@/store/auth.slice";
import { apiEndpointBaseUrl } from "./constants";

let refreshPromise: Promise<string | null> | null = null;
let currentUserId: number | null = null;
let abortController: AbortController | null = null;
let lastRefreshId: number | null = null;

const refreshToken = async (
  refreshTokenStr: string,
  refreshId: number,
): Promise<string | null> => {
  if (isTokenExpired(refreshTokenStr)) {
    return null;
  }

  // Create new abort controller for this request
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  const userId = useAuth.getState().user?.id;

  try {
    const response = await fetch(`${apiEndpointBaseUrl}/auth/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshTokenStr }),
      signal: abortController.signal,
    });

    if (!response.ok) {
      throw new Error("Token refresh failed");
    }

    // Check if this refresh is still valid for the current user
    if (lastRefreshId !== refreshId || currentUserId !== userId) {
      console.log("User or refresh ID changed during refresh, cancelling");
      return null;
    }

    const body = await response.json();

    // Verify the user is still the same before updating state
    if (useAuth.getState().user?.id === userId) {
      useAuth.setState({
        accessToken: body.access,
        refreshToken: body.refresh,
      });
      return body.access;
    }
    return null;
  } catch (error) {
    if (error instanceof Error && error.name === "AbortError") {
      return null;
    }
    // Only log error if it's not during initial login
    const isInitialLogin = !useAuth.getState().loggedIn;
    if (!isInitialLogin) {
      console.error("Error refreshing token:", error);
    }

    return null;
  } finally {
    if (lastRefreshId === refreshId && currentUserId === userId) {
      abortController = null;
    }
  }
};

const clearRefreshState = () => {
  if (abortController) {
    abortController.abort();
    abortController = null;
  }
  refreshPromise = null;
  currentUserId = null;
  lastRefreshId = null;
};

export const getAuthToken = async (
  token = useAuth.getState().accessToken || "",
): Promise<string | null> => {
  const userId = useAuth.getState().user?.id;

  // If token is valid, return it immediately
  if (token && !isTokenExpired(token)) return token;

  const refresh = useAuth.getState().refreshToken || "";
  if (refresh && !isTokenExpired(refresh)) {
    // Cancel existing refresh if user changed
    if (refreshPromise && currentUserId !== userId) {
      console.log(
        `User changed from ${currentUserId} to ${userId}, clearing refresh state`,
      );
      clearRefreshState();
    }

    // If there's already a refresh in progress for the same user, return its promise
    // This is critical for preventing multiple concurrent refresh calls
    if (refreshPromise && currentUserId === userId) {
      return refreshPromise;
    }

    // Create new refresh promise with user tracking
    const refreshId = Date.now();
    currentUserId = userId;
    lastRefreshId = refreshId;

    // Store the promise before making the request
    refreshPromise = refreshToken(refresh, refreshId);
    return refreshPromise;
  }
  return null;
};
