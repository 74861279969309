import { toast } from "@/components/ui/toast";
import { deleteArticles } from "@/store/news/articleManagement.actions";
import useArticleManagementStore from "@/store/news/articleManagement.slice";
import { feedMessages } from "@/store/news/feed.types";
import { useParams } from "react-router-dom";

export const useDeleteFeedArticle = () => {
  const selectedArticleIds = useArticleManagementStore(
    (state) => state.selectedArticleIds,
  );
  const { feedId } = useParams();

  const handleDeleteArticle = async (reason: string) => {
    if (!feedId) return;

    const success = await deleteArticles(reason);

    if (success) {
      toast(feedMessages.articleDelete.success(selectedArticleIds.length));
    } else {
      toast(feedMessages.articleDelete.error.failed);
    }
  };

  return { handleDeleteArticle };
};
