import { create } from "@/data-access/core/createStore";
import {
  type PublishersSlice,
  createPublishersSlice,
} from "./news/publishers.slice";

type usePublisherStoreType = PublishersSlice & {};

const usePublisherStore = create<usePublisherStoreType>("publisher-store")(
  (...a) => ({
    ...createPublishersSlice(...a),
  }),
);

export default usePublisherStore;
