import Sparkle from "@/assets/svg/Sparkle.svg?react";
import { Button } from "@/components/ui/button";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/field";
import ButtonGroup from "@/components/ui/molecules/ButtonGroup";
import { EditButton } from "@/components/ui/molecules/EditButton";
import {
  Select,
  SelectItem,
  SelectListBox,
  SelectPopover,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TextArea, TextField } from "@/components/ui/textfield";
import type { ArticleSentimentType } from "@/data-access/news";
import { cn } from "@/lib/utils";
import { estimateLines } from "@/lib/utils/fontMetrics";
import { useFeedMetadata } from "@/store/news/news.selectors";
import type { OptionType } from "@/types/shared";
import {
  CircleNotch,
  Smiley,
  SmileyMeh,
  SmileySad,
  Sparkle as SparkleIcon,
} from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import SentimentTooltip from "./SentimentTooltip";

const sentimentOptions: OptionType[] = [
  { label: "Positive", value: "positive" },
  { label: "Neutral", value: "neutral" },
  { label: "Negative", value: "negative" },
];

interface ArticleSentimentPolarityProps {
  staleScore?: boolean;
  articleSentiment:
    | {
        polarity: string;
        rationale: string;
      }
    | null
    | undefined;
  overriddenArticleSentiment:
    | {
        polarity: string;
        rationale: string;
        intensity: number;
      }
    | null
    | undefined;
  onSentimentChange: (sentiment: ArticleSentimentType) => Promise<void>;
}

const ArticleSentimentPolarity: React.FC<ArticleSentimentPolarityProps> = ({
  articleSentiment,
  overriddenArticleSentiment,
  onSentimentChange,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const currentRationale =
    (overriddenArticleSentiment?.rationale || articleSentiment?.rationale) ??
    "";
  const currentPolarity =
    (
      overriddenArticleSentiment?.polarity || articleSentiment?.polarity
    )?.toLowerCase() ?? "";
  const [unsavedPolarity, setUnsavedPolarity] = useState(currentPolarity);
  const [rationale, setRationale] = useState(currentRationale);
  const [polarity, setPolarity] = useState(currentPolarity);
  const [errorMessage, setErrorMessage] = useState("");
  // const [polarityMark, setPolarityMark] = useState("");
  const [isAISentiment, setIsAISentiment] = useState(
    polarity === articleSentiment?.polarity,
  );

  const feedName = useFeedMetadata().name;

  const getSentimentIcon = (polarity: string | undefined, size?: number) => {
    switch (polarity) {
      case "positive":
        return <Smiley size={size ?? 24} />;
      case "negative":
        return <SmileySad size={size ?? 24} />;
      default:
        return <SmileyMeh size={size ?? 24} />;
    }
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const onUpdateSentimentPolarity = async () => {
    if (polarity) {
      setSaving(true);
      await onSentimentChange({
        polarity,
        rationale: rationale.length > 0 ? rationale : " ",
        intensity: new Date().getTime(),
      });
      setUnsavedPolarity(polarity);
      setSaving(false);
      setEditing(false);
      // setPolarityMark(`Manually edited ${format(new Date(), "MM/dd/yyyy")}`);
    } else {
      setAttemptedSave(true);
      setErrorMessage("Required");
    }
  };

  const onUndo = () => {
    setPolarity(articleSentiment?.polarity || "");
    setRationale(articleSentiment?.rationale || "");
  };

  useEffect(() => {
    setRationale("");
    setTimeout(() => {
      rationaleRef.current?.focus();
    }, 0);
    setAttemptedSave(false);
    setErrorMessage("");
    const isAISentiment = polarity === articleSentiment?.polarity;
    setIsAISentiment(isAISentiment);
    if (isAISentiment) setRationale(articleSentiment.rationale);
    // setPolarityMark("");
  }, [polarity]);

  useEffect(() => {
    if (isAISentiment) return;
    setErrorMessage(
      attemptedSave && rationale.trim().length === 0
        ? "Please provide a rationale."
        : "",
    );
  }, [rationale]);

  const TEXT_AREA_WIDTH_PX = 715;
  const ESTIMATED_CHAR_WIDTH_PX = 6;
  const PADDING_LINES = 2;
  const MIN_LINE_COUNT = 5; // In case no AI rationale present

  const onCancel = () => {
    setPolarity(unsavedPolarity);
    setRationale(currentRationale);
    setEditing(false);
  };

  const rationaleRef = useRef<HTMLTextAreaElement>(null);
  const [attemptedSave, setAttemptedSave] = useState(false);

  const textAreaLineCount =
    Math.max(
      estimateLines(
        articleSentiment?.rationale || "",
        ESTIMATED_CHAR_WIDTH_PX,
        TEXT_AREA_WIDTH_PX,
      ),
      MIN_LINE_COUNT,
    ) + PADDING_LINES;

  const displaySparkle = (value: string) => {
    return value.toLowerCase() === articleSentiment?.polarity;
  };

  return (
    <div
      className={cn(
        "group rounded",
        isHovered && "bg-slate-200/20",
        "p-1 -m-1 mr-1 relative flex flex-row space-x-1 items-center text-sm text-gray-700/90 font-medium",
        unsavedPolarity === "positive" && "text-green-700",
        unsavedPolarity === "negative" && "text-rose-700/90",
      )}
    >
      {unsavedPolarity ? (
        <SentimentTooltip
          title={`Why is this article ${polarity} for ${feedName}?`}
          content={rationale}
          icon={
            <span
              className={cn(
                polarity === "positive" && "text-green-800",
                polarity === "negative" && "text-rose-700/90",
              )}
            >
              {getSentimentIcon(polarity, 16)}
            </span>
          }
        >
          <>
            {getSentimentIcon(unsavedPolarity)}
            <span className="first-letter:uppercase font-semibold">
              {unsavedPolarity}
            </span>
          </>
        </SentimentTooltip>
      ) : (
        <>-</>
      )}
      <EditButton
        onEditClick={handleEditClick}
        onHoverChange={setIsHovered}
        className="right-1"
      />
      <DialogOverlay
        isOpen={isEditing}
        onOpenChange={setEditing}
        shouldCloseOnInteractOutside={() => unsavedPolarity === polarity}
      >
        <DialogContent closeButton={true} onClose={onCancel} size="default">
          <DialogHeader title="Edit Article Sentiment" />
          <DialogBody className="space-y-4">
            <Select
              className="max-w-48"
              selectedKey={polarity}
              onSelectionChange={(key) => setPolarity(key as string)}
            >
              {isAISentiment ? (
                <Label className="items-center gap-1 text-xs">
                  <span>AI Sentiment</span>
                  <Sparkle />
                </Label>
              ) : (
                <Label className="text-xs">
                  Sentiment<span className="text-gray-500 font-normal">*</span>
                </Label>
              )}
              <SelectTrigger>
                <SelectValue className="first-letter:uppercase">
                  {polarity}
                </SelectValue>
              </SelectTrigger>
              <SelectPopover>
                <SelectListBox>
                  {sentimentOptions.map((option) => (
                    <SelectItem
                      key={option.value}
                      id={option.value}
                      textValue={option.label}
                      className="cursor-pointer hover:bg-gray-100 data-[hovered=true]:bg-gray-100 data-[pressed=true]:bg-gray-200"
                    >
                      <span>{option.label}</span>
                      {displaySparkle(option.value) && <Sparkle />}
                    </SelectItem>
                  ))}
                </SelectListBox>
              </SelectPopover>
            </Select>
            <TextField
              className="group flex flex-col relative"
              value={rationale}
              onChange={setRationale}
              isRequired
              name="rationale"
              type="text"
            >
              {isAISentiment ? (
                <Label className="items-center gap-1 text-gray-500 text-xs">
                  AI Rationale
                  <SparkleIcon className="text-gray-500" />
                </Label>
              ) : (
                <Label className="items-center text-xs">
                  Rationale<span className="text-gray-500 font-normal">*</span>
                </Label>
              )}
              <TextArea
                ref={rationaleRef}
                disabled={isAISentiment}
                rows={textAreaLineCount}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.stopPropagation();
                  }
                }}
                className={cn(
                  "pb-5 focus:outline-1 text-xs focus:outline-offset-0 data-[focus-visible=true]:ring-offset-0 data-[focused=true]:ring-0 data-[focused=true]:border-blue-600 data-[focus-visible=true]:border-blue-600 min-h-[100px]",
                  errorMessage && "border-red-600",
                )}
                placeholder="Add a rationale..."
              />
              {errorMessage && (
                <div className="text-sm text-red-700 font-400 pt-2 leading-tight">
                  {errorMessage}
                </div>
              )}
              {/* {!isAISentiment && polarityMark && (
                <span
                  className={cn(
                    "absolute text-xxs left-3 text-gray-200 bottom-2",
                  )}
                >
                  {polarityMark}
                </span>
              )} */}
            </TextField>
          </DialogBody>
          <DialogFooter className="flex">
            <div
              className={cn(
                "w-full flex",
                !isAISentiment ? "justify-between" : "justify-end",
              )}
            >
              {!isAISentiment && (
                <Button
                  variant="ghost"
                  size="sm"
                  onPress={onUndo}
                  className="-ml-2"
                >
                  Revert to AI Sentiment
                  <Sparkle className="ml-1" />
                </Button>
              )}
              <ButtonGroup
                size="sm"
                primaryAction={
                  polarity === unsavedPolarity && rationale === currentRationale
                    ? onCancel
                    : onUpdateSentimentPolarity
                }
                primaryLabel={isSaving ? "Saving" : "Save"}
                primaryIcon={
                  isSaving && (
                    <CircleNotch className="animate-spin h-5 w-5 mr-2" />
                  )
                }
                secondaryAction={onCancel}
                secondaryLabel="Cancel"
              />
            </div>
          </DialogFooter>
        </DialogContent>
      </DialogOverlay>
    </div>
  );
};

export default ArticleSentimentPolarity;
