import { create } from "@/data-access/core/createStore";
import { createJSONStorage, persist } from "zustand/middleware";
import type { FilterComponentStateSlice } from "./news/filterComponentState.slice";
import { createFilterComponentStateSlice } from "./news/filterComponentState.slice";

const useFilterComponentStore = create<FilterComponentStateSlice>(
  "filter-store",
)(
  persist(
    (...a) => ({
      ...createFilterComponentStateSlice(...a),
    }),
    {
      name: "filterComponentStore",
      storage: createJSONStorage(() => localStorage),
      version: 1,
      migrate: (persistedState) => {
        const emptyState: FilterComponentStateSlice = {
          filterComponentStates: {
            IMPACT_SCORE: true,
          },
          setFilterComponentState: () => {},
          toggleFilterComponentState: () => {},
        };

        if (!persistedState) return emptyState;

        return {
          ...emptyState,
          filterComponentStates: {
            ...emptyState.filterComponentStates,
            ...((persistedState as FilterComponentStateSlice)
              ?.filterComponentStates || {}),
          },
        };
      },
    },
  ),
);

export default useFilterComponentStore;
