import { Button } from "@/components/ui/button";
import { CaretDown } from "@phosphor-icons/react";
import type React from "react";

interface ShowMoreButtonProps {
  showMore: boolean;
  onToggle: () => void;
}

const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({
  showMore,
  onToggle,
}) => {
  const getButtonContent = () => (
    <>
      <CaretDown
        size={12}
        className={`transition-transform mr-0.5 text-gray-400 ${
          showMore ? "rotate-180" : ""
        }`}
      />
      <span className="text-[10px] font-medium text-gray-500">
        Show{showMore ? " less" : " more"}
      </span>
    </>
  );

  return (
    // Using Button component as a wrapper with padding
    <Button
      variant="link"
      className="pl-1.5 py-0 h-5 text-gray-500"
      onPress={onToggle}
    >
      {getButtonContent()}
    </Button>
  );
};

export default ShowMoreButton;
