import { toast } from "@/components/ui/toast";
import {
  type FeedWithAugTypes,
  type UpdateArticleMutationInputType,
  deleteFeedArticleMutation,
} from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import useFeedStore from "@/store/useFeedStore";
import useArticleManagementStore from "./articleManagement.slice";
import { feedMessages } from "./feed.types";

export const toggleArticleSelection = (id: number | undefined) => {
  if (id === undefined) return;

  const { selectedArticleIds } = useArticleManagementStore.getState();
  const newSelectedIds = new Set(selectedArticleIds);

  if (newSelectedIds.has(id)) {
    newSelectedIds.delete(id);
  } else {
    newSelectedIds.add(id);
  }

  const newSelectedArray = Array.from(newSelectedIds);

  // Force a store update with the new selection array
  useArticleManagementStore.setState(
    {
      selectedArticleIds: newSelectedArray,
    },
    false,
    "[User] Article: Toggle selection",
  );
};

export const setSelectedArticles = (ids: number[]) => {
  useArticleManagementStore.setState(
    { selectedArticleIds: ids },
    false,
    "[User] Article: Set selected",
  );
};

export const clearSelection = () => {
  useArticleManagementStore.setState(
    {
      selectedArticleIds: [],
      isDeleteModalOpen: false,
      isEditModalOpen: false,
      editChanges: {},
    },
    false,
    "[User] Article: Clear selection",
  );
};

export const setDeleteModalOpen = (isOpen: boolean) => {
  useArticleManagementStore.setState(
    { isDeleteModalOpen: isOpen },
    false,
    "[User] Article: Set delete modal",
  );
};

export const setEditModalOpen = (isOpen: boolean) => {
  useArticleManagementStore.setState(
    {
      isEditModalOpen: isOpen,
      // Reset changes when closing the modal
      ...(isOpen ? {} : { editChanges: {} }),
    },
    false,
    "[User] Article: Set edit modal",
  );
};

export const setEditChanges = (changes: UpdateArticleMutationInputType) => {
  const { editChanges } = useArticleManagementStore.getState();

  const isEmptyChanges = Object.keys(changes).length === 0;

  useArticleManagementStore.setState(
    { editChanges: isEmptyChanges ? {} : { ...editChanges, ...changes } },
    false,
    "[User] Article: Update edit changes",
  );
};

export const updateArticlesInStore = (
  articleIds: number[],
  changes: UpdateArticleMutationInputType,
) => {
  const { feedItems } = useFeedStore.getState();

  // Create a set of IDs to update for faster lookup
  const updateIdsSet = new Set(articleIds);

  // Update the feed items with the new changes
  const updatedFeedItems = feedItems.map((item: Partial<FeedWithAugTypes>) => {
    if (updateIdsSet.has(Number(item.id))) {
      return {
        ...item,
        ...(changes.headline && { overriddenHeadline: changes.headline }),
        ...(changes.publisher && { overriddenPublisher: changes.publisher }),
        ...(changes.category && {
          overriddenArticleNewsCategory: changes.category,
        }),
        ...(changes.summary && { overriddenSummary: changes.summary }),
        ...(changes.datePublished && {
          overriddenDatePublished: changes.datePublished,
        }),
        ...(changes.author && {
          overriddenArticleAuthors: [{ id: "0", name: changes.author }],
        }),
        ...(changes.isLede && { isLede: changes.isLede }),
        ...(changes.prominence && { prominence: [changes.prominence] }),
        ...(changes.sentimentPolarity && {
          overriddenArticleSentiment: {
            polarity: changes.sentimentPolarity,
            rationale: changes.sentimentRationale || "",
            intensity: 0.5,
          },
        }),
        ...(changes.isLede !== undefined && { isLede: changes.isLede }),
      } as FeedWithAugTypes;
    }
    return item;
  });

  // Update the store with modified items
  useFeedStore.setState(
    { feedItems: updatedFeedItems },
    false,
    "[User] Articles: Updated in feed",
  );
};

export const deleteArticles = async (reason: string) => {
  const { selectedArticleIds } = useArticleManagementStore.getState();
  const { feedId } = useFeedStore.getState();

  if (!feedId) {
    toast(feedMessages.articleDelete.error.failed);
    return false;
  }

  try {
    const result = await client
      .mutation(deleteFeedArticleMutation, {
        input: {
          feedId: feedId.toString(),
          feedArticleId: selectedArticleIds.map(String),
          reason,
        },
      })
      .toPromise();

    if (result.data?.deleteFeedArticle.__typename === "OperationInfo") {
      const messages = result.data.deleteFeedArticle.messages;
      for (const message of messages) {
        toast.error(message.message);
      }
      return false;
    }

    // Update local state
    const { feedItems, allFeedIds, totalCount } = useFeedStore.getState();

    // Create a set of IDs to delete
    const deleteIdsSet = new Set(selectedArticleIds);
    const deleteIdsStringSet = new Set(selectedArticleIds.map(String));

    // Update store state
    useFeedStore.setState(
      {
        feedItems: feedItems.filter(
          (item) => !deleteIdsSet.has(Number(item.articleId)),
        ),
        allFeedIds: allFeedIds.difference(deleteIdsStringSet),
        totalCount: Math.max(0, (totalCount ?? 0) - selectedArticleIds.length),
      },
      false,
      "[User] Articles: Deleted from feed",
    );

    // Update deleted articles state and clear selection
    useArticleManagementStore.setState(
      {
        deletedArticleIds: selectedArticleIds,
        selectedArticleIds: [],
        isDeleteModalOpen: false,
      },
      false,
      "[User] Articles: Clear selection after delete",
    );

    return true;
  } catch (error) {
    toast(feedMessages.articleDelete.error.failed);
    return false;
  }
};
