import { create } from "@/data-access/core/createStore";
import type {
  CreateKeyMessageGroupVariablesType,
  CreateKeyMessageVariablesType,
  GetKeyMessagesAndGroupsVariablesType,
  GetKeyMessagesVariablesType,
  UpdateKeyMessageGroupVariablesType,
  UpdateKeyMessageVariablesType,
} from "@/data-access/news";
import { useFeedParamsStore } from "@/store/useFeedStore";
import type { StateCreator } from "zustand";
import {
  setCreateKeyMessageGroupModalOpen,
  setCreateKeyMessageModalOpen,
  setEditingKeyMessageId,
  setExpandedSearchTerm,
  setIsAdvancedSearch,
  setIsEditingKeyMessage,
  setIsEditingKeyMessageGroup,
  setKeyMessageGroupName,
  setKeyMessageName,
  setSaveSearchKeyMessageModalOpen,
  setSelectedGroup,
  setSelectedGroups,
  setSelectedKeyMessageIds,
} from "./key-message.actions";

import {
  createKeyMessage,
  createKeyMessageGroup,
  fetchKeyMessages,
  fetchKeyMessagesAndGroups,
  updateKeyMessage,
  updateKeyMessageGroup,
} from "./key-message-async.actions";

export type KeyMessage = {
  id: string;
  name: string;
  group: { id: string; name: string } | null;
  feedArticleIds: number[];
  searchTerms: string;
  feedArticleFilters: string;
  total: number;
};

export type KeyMessageGroup = {
  id: string;
  name: string;
  keyMessageItems: {
    id: string;
    name: string;
    group: { id: string; name: string } | null;
    feedArticleIds: number[];
    searchTerms: string;
    feedArticleFilters: string;
    total: number;
  }[];
};

// Modal State Slice
export type ModalStateSlice = {
  expandedSearchTerm: string;
  isCreateKeyMessageGroupModalOpen: boolean;
  isCreateKeyMessageModalOpen: boolean;
  isSaveSearchKeyMessageModalOpen: boolean;
  keyMessageGroupName: string;
  keyMessageName: string;
  selectedGroup: {
    id: string;
    name: string;
  } | null;
  editingKeyMessageId: string | null;
  setCreateKeyMessageGroupModalOpen: (isOpen: boolean) => void;
  setCreateKeyMessageModalOpen: (isOpen: boolean) => void;
  setExpandedSearchTerm: (term: string) => void;
  setKeyMessageGroupName: (name: string) => void;
  setKeyMessageName: (name: string) => void;
  setSaveSearchKeyMessageModalOpen: (isOpen: boolean) => void;
  setSelectedGroup: (group: { id: string; name: string } | null) => void;
  setEditingKeyMessageId: (id: string | null) => void;
};

// Filter State Slice
export type FilterStateSlice = {
  selectedKeyMessageIds: Set<string>;
  selectedGroups: Set<string>;
  setSelectedKeyMessageIds: (
    updateFn: (prevSelected: Set<string>) => Set<string>,
  ) => void;
  setSelectedGroups: (groups: Set<string>) => void;
};

// Creation State Slice
export type CreateAndUpdateStateSlice = {
  createKeyMessageGroupState: "initial" | "complete";
  createKeyMessageState: "initial" | "complete";
  isCreatingKeyMessage: boolean;
  isCreatingKeyMessageGroup: boolean;
  isEditingKeyMessage: boolean;
  isEditingKeyMessageGroup: boolean;

  createKeyMessageGroup: (
    input: CreateKeyMessageGroupVariablesType["input"],
  ) => Promise<{ id: string; name: string } | undefined>;
  createKeyMessage: (
    input: CreateKeyMessageVariablesType["input"],
  ) => Promise<void>;
  updateKeyMessageGroup: (
    input: UpdateKeyMessageGroupVariablesType["input"],
  ) => Promise<void>;
  updateKeyMessage: (
    input: UpdateKeyMessageVariablesType["input"],
  ) => Promise<void>;
  setIsEditingKeyMessageGroup: (isEditing: boolean) => void;
  setIsEditingKeyMessage: (isEditing: boolean) => void;
};

// Data Fetch Slice
export type DataFetchSlice = {
  keyMessageGroups: KeyMessageGroup[];
  keyMessages: KeyMessage[];
  isFetchingKeyMessageGroups: boolean;
  isFetchingKeyMessages: boolean;
  isFetchingKeyMessagesAndGroups: boolean;
  fetchKeyMessagesAndGroups: (
    filters: GetKeyMessagesAndGroupsVariablesType["filters"],
  ) => Promise<void>;
  fetchKeyMessages: (
    filters: GetKeyMessagesVariablesType["filters"],
  ) => Promise<void>;
};

// Search Slice
export type SearchSlice = {
  isAdvancedSearch: boolean;
  setIsAdvancedSearch: (isAdvancedSearch: boolean) => void;
};

export type KeyMessageSlice = ModalStateSlice &
  CreateAndUpdateStateSlice &
  DataFetchSlice &
  FilterStateSlice &
  SearchSlice;

const createKeyMessageSlice: StateCreator<KeyMessageSlice, [], []> = () => ({
  keyMessageGroups: [],
  keyMessages: [],

  /** Filter State */
  selectedKeyMessageIds: new Set(
    useFeedParamsStore.getState().selected?.KEY_MESSAGES || [],
  ),
  setSelectedKeyMessageIds,
  selectedGroups: new Set(),
  setSelectedGroups,

  /** Modal State */
  expandedSearchTerm: "",
  isCreateKeyMessageGroupModalOpen: false,
  isCreateKeyMessageModalOpen: false,
  isSaveSearchKeyMessageModalOpen: false,
  keyMessageGroupName: "",
  keyMessageName: "",
  selectedGroup: null,
  editingKeyMessageId: null,
  setCreateKeyMessageGroupModalOpen,
  setCreateKeyMessageModalOpen,
  setExpandedSearchTerm,
  setKeyMessageGroupName,
  setKeyMessageName,
  setSaveSearchKeyMessageModalOpen,
  setSelectedGroup,
  setEditingKeyMessageId,

  /** Create and Update Key message and group state */
  createKeyMessage,
  createKeyMessageGroup,
  createKeyMessageGroupState: "initial",
  createKeyMessageState: "initial",
  isCreatingKeyMessage: false,
  isCreatingKeyMessageGroup: false,
  isEditingKeyMessage: false,
  isEditingKeyMessageGroup: false,
  isFetchingKeyMessagesAndGroups: false,
  setIsEditingKeyMessage,
  setIsEditingKeyMessageGroup,
  updateKeyMessage,
  updateKeyMessageGroup,

  /** Fetch Key Messages and Groups */
  isFetchingKeyMessageGroups: false,
  isFetchingKeyMessages: false,
  fetchKeyMessagesAndGroups,
  fetchKeyMessages,

  /** Search State */
  isAdvancedSearch: false,
  setIsAdvancedSearch,
});

// key message store
const useKeyMessageStore = create<KeyMessageSlice>("key-messages")(
  createKeyMessageSlice,
);

export default useKeyMessageStore;
