import type { FeedDataTermsType, FeedWithAugTypes } from "@/data-access/news";
import { derive } from "@/lib/derive-zustand";
import { useStore } from "zustand";
import useFeedStore, { useFeedParamsStore } from "../useFeedStore";
import useArticleDeletionStore from "./articleManagement.slice";
import type { DerivedFilterItem } from "./feed.slice";
import { FilterCategory } from "./feed.types";

interface FeedMetadata {
  name: string;
  terms: FeedDataTermsType[];
  tags: string[];
}

// Helper to handle serialized Sets from Redux DevTools
function ensureSet<T>(value: Set<T> | Iterable<T> | null | undefined): Set<T> {
  if (value instanceof Set) {
    return value as Set<T>;
  }
  if (value != null) {
    return new Set<T>(value as Iterable<T>);
  }
  return new Set<T>();
}

const deriveFeedMetadata = derive<FeedMetadata>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const feed = feedId
    ? (feedSidebarData.find((f) => +f?.id === feedId) ?? null)
    : null;
  const name = feed?.name ?? "";
  const terms = feed?.terms ?? [];
  const tags = feed?.knownTags?.map((t) => t.tag) ?? [];
  return { name, terms, tags };
});

const deriveFilteredFeedItems = derive<Partial<FeedWithAugTypes>[]>((get) => {
  const { feedItems, filteredFeedIds, filterFeedIdSearch, selectedStackIds } =
    get(useFeedStore);
  const { searchTerms } = get(useFeedParamsStore);

  const filteredIdsSet = ensureSet<number>(filteredFeedIds);

  let filteredItems = feedItems.filter((item) =>
    filteredIdsSet.has(+(item.id ?? -1)),
  );

  if (selectedStackIds?.length > 0) {
    filteredItems = filteredItems.filter((item) =>
      selectedStackIds.includes(+(item.id ?? -1)),
    );
  }

  // If search is active but returned no matches, return empty array to show "no results" UI
  if (searchTerms && filterFeedIdSearch.length === 0) {
    return [];
  }

  // If search is active, only show items that matched the search
  if (searchTerms) {
    const searchIds = new Set(filterFeedIdSearch.map(([id]) => id));
    filteredItems = filteredItems.filter((item) =>
      searchIds.has(+(item.id ?? -1)),
    );
  }

  // Apply search hits to items
  return filteredItems.map((item) => {
    const searchHit = filterFeedIdSearch.find(([id]) => item.id === id)?.[1];
    return {
      ...item,
      searchHit: searchHit ?? undefined,
    } as Partial<FeedWithAugTypes>;
  });
});

const deriveFilteredCount = derive<number>((get) => {
  const { filteredFeedIds } = get(useFeedStore);
  const { deletedArticleIds } = get(useArticleDeletionStore);

  const filteredSet = ensureSet<number>(filteredFeedIds);
  const deletedSet = ensureSet<number>(deletedArticleIds);

  return filteredSet.difference(deletedSet).size;
});

interface FilterCounts {
  selection: number;
  displayed: number;
  total: number;
}

// Filter counts and totals
const deriveFilterCounts = derive<FilterCounts>((get) => {
  const {
    filteredFeedIds,
    filterFeedIdSearch,
    selectedStackIds,
    feedVolume,
    totalCount,
  } = get(useFeedStore);
  const { searchTerms } = get(useFeedParamsStore);
  const deleteIds = new Set(get(useArticleDeletionStore).deletedArticleIds);

  const filteredSet = ensureSet<number>(filteredFeedIds);
  const deletedSet = ensureSet<number>(deleteIds);

  const getActiveCount = (ids: Set<number> | number[]) =>
    ensureSet(Array.isArray(ids) ? ids : Array.from(ids)).difference(deletedSet)
      .size;

  const displayedTotal =
    selectedStackIds.length > 0
      ? getActiveCount(filteredSet.intersection(new Set(selectedStackIds)))
      : feedVolume !== null
        ? searchTerms.length > 0
          ? getActiveCount(filterFeedIdSearch.map(([id]) => id))
          : feedVolume
        : getActiveCount(
          searchTerms.length > 0
            ? filterFeedIdSearch.map(([id]) => id)
            : filteredSet,
        );

  return {
    selection: getActiveCount(filteredSet),
    displayed: displayedTotal,
    total: totalCount ?? 0,
  };
});

const deriveDisplayedFilterIds = derive<Set<number>>((get) => {
  const { filteredFeedIds, filterFeedIdSearch, selectedStackIds } =
    get(useFeedStore);
  const { searchTerms } = get(useFeedParamsStore);
  const deletedArticleIds = new Set(
    get(useArticleDeletionStore).deletedArticleIds,
  );

  const filteredSet = ensureSet<number>(filteredFeedIds);
  let result = filteredSet;

  if (searchTerms.length > 0) {
    const searchIds = new Set(filterFeedIdSearch.map(([id]) => id));
    result = result.intersection(searchIds);
  }

  if (selectedStackIds.length > 0) {
    result = result.intersection(new Set(selectedStackIds));
  }

  return result.difference(ensureSet<number>(deletedArticleIds));
});

const deriveFilterGroups = derive<
  {
    key: string;
    title: string;
    filters: DerivedFilterItem[];
    sorted: boolean;
  }[]
>((get) => {
  const { categoryAppliedFilters, fetchingFilters, filteredFeedIds } =
    get(useFeedStore);
  const { selected } = get(useFeedParamsStore);
  const deleteIds = new Set(get(useArticleDeletionStore).deletedArticleIds);

  const filteredSet = ensureSet<number>(filteredFeedIds);
  const deletedSet = ensureSet<number>(deleteIds);

  return Object.entries(categoryAppliedFilters)
    .filter(([_, v]) => v)
    .map(([key, value]) => {
      return {
        key,
        title: value?.name ?? key,
        sorted: value?.sorted ?? true,
        filters: !value?.itemCounts
          ? []
          : Object.entries(value.itemCounts)
            .sort(([a], [b]) =>
              key === FilterCategory.SOCIAL ||
                key === FilterCategory.READERSHIP
                ? a === "0"
                  ? 1
                  : b === "0"
                    ? -1
                    : 0
                : 0,
            )
            .map(([filter, ids], id) => {
              return {
                id: key + filter + id, // passed the index of the array to make it unique. As the filter value can be same for different filters. ex: cnn
                value: filter,
                totalCount: fetchingFilters
                  ? 0
                  : ensureSet(ids).difference(deletedSet).size,
                count: fetchingFilters
                  ? 0
                  : filteredSet.intersection(ensureSet(ids)).size,
                selected: new Set(selected[key] || []).has(filter),
              };
            }),
      };
    });
});

interface Percentages {
  selection: number | null;
  displayed: number | null;
}

// Analytics selectors
const derivePercentages = derive<Percentages>((get) => {
  const { selection, displayed, total } = get(deriveFilterCounts);
  const calcPercent = (value: number) =>
    total ? Math.round((value / total) * 100) : null;

  return {
    selection: calcPercent(selection),
    displayed: calcPercent(displayed),
  };
});

// Hooks
const useFeedMetadata = () => useStore(deriveFeedMetadata);
const useFilteredFeedItems = () => useStore(deriveFilteredFeedItems);
const useFilterGroups = () => useStore(deriveFilterGroups);
const useFilterCounts = () => useStore(deriveFilterCounts);
const useFilteredCount = () => useStore(deriveFilteredCount);
const usePercentages = () => useStore(derivePercentages);
const useDisplayedFilterIds = () => useStore(deriveDisplayedFilterIds);

// Exports
export {
  useFeedMetadata,
  useFilteredFeedItems,
  useFilterGroups,
  useFilterCounts,
  usePercentages,
  useFilteredCount,
  useDisplayedFilterIds,
};
