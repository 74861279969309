import { toast } from "@/components/ui/toast";
import { apiEndpointBaseUrl } from "@/data-access/core/constants";
import { clearAllStores } from "@/data-access/core/createStore";
import { resetClient } from "@/lib/urqlProvider";
import { type UserInfo, defaultUserInfo } from "@/types/user/userInfo";
import useAuth from "./auth.slice";

// Constants and Types
const errorMessages = {
  invalidCredentials:
    "The username or password you entered is incorrect. Please try again.",
  failedRegistration:
    "We encountered an issue while creating your account. Please try again later.",
  googleLoginFailed:
    "There was an issue logging in with Google. Please retry or use a different login method.",
  resetPasswordFailed: "An error occurred. Please try again later.",
  resetPassConfirmFailed: "An error occurred. Please try again later.",
  changePasswordFailed: "Failed to change password. Please try again.",
} as const;

export interface LoginPayload {
  email: string;
  password: string;
}

export interface GoogleLoginPayload {
  client_id: string;
  access_token: string;
}

export interface RegisterPayload {
  email: string;
  password1: string;
  password2: string;
}

export interface ResetPasswordConfirmPayload {
  uid: string | null;
  token: string | null;
  new_password1: string;
  new_password2: string;
}

export interface AuthResponse {
  user?: UserInfo;
  access?: string;
  refresh?: string;
  detail?: string;
  email?: string[];
  non_field_errors?: string[];
}

interface SafeResponse {
  ok: boolean;
  status?: number;
  json: () => Promise<unknown>;
}

// Type Guards
const isAuthResponse = (data: unknown): data is AuthResponse => {
  return (
    !!data &&
    typeof data === "object" &&
    ("access" in data || "user" in data || "detail" in data)
  );
};

// Utility Functions
const safeFetch = async (
  url: string,
  options: RequestInit,
): Promise<SafeResponse> => {
  try {
    const response = await fetch(url, options);
    return response;
  } catch (error) {
    console.error("Error in fetch:", error);
    return {
      ok: false,
      status: 500,
      json: async () => ({ error: "Fetch failed" }),
    };
  }
};

const handleAuthError = (error: unknown) => {
  const message = error instanceof Error ? error.message : String(error);
  console.error(error);
  toast.error(message);
  useAuth.setState({ status: "failed", error: message });
};

const createAuthRequest = (endpoint: string, payload: object) => ({
  url: `${apiEndpointBaseUrl}/auth/${endpoint}/`,
  options: {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  },
});

const setAuthState = (body: AuthResponse) => {
  if (!body) {
    console.error("Missing body in setAuthState:", body);
    return;
  }

  useAuth.setState({
    user: body.user || defaultUserInfo,
    accessToken: body.access || null,
    refreshToken: body.refresh || null,
    loggedIn: Boolean(body.access),
    isEmployee: body.user?.email?.endsWith("@handraise.com") ?? false,
    status: "succeeded",
    error: null,
  });
};

// Auth Actions
let isLoggingOut = false;

export const logoutUser = async () => {
  if (isLoggingOut) {
    console.log("Logout already in progress");
    return;
  }
  isLoggingOut = true;

  try {
    const token = useAuth.getState().accessToken;
    const userId = useAuth.getState().user?.id;
    console.log(`Starting logout for user ${userId}`);

    if (token) {
      try {
        const response = await safeFetch(`${apiEndpointBaseUrl}/auth/logout/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          console.warn("Logout API call failed:", response.status);
        }
      } catch (error) {
        console.error("Error when calling logout API:", error);
      }
    }

    const resetSuccess = await resetClient();
    if (!resetSuccess) {
      console.warn("URQL client reset may not have completed successfully");
    }

    clearAllStores("/auth/login");

    if (window.navigator?.credentials) {
      try {
        await window.navigator.credentials.preventSilentAccess();
      } catch (error) {
        console.warn("Error clearing credentials:", error);
      }
    }

    console.log("Logout completed successfully");
  } catch (error) {
    console.error("Error during logout cleanup:", error);
    clearAllStores("/auth/login");
  } finally {
    isLoggingOut = false;
  }
};

export const startLogin = async ({ email, password }: LoginPayload) => {
  useAuth.setState({ status: "loading", error: null });
  try {
    const { url, options } = createAuthRequest("login", { email, password });
    const resp = await safeFetch(url, options);
    const body = await resp.json();

    if (!isAuthResponse(body)) {
      throw new Error(errorMessages.invalidCredentials);
    }

    if (resp.ok && body.access) {
      try {
        await resetClient();
        setAuthState(body);
      } catch (error) {
        console.error("Error during client reset:", error);
        // Still set auth state even if client reset fails
        setAuthState(body);
      }
    } else {
      throw new Error(errorMessages.invalidCredentials);
    }
  } catch (error) {
    handleAuthError(error);
  }
};

export const startRegister = async (payload: RegisterPayload) => {
  useAuth.setState({ status: "loading" });
  try {
    const { url, options } = createAuthRequest("registration", payload);
    const resp = await safeFetch(url, options);
    const body = await resp.json();

    if (!isAuthResponse(body)) {
      throw new Error(errorMessages.failedRegistration);
    }

    if (resp.ok) {
      await resetClient();
      setAuthState(body);
    } else {
      console.error("Error Registering", body.email, body.non_field_errors);
      throw new Error(errorMessages.failedRegistration);
    }
  } catch (error) {
    handleAuthError(error);
  }
};

export const startGoogleLogin = async (payload: GoogleLoginPayload) => {
  useAuth.setState({ status: "loading" });
  try {
    const { url, options } = createAuthRequest("gauth", payload);
    const resp = await safeFetch(url, options);
    const body = await resp.json();

    if (!isAuthResponse(body)) {
      throw new Error(errorMessages.googleLoginFailed);
    }

    if (resp.ok) {
      await resetClient();
      setAuthState(body);
    } else {
      console.error(body.non_field_errors);
      throw new Error(errorMessages.googleLoginFailed);
    }
  } catch (error) {
    handleAuthError(error);
  }
};

export const startResetPassword = async (email: string) => {
  useAuth.setState({ status: "loading" });
  try {
    const { url, options } = createAuthRequest("password/reset", { email });
    const resp = await safeFetch(url, options);
    const body = await resp.json();

    if (!isAuthResponse(body)) {
      throw new Error(errorMessages.resetPasswordFailed);
    }

    if (resp.ok) {
      toast.success(body.detail);
      useAuth.setState({ status: "succeeded", error: null });
    } else {
      throw new Error(errorMessages.resetPasswordFailed);
    }
  } catch (error) {
    handleAuthError(error);
  }
};

export const resetPasswordConfirm = async (
  payload: ResetPasswordConfirmPayload,
) => {
  useAuth.setState({ status: "loading" });
  try {
    const { url, options } = createAuthRequest(
      "password/reset/confirm",
      payload,
    );
    const resp = await safeFetch(url, options);
    const body = await resp.json();

    if (!isAuthResponse(body)) {
      throw new Error(errorMessages.resetPassConfirmFailed);
    }

    if (resp.ok) {
      toast.success(body.detail);
      useAuth.setState({ status: "succeeded", error: null });
    } else {
      throw new Error(errorMessages.resetPassConfirmFailed);
    }
  } catch (error) {
    handleAuthError(error);
  }
};
