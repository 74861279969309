import { isProduction } from "@/config";
import { accountReducer } from "@/store/reducers/accountReducer";
import type { ConfigureStoreOptions, Middleware } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { emptySplitApi } from "./emptyApi";

// Check for Redux DevTools extension, which is great for debugging Redux apps.
// Install it from: https://chromewebstore.google.com/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
const hasReduxDevtoolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

const middleware: ConfigureStoreOptions["middleware"] = (
  getDefaultMiddleware,
) =>
  !isProduction && !hasReduxDevtoolsExtension
    ? getDefaultMiddleware().concat(logger as Middleware)
    : getDefaultMiddleware();

const store = configureStore({
  reducer: {
    account: accountReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware,
  devTools: !isProduction,
});

export type AppDispatch = typeof store.dispatch;

export default store;
