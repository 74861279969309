import {
  SideBarDataQuery,
  type SideBarQueryFeedType,
} from "@/data-access/news";
import { useFeature } from "@/lib/feature-management";
import useFeedStore from "@/store/useFeedStore";
import { NewspaperClipping } from "@phosphor-icons/react";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "urql";
import DropdownList from "./dropdownList";

const SidebarNavigation = ({
  filter,
  collapsed = false,
}: { filter?: string; collapsed?: boolean }) => {
  const showNewsPage = useFeature("news-page", false);
  const updateSideBarData = useFeedStore(
    (store) => store.updateFeedSideBarData,
  );
  const [feeds, setFeeds] = useState<SideBarQueryFeedType>([]);

  const [{ data }] = useQuery({
    query: SideBarDataQuery,
    requestPolicy: "cache-and-network",
  });

  const filterItems = useCallback(
    <T extends SideBarQueryFeedType>(items: T | undefined) => {
      if (!items) return [];
      return items.filter((item) => {
        const itemName = (item.name as string).toLowerCase();
        const filterValue = filter?.toLowerCase() || "";
        return itemName.includes(filterValue);
      });
    },
    [filter],
  );

  useEffect(() => {
    if (!data) return;
    updateSideBarData(data.feeds);
  }, [data]);

  const filterFeeds = useCallback(
    (feeds: SideBarQueryFeedType) =>
      setFeeds(filterItems(feeds) as SideBarQueryFeedType),
    [data, filter],
  );

  useEffect(() => {
    const feeds =
      data?.feeds.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      ) ?? [];

    if (!filter) {
      setFeeds(feeds);
    } else {
      filterFeeds(feeds);
    }
  }, [data, filter]);

  return (
    <>
      {showNewsPage && (
        <DropdownList
          title="Newsfeeds"
          items={feeds}
          icon={NewspaperClipping}
          basePath="/newsfeeds"
          open={true}
          collapsed={collapsed}
        />
      )}
    </>
  );
};

export default SidebarNavigation;
