import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import Checkbox from "@/components/ui/forms/checkbox";
import { Input, JollyTextField } from "@/components/ui/textfield";
import InlineSearchEditor from "@/components/ui/tiptap-editor/InlineSearchEditor";
import { ExpandedSearchEditor } from "@/components/ui/tiptap-editor/TiptapEditor";
import { useFeature } from "@/lib/feature-management";
import { cn } from "@/lib/utils";
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName";
import useKeyMessageStore from "@/store/key-messages/key-message.slice";
import { setSelectedArticles } from "@/store/news/articleManagement.actions";
import useArticleManagementStore from "@/store/news/articleManagement.slice";
import {
  filterBySearchTerms,
  isFeedFiltered,
} from "@/store/news/filterSearch.actions";
import {
  useDisplayedFilterIds,
  useFilterCounts,
  useFilteredFeedItems,
  usePercentages,
} from "@/store/news/news.selectors";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { FeedSort } from "./FeedSort";

interface FeedSearchProps {
  style?: React.CSSProperties;
}

export const FeedSearch: React.FC<FeedSearchProps> = ({ style }) => {
  const filteredFeedIds = useDisplayedFilterIds();
  const selectedArticleIds = useArticleManagementStore(
    (state) => state.selectedArticleIds,
  );
  const fetchingFeed = useFeedStore((state) => state.fetchingFeed);
  const fetchingSearch = useFeedStore((state) => state.fetchingSearch);
  // * key message states*/
  const setSaveSearchKeyMessageModalOpen = useKeyMessageStore(
    (state) => state.setSaveSearchKeyMessageModalOpen,
  );
  const setKeyMessageName = useKeyMessageStore(
    (state) => state.setKeyMessageName,
  );
  const setExpandedSearchTerm = useKeyMessageStore(
    (state) => state.setExpandedSearchTerm,
  );

  const searchTerms = useFeedParamsStore((state) => state.searchTerms);

  const { displayed: displayPercentage } = usePercentages();
  const { displayed: displayedItemsCount } = useFilterCounts();

  const filteredFeedItems = useFilteredFeedItems();

  const [isFilteredUI, setIsFilteredUI] = useState<boolean>(isFeedFiltered());
  const isAdvancedSearchOpen = useKeyMessageStore(
    (state) => state.isAdvancedSearch,
  );
  const setIsAdvancedSearchOpen = useKeyMessageStore(
    (state) => state.setIsAdvancedSearch,
  );

  // * Feature Flags*/
  const hasKeyMessagesAccess = useFeature("enable-key-messages", false);

  useEffect(() => {
    setIsFilteredUI(isFeedFiltered());
  }, [filteredFeedItems]);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Only to restore the existing search terms
    searchTerms;
  }, [searchTerms]);

  useEffect(() => {
    if (!searchTerm.length && !hasKeyMessagesAccess) return;
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    if (isAdvancedSearchOpen && !hasKeyMessagesAccess) return;

    debounceTimeout.current = setTimeout(() => {
      filterBySearchTerms(searchTerm);
    }, 500);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm]);

  const handleSubmitAdvancedSearch = () => {
    filterBySearchTerms(searchTerm);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      filterBySearchTerms(searchTerm);
      // Move focus to the next element after submitting
      (e.target as HTMLElement).blur();
      // Find the next focusable element and focus it
      const focusableElements = document.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );
      const currentIndex = Array.from(focusableElements).indexOf(
        e.target as HTMLElement,
      );
      if (currentIndex >= 0 && currentIndex < focusableElements.length - 1) {
        (focusableElements[currentIndex + 1] as HTMLElement).focus();
      }
    }
    // Allow Tab key to move focus out of the component
    if (e.key === "Tab") {
      // Don't prevent default - let the browser handle tab navigation
      return;
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleToggleAll = () => {
    const ids = Array.from(filteredFeedIds);
    // If all are selected, deselect all. Otherwise, select all.
    if (selectedArticleIds.length === ids.length) {
      setSelectedArticles([]);
    } else {
      const numericIds = ids.map(Number);
      setSelectedArticles(numericIds);
    }
  };

  const renderNumberOfArticles = () => {
    return (
      <>
        <span className="font-semibold">
          {addCommasToNumbers(displayedItemsCount)}{" "}
        </span>
        <span>{pluralize(displayedItemsCount, "Article")} </span>
        {isFilteredUI &&
          displayPercentage !== 0 &&
          displayPercentage &&
          !Number.isNaN(displayPercentage) && (
            <span>({displayPercentage}%)</span>
          )}
        {isFilteredUI && displayPercentage === 0 && (
          <span className={"text-xs text-gray-600"}>
            (Adjust filters to see more)
          </span>
        )}
      </>
    );
  };

  const renderSearchInput = () => {
    if (!isAdvancedSearchOpen) {
      if (hasKeyMessagesAccess) {
        return (
          <InlineSearchEditor
            handleSave={() => {
              setKeyMessageName(searchTerm);
              setSaveSearchKeyMessageModalOpen(true);
            }}
            handleSearch={handleSearch}
            handleExpand={() => setIsAdvancedSearchOpen(true)}
            content={searchTerm}
          />
        );
      }

      return (
        <div className="relative flex items-center w-auto">
          <MagnifyingGlass
            size={16}
            className="text-gray-600 absolute left-2.5"
          />
          <Input
            placeholder="Search for articles"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              // Ensure we're not trapping focus
              if (e.target.value === "") {
                // If the input is empty, allow tabbing out
                e.currentTarget.setAttribute("data-allow-tab-out", "true");
              }
            }}
            onKeyDown={(e) => handleKeyPress(e)}
            onBlur={() => {
              // When the input loses focus, ensure we can tab to the next element
              setTimeout(() => {
                const nextFocusableElement = document.querySelector(
                  'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled])',
                ) as HTMLElement;
                if (nextFocusableElement) {
                  nextFocusableElement.setAttribute("tabindex", "0");
                }
              }, 0);
            }}
            className={cn(
              "w-80 mr-1 h-9 py-2 pr-[4.5rem] items-end will-change-[width] transition-[width] duration-150 ease-in-out transform-gpu",
            )}
          />
          <Button
            type="button"
            variant="input"
            size="input"
            onPress={() => setIsAdvancedSearchOpen(!isAdvancedSearchOpen)}
            onKeyDown={(e) => {
              // Allow Tab key to move focus out of the component
              if (e.key === "Tab") {
                // Don't prevent default - let the browser handle tab navigation
                return;
              }
            }}
          >
            Expand
          </Button>
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      <div
        className={cn("max-w-[956px] lg:mx-auto  z-30 sticky", "top-[88px]")}
        style={style}
      >
        {isAdvancedSearchOpen &&
          (hasKeyMessagesAccess ? (
            <ExpandedSearchEditor
              content={searchTerm}
              handleExpand={() => setIsAdvancedSearchOpen(false)}
              handleSave={() => setSaveSearchKeyMessageModalOpen(true)}
              handleSearch={handleSearch}
              isSearching={fetchingSearch}
              onUpdate={(editor) => {
                setKeyMessageName(editor.getText());
                setExpandedSearchTerm(editor.getText());
              }}
            />
          ) : (
            <div className="px-4 py-4 bg-white">
              <JollyTextField
                textArea
                label="Advanced Search"
                value={searchTerm}
                onChange={(searchTerm) => setSearchTerm(searchTerm)}
                onKeyDown={handleKeyPress}
              />
              <div className="flex justify-end gap-2 mt-2">
                <Button
                  type="button"
                  variant="ghost"
                  onPress={() => setIsAdvancedSearchOpen(false)}
                >
                  Cancel
                </Button>
                <Button variant="outline" onPress={handleSubmitAdvancedSearch}>
                  Submit
                </Button>
              </div>
            </div>
          ))}
        <div className="flex px-3 pt-2 items-baseline justify-between bg-white">
          <div className="text-sm flex-grow text-slate-900/95 flex-1 leading-tight tracking-[0.14px] flex">
            {fetchingFeed || fetchingSearch ? (
              <Skeleton className="w-32 h-5 bg-slate-300/50" />
            ) : (
              <>
                <Checkbox
                  checked={selectedArticleIds.length === filteredFeedIds.size}
                  isSelectAll={true}
                  isPartialSelect={
                    !!selectedArticleIds.length &&
                    selectedArticleIds.length !== filteredFeedIds.size
                  }
                  onCheckedChange={handleToggleAll}
                >
                  {renderNumberOfArticles()}
                </Checkbox>
              </>
            )}
            {hasKeyMessagesAccess && (
              <div className={"ml-3"}>
                <FeedSort />
              </div>
            )}
          </div>

          <div className="flex gap-1 items-end pb-1.5">
            {renderSearchInput()}
            {!hasKeyMessagesAccess && <FeedSort />}
          </div>
        </div>
        <div className="z-10 bg-gradient-to-b from-white via-white/50 via-70% h-4 -mx-1 to-transparent" />
      </div>
    </>
  );
};
