/**
 * Removes specified keys from an object and returns a new object.
 *
 * @export
 * @template T
 * @template K
 * @param {T} obj - The object from which keys should be omitted.
 * @param {...K[]} keys - The keys to omit from the object.
 * @return {Omit<T, K>} - A new object without the specified keys.
 */
export const omit = <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Omit<T, K> => {
  const result = { ...obj };
  for (const key of keys) {
    delete result[key];
  }
  return result;
};

/**
 * Deeply compares two values to determine if they are equivalent.
 *
 * @export
 * @template T
 * @param {T} a - The first value to compare.
 * @param {T} b - The second value to compare.
 * @return {boolean} - Returns true if the values are deeply equal, otherwise false.
 */
export const deepEqual = <T>(a: T, b: T): boolean => {
  if (a === b) return true;

  if (
    typeof a !== "object" ||
    typeof b !== "object" ||
    a === null ||
    b === null
  ) {
    return false;
  }

  const keysA = Object.keys(a as Record<string, unknown>);
  const keysB = Object.keys(b as Record<string, unknown>);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (
      !keysB.includes(key) ||
      !deepEqual(
        (a as Record<string, unknown>)[key],
        (b as Record<string, unknown>)[key],
      )
    ) {
      return false;
    }
  }

  return true;
};
