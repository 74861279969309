import {
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogOverlay,
} from "@/components/ui/dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";

import DefaultAvatar from "@/assets/images/default-avatar.png";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/controls/separator";
import { JollyTextField } from "@/components/ui/textfield";
import { setTmpPicture, startUpdateAccount } from "@/store/actions/settings";
import useAuth from "@/store/auth.slice";
import { Upload } from "@phosphor-icons/react";

const formSchema = z.object({
  email: z.string().min(1, { message: "Name is required." }),
});

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SettingsModal = ({ isOpen, onClose }: SettingsModalProps) => {
  const user = useAuth((state) => state.user);
  const { avatar } = user;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const dispatch: any = useDispatch();
  const [image, setImage] = useState((avatar as string) || "");

  const { handleSubmit, control } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: user.email || "",
    },
  });

  useEffect(() => {
    dispatch(setTmpPicture(image));
  }, [image, dispatch]);

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    const reader = new FileReader();
    if (files && files.length > 0) {
      if (!files[0]) return;
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        setImage(e.target?.result as string);
        dispatch(startUpdateAccount({ avatar: e.target?.result as string }));
      };
    }
  };

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    try {
      await dispatch(
        startUpdateAccount({
          first_name: "",
          last_name: "",
          email: data.email,
        }),
      );
      onClose();
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  };

  const handlePasswordReset = () => {
    dispatch(startUpdateAccount({ password: "" }));
  };

  return (
    <DialogOverlay
      isOpen={isOpen}
      isDismissable
      onOpenChange={(isOpen) => !isOpen && onClose()}
    >
      <DialogContent>
        <DialogHeader title="Account Settings" />
        <DialogBody className="space-y-6">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="space-y-6">
              {/* Profile Photo */}
              <div>
                <label
                  htmlFor="profile-photo"
                  className="font-medium text-gray-700 text-xs block"
                >
                  Profile Photo
                </label>
                <div className="flex py-4">
                  <div className="relative inline-block">
                    <img
                      src={image || DefaultAvatar}
                      alt="Profile"
                      className="w-[120px] h-[120px] rounded-full object-cover will-change-transform"
                    />
                    <div className="absolute inset-0 rounded-full ring-1 ring-inset ring-gray-200/80" />
                    <button
                      className="opacity-0 hover:opacity-100 focus:opacity-100 flex gap-1.5 items-center justify-center absolute inset-[1px] cursor-pointer rounded-full overflow-hidden transition-opacity duration-200 ease-in-out will-change-opacity focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-750 focus-visible:ring-offset-2"
                      onClick={() =>
                        document.getElementById("profile-photo")?.click()
                      }
                      type="button"
                    >
                      <div className="absolute inset-0 bg-black/70 backdrop-blur-[6px] transition-[backdrop-filter,background-color] duration-500 ease-in-out will-change-[backdrop-filter]" />
                      <Upload className="z-10 w-3.5 h-3.5 text-white" />
                      <span className="z-10 text-xs text-white">Change</span>
                    </button>
                    <input
                      id="profile-photo"
                      name="profile-photo"
                      className="hidden"
                      type="file"
                      tabIndex={-1}
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              {/* Email Field */}
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <JollyTextField
                    label="Name"
                    placeholder="Your name"
                    {...field}
                    required
                    size="sm"
                  />
                )}
              />

              <div className="flex justify-end">
                <Button variant="default" type="submit" size="sm">
                  Save changes
                </Button>
              </div>
            </div>
          </form>
          <Separator />
          <div>
            <h3 className="text-gray-800 font-medium text-sm">
              Password Reset
            </h3>
            <p className="text-gray-500 text-xs">
              You'll receive an email with a link to create a new password.
            </p>
            <div className="flex justify-end mt-2">
              <Button
                variant="secondary"
                size="sm"
                onPress={handlePasswordReset}
                type="button"
              >
                Password Reset
              </Button>
            </div>
          </div>
        </DialogBody>
      </DialogContent>
    </DialogOverlay>
  );
};

export default SettingsModal;
