import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
} from "@/components/ui/dialog";
import { JollyTextField } from "@/components/ui/textfield";
import { useAppNavigation } from "@/lib/navigation";
import { addArticle } from "@/store/news/articles.actions";
import { useCallback, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const MODAL_PARAM = "dialog" as const;

type AddArticleURLModalProps = {
  open: boolean;
  onClose: () => void;
};

const AddArticleURLModal = ({ open, onClose }: AddArticleURLModalProps) => {
  const { feedId } = useParams();
  const [_, setSearchParams] = useSearchParams();
  const { navigateToArticlePreview } = useAppNavigation();

  const [url, setUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [isAddingArticle, setIsAddingArticle] = useState(false);

  const validateUrl = (value: string) => {
    if (!value.trim()) {
      setErrorMessage("Required");
      return false;
    }

    const urlRegex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    if (!urlRegex.test(value)) {
      setErrorMessage("Invalid link");
      return false;
    }

    setErrorMessage(undefined);
    return true;
  };

  const handleInputChange = useCallback(
    (value: string) => {
      setUrl(value);
      if (errorMessage) {
        // Clear error when user starts typing again
        setErrorMessage(undefined);
      }
    },
    [errorMessage],
  );

  const handleAddArticle = async () => {
    if (!validateUrl(url)) {
      return;
    }
    setIsAddingArticle(true);
    addArticle(url)
      .then((result) => {
        if (feedId && result) {
          navigateToArticlePreview(feedId, result.id, {
            from: "add-article-url",
          });
        }
        handleClose();
      })
      .finally(() => {
        setIsAddingArticle(false);
      });
  };

  const handleClose = () => {
    onClose();
    setUrl("");
    setErrorMessage(undefined);
    setSearchParams(
      (prev) => {
        prev.delete(MODAL_PARAM);
        return prev;
      },
      { replace: true },
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !isAddingArticle) {
      handleAddArticle();
    }
  };

  return (
    <DialogOverlay
      isOpen={open}
      onOpenChange={(isOpen: boolean) =>
        !isOpen && !isAddingArticle && handleClose()
      }
    >
      <DialogContent size="default">
        <DialogHeader title="Add an article" />
        <DialogBody>
          <JollyTextField
            autoFocus
            clearable
            errorMessage={errorMessage}
            label="URL/Link"
            onChange={handleInputChange}
            placeholder="https://example.com"
            required
            size="sm"
            value={url}
            onKeyDown={handleKeyDown}
          />
        </DialogBody>
        <DialogFooter
          primaryLabel={isAddingArticle ? "Adding" : "Add Article"}
          secondaryLabel="Cancel"
          primaryAction={handleAddArticle}
          isPending={isAddingArticle}
          secondaryAction={handleClose}
        />
      </DialogContent>
    </DialogOverlay>
  );
};

export default AddArticleURLModal;
