import { Menu, MenuPopover, MenuSeparator } from "@/components/ui/menu";
import { CustomMenuItem, type MenuItemConfig } from "@/components/ui/menu-item";
import { HeadCircuit } from "@phosphor-icons/react"; // Changed to AI-related icon
import { Fragment } from "react";

interface StoryClusterMenuProps {
  handleMenuClick: () => void;
}

const StoryClusterMenu: React.FC<StoryClusterMenuProps> = ({
  handleMenuClick,
}) => {
  const menuItems: MenuItemConfig[] = [
    {
      icon: (
        <div className="flex items-center gap-1">
          <HeadCircuit size={12} weight="fill" className="text-violet-600" />{" "}
          {/* Changed to BrainCircuit icon */}
        </div>
      ),
      label: "Generate AI Summary",
      className: "text-violet-600",
      onClick: handleMenuClick,
    },
  ];

  return (
    <MenuPopover placement="end">
      <Menu>
        {menuItems.map((item, index) => (
          <Fragment key={item.label}>
            <CustomMenuItem config={item} />
            {index === menuItems.length - 2 && (
              <MenuSeparator className="mx-1" />
            )}
          </Fragment>
        ))}
      </Menu>
    </MenuPopover>
  );
};

export { StoryClusterMenu };
