import { useFeature } from "@/lib/feature-management";
import { FeaturesReady } from "@growthbook/growthbook-react";
import { type ReactNode, Suspense, createElement, memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { CustomLoader } from "@/shared/CustomLoader";

import MainLayout from "./components/layouts/MainLayout";
import { HTTP_STATUS } from "./data-access/core/httpConstants";
import { PendoTracker } from "./lib/analytics/PendoTracker";
import ArticlePreview from "./modules/article-preview/ArticlePreview";
import NewsFeedsPage from "./pages/NewsFeedsPage";
import useAuthRoutes from "./pages/auth/useAuthRoutes";
import ArticlePreviewPage from "./pages/newsfeeds/ArticlePreviewPage";
import NewsFeedsScreen from "./pages/newsfeeds/FeedPage";
import NewsFeedsLayout from "./pages/newsfeeds/NewsFeedsLayout";
import { LazyLoader } from "./shared/LazyLoader";
import useAuth from "./store/auth.slice";

export default memo(function AppRouter() {
  const campaignsEnabled = useFeature("campaigns-enabled", false);
  const loggedIn = useAuth((state: { loggedIn: boolean }) => state.loggedIn);
  // biome-ignore lint/suspicious/noExplicitAny: <using outdated dispatch that is not setup correctly, any is required>
  const dispatch: any = useDispatch();
  const authRoutes = useAuthRoutes();

  const getProtectedElement = useMemo(
    () =>
      (
        element: React.FC<{ children: ReactNode[] | ReactNode }>,
        redirectTo = "/auth/login",
      ) => {
        return loggedIn ? (
          // biome-ignore lint/correctness/noChildrenProp: <react router dom requires this>
          createElement(element, { children: <Outlet /> })
        ) : (
          <Navigate to={redirectTo} />
        );
      },
    [loggedIn],
  );

  const router = useMemo(
    () =>
      createBrowserRouter(
        [
          authRoutes,
          {
            path: "/article-preview/:id",
            Component: ArticlePreview,
          },
          {
            path: "/",
            element: getProtectedElement(MainLayout),
            children: [
              {
                path: "",
                element: (
                  <>
                    <PendoTracker />
                    <Navigate to="/newsfeeds" />
                  </>
                ),
              },
              {
                path: "newsfeeds",
                element: <NewsFeedsLayout />,
                children: [
                  {
                    path: "",
                    element: <NewsFeedsPage />,
                    loader: async () => {
                      return { feedId: null };
                    },
                  },
                  {
                    path: ":feedId",
                    loader: async ({ params }) => {
                      const feedId = params.feedId;
                      if (!feedId) {
                        throw new Response("No feed id provided", {
                          status: HTTP_STATUS.BAD_REQUEST,
                        });
                      }
                      const parsedFeedId = Number.parseInt(feedId, 10);
                      if (Number.isNaN(parsedFeedId)) {
                        throw new Response("Invalid feed id", {
                          status: HTTP_STATUS.BAD_REQUEST,
                        });
                      }
                      return { feedId: parsedFeedId };
                    },
                    children: [
                      {
                        path: "articles/:articleId",
                        element: <NewsFeedsScreen />,
                      },
                      {
                        path: "article-preview/:articleId",
                        element: <ArticlePreviewPage />,
                      },
                      {
                        path: "",
                        element: <NewsFeedsScreen />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "*",
                element: <Navigate to="/newsfeeds" />,
              },
            ],
          },
        ],
        {
          future: {
            v7_relativeSplatPath: true,
          },
        },
      ),
    [authRoutes, campaignsEnabled, getProtectedElement, dispatch],
  );

  return (
    <Suspense fallback={<LazyLoader delay={500} />}>
      <FeaturesReady timeout={500} fallback={<CustomLoader />}>
        <RouterProvider
          router={router}
          future={{
            v7_startTransition: true,
          }}
        />
      </FeaturesReady>
    </Suspense>
  );
});
