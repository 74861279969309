import { Button } from "@/components/ui/button";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/field";
import { Progress } from "@/components/ui/progress";
import { Input } from "@/components/ui/textfield";
import { toast } from "@/components/ui/toast";
import { createHeaders } from "@/data-access/core/authenticatedFetch";
import { dataServiceBaseUrl } from "@/data-access/core/constants";
import { cn } from "@/lib/utils";
import useAuth from "@/store/auth.slice";
import useFeedStore from "@/store/useFeedStore";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { FileCsv, X, XCircle } from "@phosphor-icons/react";
import { useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

type UploadState = {
  file: File;
  customName: string;
  status: "naming" | "uploading" | "completed" | "error";
  progress: number;
  errorMessage?: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const UPLOAD_CSV_VALUE = "upload-csv" as const;

function UploadCSVModal(props: Props) {
  const { open, onClose } = props;
  const [isDragging, setIsDragging] = useState(false);
  const [uploadState, setUploadState] = useState<UploadState | null>(null);
  const [cancelToken, setCancelToken] = useState<AbortController | null>(null);
  const tenant = useAuth((state) => state.user.default_tenant?.slug);
  const [_, setSearchParams] = useSearchParams();

  const progressRef = useRef<HTMLButtonElement>(null);

  const feedId = useFeedStore((state) => state.feedId);

  const maxSize = 200; // Size in MB

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleFileSelection = (file: File) => {
    setUploadState({
      file,
      customName: file.name,
      status: "naming",
      progress: 0,
    });
  };

  const handleUploadFile = async () => {
    if (!uploadState) return;

    setUploadState((prev) =>
      prev ? { ...prev, status: "uploading", progress: 0 } : null,
    );

    setTimeout(() => {
      if (progressRef.current) progressRef.current.focus();
    }, 0);

    const _cancelToken = new AbortController();
    setCancelToken(_cancelToken);

    //TODO: Look at this to get it to match our patterns
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: `
      mutation UploadCSVFile($feedId: ID!, $file: Upload!, $fileName: String!) {
        uploadCsvFile(input: { feedId: $feedId, file: $file, fileName: $fileName}) {
          ... on SuccessResponse {
            message
          }
          ... on OperationInfo {
            messages {
              kind
              message
            }
          }
        }
      }
    `,
        variables: {
          feedId: feedId?.toString() ?? "",
          fileName: uploadState.customName,
        },
      }),
    );
    formData.append("map", JSON.stringify({ "1": ["variables.file"] }));
    formData.append("1", uploadState.file);

    const headers = await createHeaders("GET", "", tenant);
    try {
      const response = await fetch(`${dataServiceBaseUrl}/graphql/`, {
        method: "POST",
        headers,
        body: formData,
        signal: _cancelToken.signal,
      });

      const result = await response.json();
      const resultData = result?.data?.uploadCsvFile;

      if (result.errors || resultData.messages) {
        const errorMessage = `${resultData.messages[0]?.kind}: ${resultData.messages[0]?.message}`;
        setUploadState((prev) =>
          prev ? { ...prev, status: "error", progress: 0, errorMessage } : null,
        );
      } else {
        const message = resultData.message ?? "File uploaded successfully";
        setTimeout(() => {
          setUploadState((prev) =>
            prev ? { ...prev, status: "uploading", progress: 20 } : null,
          );

          setTimeout(() => {
            setUploadState((prev) =>
              prev ? { ...prev, status: "uploading", progress: 58 } : null,
            );

            setTimeout(() => {
              setUploadState((prev) =>
                prev ? { ...prev, status: "completed", progress: 100 } : null,
              );

              setTimeout(() => {
                handleCloseModal();
                toast({
                  type: "success",
                  title: message,
                  description: "We are now intelligently processing your file.",
                });
              }, 1000);
            }, 333);
          }, 333);
        }, 333);
      }
    } catch (error) {
      toast({
        type: "error",
        title: "Upload failed",
        description: "Please try again later.",
      });

      setUploadState((prev) =>
        prev ? { ...prev, status: "error", progress: 0 } : null,
      );
    }
  };

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      if (file.size <= maxSize * 1024 * 1024) {
        handleFileSelection(file);
      } else {
        alert(`File size must be less than ${maxSize}MB`);
      }
    } else {
      alert("Please upload a CSV file");
    }
  }, []);

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        if (file.size <= maxSize * 1024 * 1024) {
          handleFileSelection(file);
        } else {
          alert(`File size must be less than ${maxSize}MB`);
        }
      }
    },
    [],
  );

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadState((prev) =>
      prev ? { ...prev, customName: e.target.value } : null,
    );
  };

  const handleNameReset = () => {
    setUploadState((prev) => (prev ? { ...prev, customName: "" } : null));
  };

  const goBack = () => {
    setUploadState(null);
  };

  const handleCloseModal = () => {
    if (cancelToken) cancelToken.abort();
    setUploadState(null);
    setSearchParams(
      (prev) => {
        prev.delete(UPLOAD_CSV_VALUE);
        return prev;
      },
      { replace: true },
    );
    onClose();
  };

  return (
    <DialogOverlay
      isOpen={open}
      onOpenChange={(isOpen) => !isOpen && handleCloseModal()}
      isDismissable={false}
    >
      <DialogContent size="default">
        {!uploadState ? (
          <>
            <DialogBody
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <h1 className="my-1 text-gray-800 text-sm font-semibold font-['Inter'] leading-normal">
                Upload CSV file
              </h1>
              <div
                className={cn(
                  "mt-3 border-2 border-dashed rounded transition-colors ",
                  isDragging
                    ? "border-blue-750 bg-muted"
                    : "border-muted-foreground/25",
                )}
              >
                <div className="flex flex-col items-center justify-center gap-2 text-center h-[147px]">
                  <p className="text-xs">
                    Drag CSV file here or{" "}
                    <label className="text-blue-750 hover:underline cursor-pointer">
                      browse
                      <input
                        type="file"
                        className="sr-only"
                        accept=".csv"
                        onChange={handleFileSelect}
                      />
                    </label>
                  </p>
                  <p className="text-xxs text-gray-600">
                    Max file size {maxSize}MB
                  </p>
                </div>
              </div>
            </DialogBody>
          </>
        ) : uploadState.status === "naming" ? (
          <>
            <DialogHeader title="What do you want to name this upload?" />
            <DialogBody className="relative space-y-1">
              <Label htmlFor="filename">Upload name*</Label>
              <Input
                id="filename"
                value={uploadState.customName}
                onChange={handleNameChange}
                className="focus-visible:ring-2 focus-visible:ring-primary"
              />
              {uploadState.customName?.length > 0 && (
                <button
                  type="button"
                  className="absolute w-4 h-4 bottom-3 p-0.5 right-2 cursor-pointer bg-gray-300 text-white rounded-full hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-primary"
                  onClick={handleNameReset}
                  onKeyDown={(e) => e.key === "Enter" && handleNameReset()}
                  aria-label="Clear name"
                >
                  <X size={12} />
                </button>
              )}
            </DialogBody>
            <DialogFooter className="pt-0">
              <Button
                variant="outline"
                onPress={goBack}
                className="flex items-center gap-2"
              >
                Back
              </Button>
              <Button
                onPress={handleUploadFile}
                variant="default"
                isDisabled={uploadState?.customName?.length < 1}
              >
                Next
              </Button>
            </DialogFooter>
          </>
        ) : uploadState.status === "error" ? (
          <>
            <DialogHeader title="Upload CSV File" />
            <DialogBody className="border border-l-[3px] rounded px-2 py-5 border-red-750">
              <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-3">
                  <FileCsv className="h-7 w-7 text-gray-600" />
                  <div>
                    <p className="text-sx font-normal text-gray-900">
                      {uploadState.customName}
                    </p>
                    <p className="text-xxs text-red-700">
                      {uploadState.errorMessage}
                    </p>
                  </div>
                </div>
                <div className="w-7 text-center">
                  <div className="rounded-full w-6 h-6 p-1">
                    <XCircle size={16} />
                  </div>
                </div>
              </div>
            </DialogBody>
            <DialogFooter className="pt-0">
              <Button
                variant="outline"
                onPress={handleUploadFile}
                className="flex items-center gap-2"
              >
                Try again
              </Button>
              <Button
                onPress={() => setUploadState(null)}
                variant="default"
                isDisabled={uploadState?.customName?.length < 1}
              >
                Upload a new file
              </Button>
            </DialogFooter>
          </>
        ) : (
          <>
            <DialogHeader title="Upload CSV File" />
            <DialogBody className="border rounded-lg p-4">
              <div className="flex-grow">
                <div className="flex items-center justify-between gap-4 mb-1">
                  <div className="flex items-center gap-3">
                    <FileCsv className="h-7 w-7 text-gray-600" />
                    <div>
                      <p className="text-sx font-normal text-gray-900">
                        {uploadState.customName}
                      </p>
                      <p className="text-xxs text-gray-600">
                        {Math.round(uploadState.file.size)} bytes
                      </p>
                    </div>
                  </div>
                  <div className="w-7 text-center">
                    {uploadState.status === "completed" && (
                      <DotLottieReact
                        className="w-9 h-9 -mt-1"
                        src="/green-check.lottie"
                        autoplay
                      />
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between gap-4">
                  <Progress
                    value={uploadState.progress}
                    className="w-full"
                    barClassName="h-1"
                    fillClassName={cn(
                      uploadState.status === "uploading" && "bg-blue-600",
                      uploadState.status === "completed" && "bg-green-600",
                    )}
                  />

                  <button
                    type="button"
                    className="text-gray-600 text-xxs"
                    ref={progressRef}
                  >
                    {uploadState.progress}%
                  </button>
                </div>
              </div>
            </DialogBody>
          </>
        )}
      </DialogContent>
    </DialogOverlay>
  );
}

export default UploadCSVModal;
