import { isDevelopment } from "@/config";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { createWithEqualityFn as create } from "zustand/traditional";
import {
  DEFAULT_FILTER_WIDTH,
  type LayoutPreferencesSlice,
  createLayoutPreferencesSlice,
} from "./news/layoutPreferences.slice";

type LayoutPreferencesStore = LayoutPreferencesSlice;

// Type for the migration function
type MigrationFn = (
  persistedState: unknown,
  version: number,
) => LayoutPreferencesStore;

const migration: MigrationFn = (persistedState, version) => {
  const defaultState: LayoutPreferencesStore = {
    filterPanelWidth: DEFAULT_FILTER_WIDTH,
    leftSidebarVisible: false,
    setFilterPanelWidth: () => {},
    setLeftSidebarVisible: () => {},
  };

  if (!persistedState || version === 0) return defaultState;

  return {
    ...defaultState,
    ...(persistedState as Partial<LayoutPreferencesStore>),
  };
};

const useLayoutPreferencesStore = create<LayoutPreferencesStore>()(
  devtools(
    persist(
      (...a) => ({
        ...createLayoutPreferencesSlice(...a),
      }),
      {
        name: "layoutPreferencesStore",
        storage: createJSONStorage(() => localStorage),
        version: 1,
        migrate: migration,
      },
    ),
    {
      name: "layoutPreferencesStore",
      enabled: isDevelopment,
      store: "layoutPreferences",
    },
  ),
);

export default useLayoutPreferencesStore;
