import ConfirmationDialog from "@/components/common/ConfirmationDialog";
import Checkbox from "@/components/ui/forms/checkbox.tsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { z } from "zod";

import { DialogOverlay } from "@/components/ui/dialog.tsx";
import { FieldGroup } from "@/components/ui/field";
import { Input } from "@/components/ui/textfield.tsx";
// @Utils
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName.ts";
import useArticleManagementStore from "@/store/news/articleManagement.slice";
import { useDeleteFeedArticle } from "./useFeedArticleActions.ts";

const RemoveArticleFormSchema = z.object({
  notRelevant: z.boolean().optional(),
  notNews: z.boolean().optional(),
  other: z.boolean().optional(),
  otherReason: z.string().optional(),
});

type FeedArticleRemoveProps = {
  isOpen: boolean;
  onClose: () => void;
  numberOfItemsToDelete: number;
};

const FeedArticleRemove = ({
  isOpen,
  onClose,
  numberOfItemsToDelete,
}: FeedArticleRemoveProps) => {
  const form = useForm({
    resolver: zodResolver(RemoveArticleFormSchema),
    defaultValues: {
      notRelevant: false,
      notNews: false,
      other: false,
      otherReason: "",
    },
  });
  const { watch, reset } = form;
  const isOtherSelected = useWatch({
    control: form.control,
    name: "other",
  });
  const { handleDeleteArticle } = useDeleteFeedArticle();
  const selectedArticleIds = useArticleManagementStore(
    (state) => state.selectedArticleIds,
  );

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleOnDelete = (
    reasonToDelete: z.infer<typeof RemoveArticleFormSchema>,
  ) => {
    const selectedReasons: string[] = [];

    if (reasonToDelete.notRelevant) {
      selectedReasons.push("This is not relevant to the company");
    }

    if (reasonToDelete.notNews) {
      selectedReasons.push("This is not news/blog");
    }

    if (reasonToDelete.other && reasonToDelete.otherReason) {
      selectedReasons.push(reasonToDelete.otherReason);
    }

    handleDeleteArticle(selectedReasons.join(", "));
    handleClose();
  };

  const items = [
    { id: "notRelevant", label: "This is not relevant to the company" },
    { id: "notNews", label: "This is not news/blog" },
    { id: "other", label: "Other" },
  ] as const;

  return (
    <>
      <DialogOverlay isOpen={isOpen}>
        <ConfirmationDialog
          title={`Are you sure you want to delete ${addCommasToNumbers(
            selectedArticleIds.length,
          )} ${pluralize(numberOfItemsToDelete, "article")}?`}
          onCancelClick={handleClose}
          submitText="Yes, delete"
          submittingText="Deleting..."
          submitVariant="destructive"
          onSubmitClick={() => {
            handleOnDelete(form.getValues());
          }}
        >
          <p className="text-sm mb-4">
            We’d love to improve. Could you let us know what went wrong?
          </p>

          <FormProvider {...form}>
            {items.map((item) => (
              <Controller
                control={form.control}
                key={item.id}
                name={item.id}
                render={({ field }) => (
                  <div className="mb-2">
                    <label
                      htmlFor={item.id}
                      className="flex items-center space-x-2 cursor-pointer"
                    >
                      <Checkbox
                        checked={watch(item.id)}
                        id={item.id}
                        onCheckedChange={(checked) => field.onChange(checked)}
                      />
                      <span className="text-sm text-gray-700">
                        {item.label}
                      </span>
                    </label>
                  </div>
                )}
              />
            ))}
            <div className="w-full">
              {isOtherSelected && (
                <Controller
                  control={form.control}
                  name="otherReason"
                  render={({ field }) => (
                    <FieldGroup>
                      <Input
                        {...field}
                        className="w-full"
                        placeholder={"Add a reason"}
                      />
                    </FieldGroup>
                  )}
                />
              )}
            </div>
          </FormProvider>
        </ConfirmationDialog>
      </DialogOverlay>
    </>
  );
};

export default FeedArticleRemove;
