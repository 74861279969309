import { useMemo } from "react";
import { ITEMS_TO_SHOW, MIN_ITEMS_FOR_SEARCH } from "./useFilterState";

/**
 * Hook to determine toi display search and "Show More" buttons
 * @param filteredCount : filtered items count based on search
 * @param totalCount : total number of filter items
 * @param hasActiveSearch
 */
export function useFilterVisibility(
  filteredCount: number,
  totalCount: number,
  hasActiveSearch = false,
) {
  return useMemo(() => {
    // Show "Show More" if we have more than ITEMS_TO_SHOW items
    const needsShowMore = filteredCount > ITEMS_TO_SHOW;

    // Show search if total count is above threshold OR if there's an active search
    const showSearch = totalCount > MIN_ITEMS_FOR_SEARCH || hasActiveSearch;

    return {
      needsShowMore,
      showSearch,
    };
  }, [filteredCount, totalCount, hasActiveSearch]);
}
