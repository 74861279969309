import * as Sentry from "@sentry/react";
import { loadDatadog } from "./datadog";
import { getSystemInfoForLogging, getSystemInfoForSentry } from "./systemInfo";

/**
 * Logs system information to DataDog and Sentry
 *
 * This function collects system information and sends it to:
 * - DataDog as global context (attached to all subsequent RUM events)
 * - Sentry as tags and contexts (attached to all subsequent error reports)
 */
export const logSystemInfo = async (): Promise<void> => {
  try {
    // Log to DataDog
    const datadogSystemInfo = getSystemInfoForLogging();
    const datadogRum = await loadDatadog();
    // Set system information as global context - setGlobalContext expects a single object
    datadogRum.setGlobalContext({ system_info: datadogSystemInfo });
    // Also log a specific event for system information
    datadogRum.addAction("system_info_collected", datadogSystemInfo);
    // Log to Sentry
    const { tags, contexts } = getSystemInfoForSentry();
    // Set tags for filtering/searching
    for (const [key, value] of Object.entries(tags)) {
      Sentry.setTag(key, value.toString());
    }
    // Set contexts for detailed information
    for (const [key, value] of Object.entries(contexts)) {
      Sentry.setContext(key, value);
    }
    console.log("System information logged to DataDog and Sentry");
  } catch (error) {
    console.error("Failed to log system information:", error);
  }
};

/**
 * Sets up a listener to update system information on window resize
 *
 * This ensures that if the user resizes their browser or rotates their device,
 * we capture the updated viewport and screen information.
 */
export const setupSystemInfoListeners = (): (() => void) => {
  let resizeTimeout: number | null = null;

  const handleResize = () => {
    if (resizeTimeout) {
      window.clearTimeout(resizeTimeout);
    }

    // Debounce to avoid excessive logging during resize
    resizeTimeout = window.setTimeout(() => {
      logSystemInfo();
    }, 500);
  };

  window.addEventListener("resize", handleResize);
  window.addEventListener("orientationchange", handleResize);
  // Return cleanup function
  return () => {
    window.removeEventListener("resize", handleResize);
    window.removeEventListener("orientationchange", handleResize);
    if (resizeTimeout) {
      window.clearTimeout(resizeTimeout);
    }
  };
};
