import FilterComponentItem from "@/components/news/feed/FilterComponentItem";
import FilterHeader from "@/components/news/feed/filters/FilterHeader";
import SearchBar from "@/components/news/feed/filters/FilterSearchBar";
import ShowMoreButton from "@/components/news/feed/filters/ShowMoreButton";
import { useFilterState } from "@/components/news/feed/hooks/useFilterState";
import { useFilterVisibility } from "@/components/news/feed/hooks/useFilterVisibility";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import { Menu, MenuItem, MenuPopover } from "@/components/ui/menu";
import { cn } from "@/lib/utils";
import { fetchKeyMessagesAndGroups } from "@/store/key-messages/key-message-async.actions";
import {
  setCreateKeyMessageGroupModalOpen,
  setCreateKeyMessageModalOpen,
  setSelectedGroup,
  setSelectedGroups,
  setSelectedKeyMessageIds,
} from "@/store/key-messages/key-message.actions";
import { useGetKeyMessageFilterItems } from "@/store/key-messages/key-message.selectors";
import useKeyMessageStore, {
  type KeyMessageGroup,
} from "@/store/key-messages/key-message.slice";
import { getFormattedDates } from "@/store/news/filterSearch.actions";
import { updateSelectedFilters } from "@/store/news/filters.actions";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import { FolderPlus, Plus } from "@phosphor-icons/react";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FilterMenu, GroupHeaderMenu } from "./GroupFilterMenu";

const SkeletonLoader = () => {
  return (
    <div className="space-y-2">
      {/* Group skeletons */}
      {[1, 2].map((i) => (
        <div key={i} className="space-y-1">
          {/* Group header skeleton */}
          <Skeleton className="h-5 px-2" />

          {/* Items skeletons */}
          <div className="pl-6 space-y-1 pr-2">
            {[1, 2].map((j) => (
              <Skeleton key={j} className="h-5" />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const GroupedFilterComponent = () => {
  const { feedId } = useParams();
  const isFetchingKeyMessageGroups = useKeyMessageStore(
    (state) => state.isFetchingKeyMessagesAndGroups,
  );

  const endDate = useFeedParamsStore((state) => state.endDate);
  const filterDays = useFeedParamsStore((state) => state.filterDays);
  const startDate = useFeedParamsStore((state) => state.startDate);
  const { startDateFormatted, endDateFormatted } = getFormattedDates(
    startDate,
    endDate,
    filterDays,
  );

  // content height management
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);

  // key messages and groups
  const keyMessages = useKeyMessageStore((state) => state.keyMessages);
  const keyMessageGroups = useKeyMessageStore(
    (state) => state.keyMessageGroups,
  );

  // selected items
  const selectedKeyMessageIds = useKeyMessageStore(
    (state) => state.selectedKeyMessageIds,
  );
  const selectedGroups = useKeyMessageStore((state) => state.selectedGroups);

  // Get filteredFeedIds from useFeedStore
  const filteredFeedIds = useFeedStore((state) => state.filteredFeedIds);

  const toggleGroupSelection = (group: KeyMessageGroup) => {
    const itemIds = group.keyMessageItems.map((item) => item.id);
    let newSelectedItems = new Set<string>();
    setSelectedKeyMessageIds((prevSelectedItems) => {
      newSelectedItems = new Set(prevSelectedItems);
      const isGroupSelected = selectedGroups.has(group.id); // Check if group is selected

      if (isGroupSelected) {
        // Deselect group: Remove all key message items
        for (const id of itemIds) {
          newSelectedItems.delete(id);
        }
        setSelectedGroups(
          new Set(Array.from(selectedGroups).filter((id) => id !== group.id)),
        );
      } else {
        // Select group: Add all key message items
        for (const id of itemIds) {
          newSelectedItems.add(id);
        }
        setSelectedGroups(new Set([...Array.from(selectedGroups), group.id]));
      }

      return newSelectedItems;
    });
    updateSelectedFilters("KEY_MESSAGES", Array.from(newSelectedItems));
  };

  const toggleItemSelection = (itemId: string, group: KeyMessageGroup) => {
    let newSelectedItems = new Set<string>();
    setSelectedKeyMessageIds((prevSelectedItems) => {
      newSelectedItems = new Set(prevSelectedItems);

      if (newSelectedItems.has(itemId)) {
        newSelectedItems.delete(itemId);
      } else {
        newSelectedItems.add(itemId);
      }

      // Check if entire group is selected after toggling
      const groupItemIds = group.keyMessageItems.map((item) => item.id);
      const isGroupFullySelected = groupItemIds.every((id) =>
        newSelectedItems.has(id),
      );

      setSelectedGroups(
        new Set(
          isGroupFullySelected
            ? [...Array.from(selectedGroups), group.id]
            : Array.from(selectedGroups).filter((id) => id !== group.id),
        ),
      );

      return newSelectedItems;
    });
    updateSelectedFilters("KEY_MESSAGES", Array.from(newSelectedItems));
  };

  const {
    isOpen,
    searchTerm,
    showMore,
    handleSearchChange,
    handleClearSearch,
    toggleShowMore,
    setIsTransitioning,
    isTransitioning,
    toggleOpen,
  } = useFilterState(
    [],
    (tags) => setSelectedKeyMessageIds(() => new Set(tags)),
    "KEY_MESSAGES",
  );

  const filteredKeyMessages = useGetKeyMessageFilterItems(searchTerm);

  // visibility
  const { needsShowMore, showSearch: shouldShowSearch } = useFilterVisibility(
    filteredKeyMessages.length,
    keyMessages.length,
    !!searchTerm,
  );

  const visibleItemIds = useMemo(
    () =>
      showMore
        ? filteredKeyMessages.map((item) => item.id)
        : filteredKeyMessages.slice(0, 10).map((item) => item.id),
    [showMore, filteredKeyMessages],
  );

  // Filter the groups based on visible item IDs
  const slicedGroups = useMemo(
    () =>
      keyMessageGroups
        .map((group) => {
          const filteredItems = group.keyMessageItems.filter((item) =>
            visibleItemIds.includes(item.id),
          );

          return { ...group, keyMessageItems: filteredItems };
        })
        .filter(Boolean) as KeyMessageGroup[],
    [keyMessageGroups, visibleItemIds],
  );

  // get counts for key messages
  const getIntersectedCount = useCallback(
    (feedArticleIds: number[]) => {
      const articleSet = new Set(feedArticleIds);
      return Array.from(filteredFeedIds).filter((id: number) =>
        articleSet.has(id),
      ).length;
    },
    [filteredFeedIds],
  );

  useEffect(() => {
    const filters = {
      feedId: Number(feedId),
      dateRange: {
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      },
    };
    fetchKeyMessagesAndGroups(filters);
  }, [feedId, startDate, endDate, filterDays]);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [
    isOpen,
    filteredKeyMessages,
    showMore,
    isFetchingKeyMessageGroups,
    searchTerm,
  ]);

  // Clear selected key messages when feedId changes
  useEffect(() => {
    setSelectedKeyMessageIds(() => new Set());
    setSelectedGroups(new Set());
    updateSelectedFilters("KEY_MESSAGES", []);
  }, [feedId]);

  return (
    <div className={"w-full pl-2 pt-2 pb-1 relative"}>
      <FilterHeader
        title="Key Messages"
        isOpen={isOpen}
        onToggle={() => {
          toggleOpen();
          setIsTransitioning(true);
        }}
        length={keyMessages.length}
        loading={isFetchingKeyMessageGroups}
      >
        <MenuPopover>
          <Menu>
            <MenuItem
              className={"text-xs"}
              onAction={() => setCreateKeyMessageModalOpen(true)}
            >
              <Plus className={"text-gray-600"} />
              New key message
            </MenuItem>
            <MenuItem
              className={"text-xs"}
              onAction={() => setCreateKeyMessageGroupModalOpen(true)}
            >
              <FolderPlus className={"text-gray-600"} />
              New group
            </MenuItem>
          </Menu>
        </MenuPopover>
      </FilterHeader>
      <div
        className={cn(
          "transition-[height,opacity] duration-200 -mx-1.5 ease-in-out overflow-hidden contain-layout will-change-[height,opacity] backface-visibility-hidden",
          isOpen && !isTransitioning ? "opacity-100" : "opacity-0",
        )}
        style={{
          height: isOpen ? `${contentHeight}px` : "0px",
        }}
        onTransitionEnd={() => setIsTransitioning(false)}
      >
        <div ref={contentRef}>
          {isFetchingKeyMessageGroups ? (
            <SkeletonLoader />
          ) : (
            <>
              {shouldShowSearch && (
                <div className="sticky top-0 bg-white z-10 contain-layout">
                  <SearchBar
                    isOpen={isOpen}
                    searchTerm={searchTerm}
                    onSearchChange={handleSearchChange}
                    onClearSearch={handleClearSearch}
                    title={""}
                  />
                </div>
              )}

              <div
                className={cn(
                  showMore ? "overflow-y-auto" : "overflow-hidden",
                  "contain-layout will-change-transform backface-visibility-hidden",
                  "transform-gpu",
                )}
              >
                {isOpen &&
                  slicedGroups.map((group) => {
                    const isGroupSelected = selectedGroups.has(group.id);
                    return (
                      <div key={group.id}>
                        <FilterComponentItem
                          title={group.name}
                          key={group.id}
                          toggleFilter={() => toggleGroupSelection(group)}
                          tabIndex={Number(group.id)}
                          selected={isGroupSelected}
                          className={"py-1"}
                        >
                          <GroupHeaderMenu
                            keyMessageGroups={keyMessageGroups}
                            setCreateKeyMessageModalOpen={
                              setCreateKeyMessageModalOpen
                            }
                            group={group}
                            setSelectedGroup={setSelectedGroup}
                          />
                        </FilterComponentItem>
                        <div className={"pl-6"}>
                          {group.keyMessageItems.length === 0 ? (
                            <Button
                              variant={"ghost"}
                              size={"none"}
                              className={
                                "text-[10px] font-normal flex text-gray-900 pl-1 py-0.5 w-full justify-start"
                              }
                              onPress={() => {
                                setCreateKeyMessageModalOpen(true);
                                setSelectedGroup(group);
                              }}
                            >
                              <Plus
                                size={10}
                                className={"text-gray-600 mr-2"}
                              />
                              New key message
                            </Button>
                          ) : (
                            group.keyMessageItems.map((item) => {
                              const isItemSelected = selectedKeyMessageIds.has(
                                item.id,
                              );
                              const intersectedCount = getIntersectedCount(
                                item.feedArticleIds,
                              );
                              return (
                                <FilterComponentItem
                                  title={item.name}
                                  count={intersectedCount}
                                  toggleFilter={() => {
                                    toggleItemSelection(item.id, group);
                                  }}
                                  key={item.id}
                                  tabIndex={Number(group.id)}
                                  selected={isItemSelected}
                                  className={"py-1"}
                                >
                                  <FilterMenu
                                    item={item}
                                    keyMessageGroups={keyMessageGroups}
                                    feedId={feedId as string}
                                    setCreateKeyMessageModalOpen={
                                      setCreateKeyMessageModalOpen
                                    }
                                    setCreateKeyMessageGroupModalOpen={
                                      setCreateKeyMessageGroupModalOpen
                                    }
                                    setSelectedGroup={setSelectedGroup}
                                  />
                                </FilterComponentItem>
                              );
                            })
                          )}
                        </div>
                      </div>
                    );
                  })}
                {needsShowMore && isOpen && (
                  <ShowMoreButton
                    showMore={showMore}
                    onToggle={toggleShowMore}
                  />
                )}
                {filteredKeyMessages.length === 0 && searchTerm && (
                  <p className="text-sm text-slate-500 ml-6">
                    No key messages found
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default memo(GroupedFilterComponent);
