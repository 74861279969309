import {
  DiscoveredTagQuery,
  type FilterSideBarCategoryCount,
  FilterSidebarQuery,
  NewsFeedsSearchQuery,
} from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import { getPrettyName } from "@/lib/utils/prettyName";
import { getSelectedFeedArticleIds } from "@/store/key-messages/key-message.selectors";
import useKeyMessageStore from "@/store/key-messages/key-message.slice";
import { format as formatTZ, toZonedTime } from "date-fns-tz";
import useFeedStore, { useFeedParamsStore } from "../useFeedStore";
import useArticleDeletionStore from "./articleManagement.slice";
import { updatePagedFeed } from "./feed.actions";
import { FilterCategory } from "./feed.types";
import type { CategoryAppliedFilter } from "./filterSearch.slice";
// import { FilterDisplayNames } from "./constants";

type PartialFeedTag = {
  tagKind: string;
  tag: string;
  feedArticleIds: number[];
};

async function fetchSearchData(
  searchTerm: string,
): Promise<Record<number, string>> {
  const minLengthForSearch = 3;
  const { feedId, filteredFeedIds, selectedStackIds } = useFeedStore.getState();
  const { deletedArticleIds } = useArticleDeletionStore.getState();
  const deleteIdsSet = new Set(deletedArticleIds);

  // Start with filtered IDs
  let searchableIds = Array.from(filteredFeedIds);

  // If we have selected stack IDs, use those to limit the search
  if (selectedStackIds.length > 0) {
    const stackSet = new Set(selectedStackIds);
    searchableIds = searchableIds.filter((id) => stackSet.has(id));
  }

  // Filter out deleted IDs
  searchableIds = searchableIds.filter((id) => !deleteIdsSet.has(id));

  if (
    !feedId ||
    searchableIds.length === 0 ||
    !searchTerm ||
    searchTerm?.length < minLengthForSearch
  )
    return {};

  const response = await client
    .query(NewsFeedsSearchQuery, {
      feedId,
      searchTerm,
      ids: searchableIds,
    })
    .toPromise();

  if (!response.data) {
    console.error("Issue with GraphQL response");
    return {};
  }

  return (
    response.data?.feedSearch.items.reduce(
      (acc, { id, field }) => {
        acc[id] = field[0]?.content[0] ?? "";
        return acc;
      },
      {} as Record<number, string>,
    ) ?? {}
  );
}

const mapToFilterFeedIdSearchResults = (
  foundSearchHits: Record<number, string>,
): [number, searchHit: { mention: string; term: string }][] => {
  if (!foundSearchHits) return [];

  const results: [number, searchHit: { mention: string; term: string }][] = [];

  for (const itemId in foundSearchHits) {
    const searchHitString = foundSearchHits[+itemId];

    if (!searchHitString) continue;

    const emRegex = /<em><b>(.*?)<\/b><\/em>/g;
    const match = searchHitString.match(emRegex);
    const mention = searchHitString.replace(emRegex, "$1");
    const term = match ? match[0].replace(/<.*?>/g, "") : "";

    results.push([+itemId, { mention, term }]);
  }

  return results;
};

export const filterBySearchTerms = async (searchParam?: string) => {
  const searchTerms =
    searchParam ?? useFeedParamsStore.getState().searchTerms ?? "";
  const shouldSearch = searchTerms.length !== 0;

  // Reset feed state before new search
  useFeedStore.setState(
    {
      fetchingSearch: shouldSearch,
      filterFeedIdSearch: [], // Clear existing filtered results
    },
    false,
    "[EFFECT]: reset feed state for search",
  );

  useFeedParamsStore.setState(
    { searchTerms },
    false,
    "[USER]: updated search terms",
  );

  if (!shouldSearch) {
    updatePagedFeed();
    return;
  }

  const searchResults = await fetchSearchData(searchTerms);
  const filterFeedIdSearch = mapToFilterFeedIdSearchResults(searchResults);

  useFeedStore.setState(
    {
      fetchingSearch: false,
      filterFeedIdSearch,
    },
    false,
    "[EFFECT]: Update search results",
  );

  updatePagedFeed();
};

const initializeCategoryStructure = (
  origFilters: CategoryAppliedFilter,
): CategoryAppliedFilter => {
  const categoryAppliedFilters: CategoryAppliedFilter = {};

  for (const [category, filter] of Object.entries(origFilters)) {
    categoryAppliedFilters[category] = {
      itemCounts: {},
      ...(filter.name && { name: filter.name }),
      sorted: filter.sorted ?? true,
    };

    if (filter.itemCounts) {
      for (const [tag, set] of Object.entries(filter.itemCounts)) {
        if (!categoryAppliedFilters[category].itemCounts) {
          categoryAppliedFilters[category].itemCounts = {};
        }
        categoryAppliedFilters[category].itemCounts[tag] = new Set(set);
      }
    }
  }

  return categoryAppliedFilters;
};

function initializeCategory(
  categoryAppliedFilters: CategoryAppliedFilter,
  origFilters: CategoryAppliedFilter,
  category: string,
) {
  const origFilter = origFilters[category];
  if (!categoryAppliedFilters[category]) {
    categoryAppliedFilters[category] = {
      itemCounts: {},
      sorted: origFilter?.sorted ?? true,
      ...(origFilter?.name && { name: origFilter.name }),
    };
  }

  const filter = categoryAppliedFilters[category];
  if (!filter.itemCounts) {
    filter.itemCounts = {};
  }
}

const setCountsForThemes = (tags: PartialFeedTag[]) => {
  const origFilters = useFeedStore.getState().categoryAppliedFilters;
  const categoryAppliedFilters = initializeCategoryStructure(origFilters);

  for (const tag of tags) {
    const tagKind = getPrettyName(tag.tagKind);
    initializeCategory(categoryAppliedFilters, origFilters, tagKind);

    const itemCounts = categoryAppliedFilters[tagKind]?.itemCounts ?? {};
    const currentSet = itemCounts[tag.tag] ?? new Set<number>();
    if (tag.feedArticleIds) {
      for (const id of tag.feedArticleIds) {
        currentSet.add(id);
      }
    }
    itemCounts[tag.tag] = currentSet;
    if (categoryAppliedFilters[tagKind]) {
      categoryAppliedFilters[tagKind].itemCounts = itemCounts;
    }
  }

  return categoryAppliedFilters;
};

const setCounts = (categoryCounts: FilterSideBarCategoryCount[]) => {
  const origFilters = useFeedStore.getState().categoryAppliedFilters;
  const categoryAppliedFilters = initializeCategoryStructure(origFilters);

  for (const { category, items } of categoryCounts) {
    // Only process if we have items to add
    if (items.length > 0) {
      initializeCategory(categoryAppliedFilters, origFilters, category);

      const itemCounts = categoryAppliedFilters[category]?.itemCounts ?? {};
      // Process items

      for (const item of items) {
        if (
          item.label === undefined ||
          item.label === null ||
          item.label === ""
        )
          continue;

        if (category === "Story Clusters") {
          itemCounts[`${item.key} ${item.label}`] = new Set<number>(
            item.ids || [],
          );
        } else if (category === "PUBLISHER") {
          itemCounts[`${item.key} ${item.label}`] = new Set<number>(
            item.ids || [],
          );
        } else {
          itemCounts[item.label] = new Set<number>(item.ids || []);
        }
      }
      if (categoryAppliedFilters[category]) {
        categoryAppliedFilters[category].itemCounts = itemCounts;
      }
    }
  }

  return categoryAppliedFilters;
};

export const updateFilteredIds = () => {
  const {
    allFeedIds,
    categoryAppliedFilters,
    filteredFeedIds: oldFilteredIds,
  } = useFeedStore.getState();

  const { selected } = useFeedParamsStore.getState();
  const { selectedKeyMessageIds, keyMessages } = useKeyMessageStore.getState();
  const keyMessageArticleIds = getSelectedFeedArticleIds(
    keyMessages,
    selectedKeyMessageIds,
  );
  const hasKeyMessageFilter = selectedKeyMessageIds.size !== 0;

  // If key messages are selected, use them as the base filter
  let newFilteredIds: Set<number> = hasKeyMessageFilter
    ? keyMessageArticleIds
    : new Set<number>(allFeedIds);

  // Don't show empty state until we have category filters
  const categoryFilter = categoryAppliedFilters[FilterCategory.CATEGORY];
  if (
    !categoryFilter?.itemCounts ||
    Object.keys(categoryFilter.itemCounts).length === 0
  ) {
    return allFeedIds;
  }

  // Check if we have any selected filters
  const hasSelectedFilters = Object.entries(selected).some(
    ([key, items]) => key !== "KEY_MESSAGES" && items && items.length > 0,
  );

  // Helper function to update feed state when filters change
  const updateFeedState = (newIds: Set<number>, reason: string) => {
    const hasFilteredIdsChanged =
      oldFilteredIds.size !== newIds.size ||
      oldFilteredIds.intersection(newIds).size === oldFilteredIds.size;

    useFeedStore.setState(
      {
        filteredFeedIds: newIds,
        ...(hasFilteredIdsChanged && {
          selectedStackIds: [],
          feedVolume: null,
          feedVolumeSelectedDate: null,
          activeBar: undefined,
        }),
      },
      false,
      reason,
    );
    filterBySearchTerms();
  };

  // If no filters are selected, use all feed IDs
  if (!hasSelectedFilters && !hasKeyMessageFilter) {
    updateFeedState(allFeedIds, "[Effect]: Reset to all feed ids");
    return allFeedIds;
  }

  for (const selectedKey in selected) {
    // Skip key message filter since it's already applied
    if (selectedKey === "KEY_MESSAGES") continue;
    const selectedItems = selected[selectedKey];
    const itemCountsMap = categoryAppliedFilters[selectedKey]?.itemCounts;
    if (!itemCountsMap || !selectedItems || selectedItems.length === 0)
      continue;

    // Build union of all selected item IDs in this group
    const currentFilterGroupSet = selectedItems
      .map((item) => itemCountsMap[item])
      .filter((itemIds): itemIds is Set<number> => Boolean(itemIds?.size))
      .reduce((acc, itemIds) => acc.union(itemIds), new Set<number>());

    // If no items had IDs, return empty set
    if (currentFilterGroupSet.size === 0) {
      newFilteredIds = new Set<number>();
      break;
    }

    // If this is our first filter group with results, use its IDs
    // Otherwise intersect with previous results
    if (newFilteredIds === null) {
      newFilteredIds = currentFilterGroupSet;
    } else {
      newFilteredIds = newFilteredIds.intersection(currentFilterGroupSet);
    }

    if (newFilteredIds.size === 0) break;
  }

  // If we have selected filters but never set newFilteredIds (all filters were skipped),
  // return empty set instead of falling back to all IDs
  if (newFilteredIds === null) {
    newFilteredIds = new Set<number>();
  }

  updateFeedState(newFilteredIds, "[Effect]: Update filtered feed ids");
};

export const fetchFilters = async (feedId: number) => {
  const { startDateFormatted: startDate, endDateFormatted: endDate } =
    getDateParameters();

  const filters = client.query(DiscoveredTagQuery, {
    feedId,
    startDate,
    endDate,
  });

  const $filters = filters.subscribe((data) => {
    // Just update the discovered tags without affecting loading state
    const categoryAppliedFilters = setCountsForThemes(
      data.data?.feedArticleTagsByFeed ?? [],
    );
    useFeedStore.setState(
      {
        categoryAppliedFilters,
      },
      false,
      "[Effect] Tags: Updated discovered items",
    );
  });

  useFeedStore.setState(
    {
      unsubscribeFetchFilters: $filters.unsubscribe,
    },
    false,
    "Update unsubscribe for precompute filters",
  );

  const filterSidebarCounts = client.query(FilterSidebarQuery, {
    feedId: feedId,
    startDate: startDate,
    endDate: endDate,
  });

  const $filterSidebarCounts = filterSidebarCounts.subscribe((data) => {
    if (data.error) {
      console.error("Error fetching filter sidebar counts:", data.error);
      useFeedStore.setState(
        {
          allFeedIds: new Set<number>(),
        },
        false,
        "[API] Filters: Failed to fetch",
      );
      return;
    }

    const precomputeCounts = data?.data?.precomputeCounts;
    if (!precomputeCounts) {
      console.error("No precompute counts received");
      return;
    }

    // Update category filters with sidebar counts
    const categoryAppliedFilters = setCounts(precomputeCounts.counts);
    const categoryFilterGroup = categoryAppliedFilters[FilterCategory.CATEGORY];
    const allFeedIds = Object.values(categoryFilterGroup?.itemCounts ?? {})
      .filter(Boolean)
      .reduce((acc, items) => acc.union(items), new Set<number>());

    // Set allFeedIds and update filters
    useFeedStore.setState(
      {
        allFeedIds,
        categoryAppliedFilters,
        totalCount: data.data?.precomputeCounts.total,
      },
      false,
      "[Effect] Filters: Updated sidebar counts",
    );

    // Now that we have allFeedIds, update filtered IDs and complete loading
    updateFilteredIds();
    useFeedStore.setState(
      { fetchingFilters: false },
      false,
      "[Effect] Filters: Finished loading",
    );
  });

  useFeedStore.setState(
    {
      unsubscribeFetchDynamicFilters: $filterSidebarCounts.unsubscribe,
    },
    false,
    "Update unsubscribe for dynamic filters",
  );
};

export const isFeedFiltered = (): boolean => {
  const { searchTerms, selected } = useFeedParamsStore.getState();
  return Boolean(
    searchTerms?.length ||
      Object.values(selected).some((items) => items?.length > 0),
  );
};

export const getFormattedDates = (
  startDate?: Date,
  endDate?: Date,
  filterDays?: number,
) => {
  const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;
  const timeZone = "UTC";

  if (!startDate && !filterDays) {
    const endDate = toZonedTime(new Date(), timeZone);
    const startDate = toZonedTime(new Date(), timeZone);
    startDate.setDate(startDate.getDate() - 30);

    return {
      startDateFormatted: startDate.toISOString(),
      endDateFormatted: endDate.toISOString(),
    };
  }

  const startDateFormatted = startDate
    ? formatTZ(toZonedTime(startDate, timeZone), "yyyy-MM-dd", { timeZone })
    : formatTZ(
        toZonedTime(
          new Date(Date.now() - (filterDays ?? 30) * MILLISECONDS_IN_A_DAY),
          timeZone,
        ),
        "yyyy-MM-dd",
        { timeZone },
      );

  const endDateFormatted = endDate
    ? formatTZ(toZonedTime(endDate, timeZone), "yyyy-MM-dd", { timeZone })
    : formatTZ(toZonedTime(new Date(), timeZone), "yyyy-MM-dd", { timeZone });

  return {
    startDateFormatted,
    endDateFormatted,
  };
};

export function getDateParameters() {
  const { filterDays, startDate, endDate, isCustomDaysRange } =
    useFeedParamsStore.getState();
  const { feedVolumeSelectedDate } = useFeedStore.getState();

  const dates = {
    start: feedVolumeSelectedDate
      ? new Date(feedVolumeSelectedDate.startDate)
      : startDate,
    end: feedVolumeSelectedDate
      ? new Date(feedVolumeSelectedDate.endDate)
      : endDate,
  };

  return {
    ...getFormattedDates(dates.start, dates.end, filterDays),
    dayFilter:
      isCustomDaysRange && startDate && endDate ? undefined : filterDays,
  };
}
