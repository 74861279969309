import { Button } from "@/components/ui/button";
import {
  useStoryInsightsSubscription,
  STORY_CLUSTER_INSIGHT_MODAL as MODAL,
} from "@/data-access/news/use-story-insights-subscription";
import { Copy } from "@phosphor-icons/react";
import {
  Fragment,
  type ReactElement,
  useCallback,
  useEffect,
  useState
} from "react";
import {
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogOverlay,
} from "../ui/dialog";
import { format } from "date-fns";
import { Separator } from "react-aria-components";

interface StoryClusterInsightModalProps {
  isOpen: boolean;
  onClose: () => void;
  storyId: string;
  name: string;
}

export function StoryClusterInsightModal({
  isOpen,
  onClose,
  storyId,
  name,
}: StoryClusterInsightModalProps): ReactElement {
  const [isTermsExpanded, setIsTermsExpanded] = useState(false);

  useEffect(() => {
    setIsTermsExpanded(false);
  }, [isOpen]);
  
  const { insightData, metrics, isContentLoaded } = useStoryInsightsSubscription({
    storyId,
    enabled: isOpen
  });

  // Copy HTML content to clipboard with formatting preserved
  const copyHtmlToClipboard = useCallback(async (htmlContent: string) => {
    try {
      // Create temporary elements for HTML and plain text
      const container = document.createElement('div');
      container.innerHTML = htmlContent;
      
      // Extract plain text version
      const plainText = container.textContent || container.innerText;
      
      // Try using modern Clipboard API with both formats
      if (navigator.clipboard && navigator.clipboard.write) {
        const htmlBlob = new Blob([htmlContent], { type: 'text/html' });
        const textBlob = new Blob([plainText], { type: 'text/plain' });
        
        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': htmlBlob,
            'text/plain': textBlob
          })
        ]);
        return true;
      }
      
      // Fallback: Try setting plain text if HTML fails
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(plainText);
        return true;
      }

      // Legacy fallback
      const range = document.createRange();
      range.selectNode(container);
      const selection = window.getSelection();
      
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        
        // Deprecated command for older browsers
        const successful = document.execCommand('copy');
        selection.removeAllRanges();
        
        if (!successful) {
          console.error('execCommand copy failed');
          return false;
        }
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Failed to copy content:', error);
      return false;
    }
  }, []);
  
  const getDateRangeString = useCallback(() => {
    return `${format(insightData.startDate || new Date(), 'MMMM d, yyyy')} - ${format(insightData.endDate || new Date(), 'MMMM d, yyyy')}`;
  }, [insightData.startDate, insightData.endDate]);

  const getStatsItems = useCallback(() => {
    return Object.entries(metrics)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}: ${value}`)
      .join(' | ');
  }, [metrics]);


  const generateHtml = (includeTerms = false) => {
    if (!insightData.whatToKnow && !insightData.whyItMatters) return "";
  
    // Helper function to create a section with proper formatting
    const createSection = (header: string, content: string, includeBrBefore = true) => `
      ${includeBrBefore ? '<br>' : ''}
      <p><strong>${header}</strong></p>
      <p style="font-weight: normal;">${content}</p>`;
  
    const mainContent = `
      <div style="font-family: Arial, sans-serif;">
        <p><strong>${name}</strong></p>
        <p style="font-weight: normal;">${getDateRangeString()}</p>
        ${createSection('Story stats:', getStatsItems())}
        ${createSection('What to know:', insightData.whatToKnow || MODAL.STATUS.NO_INFO)}
        ${createSection('Why it matters:', insightData.whyItMatters || MODAL.STATUS.NO_INFO)}
        ${includeTerms ? `
          <br><p><strong>${MODAL.SECTIONS.TERMS_GUIDE}</strong></p>
          ${Object.entries(MODAL.TERMS).map(([_, term]) => `
            <p>
              <strong>${term.TITLE}</strong>
              <span style="font-weight: normal;"> ${term.DESCRIPTION}</span>
            </p>
          `).join('')}
        ` : ''}
      </div>`;
  
    return mainContent;
  };

  const handleCopySummary = useCallback(async () => {
    const html = generateHtml(false);
    if (!html) return;

    const success = await copyHtmlToClipboard(html);
    if (success) {
      console.log("Summary copied to clipboard with formatting");
    } else {
      console.error("Failed to copy summary with formatting");
    }
  }, [generateHtml, copyHtmlToClipboard]);

  const handleCopyAll = useCallback(async () => {
    const html = generateHtml(true);
    if (!html) return;

    const success = await copyHtmlToClipboard(html);
    if (success) {
      console.log("All content copied to clipboard with formatting");
    } else {
      console.error("Failed to copy all content with formatting");
    }
  }, [copyHtmlToClipboard, generateHtml]);

  return (
    <DialogOverlay isOpen={isOpen}>
      <DialogContent onClose={onClose} size="lg">
        <DialogHeader
          title={MODAL.TITLE}
          className="text-violet-800 text-base"
        />
        <DialogBody>
          <div className="py-1.5 px-4 overflow-y-auto text-gray-900">
            <div
              className="space-y-6 text-xs transition-all duration-300"
              style={{
                opacity: isContentLoaded ? 1 : 0.8,
                height: "auto",
                overflow: "hidden",
              }}
            >
              <div className="transition-opacity duration-300">
                <h3 className="font-bold text-sm">{name}</h3>
                {insightData.startDate && insightData.endDate && (
                  <p className="text-xs mt-1">
                    {getDateRangeString()}
                  </p>
                )}
              </div>

              <div className="transition-opacity duration-300">
                <h4 className="font-semibold">
                  {MODAL.SECTIONS.STORY_STATS}
                </h4>
                <div className="flex flex-wrap">
                  {Object.entries(metrics).filter(([_, value]) => value).length > 0 ? (
                    Object.entries(metrics)
                      .filter(([_, value]) => value)
                      .map(([key, value], index, array) => {
                        const isLast = index === array.length - 1;
                        return <Fragment key={key}>
                          <div className="whitespace-nowrap">
                            <span>
                              {key}:
                            </span>{" "}
                            <span>
                              {String(value)}
                            </span>{!isLast && <span className="mx-2 text-gray-400">|</span>}
                          </div>
                        </Fragment>
                      })
                  ) : (
                    <span className="text-gray-700">{MODAL.STATUS.LOADING}</span>
                  )}
                </div>
              </div>

              <div className="transition-opacity duration-300">
                <h4 className="font-semibold">
                  {MODAL.SECTIONS.WHAT_TO_KNOW}
                </h4>
                <p className="leading-4">
                  {insightData.whatToKnow || (
                    <span className="text-gray-700">{MODAL.STATUS.LOADING}</span>
                  )}
                </p>
              </div>

              <div className="transition-opacity duration-300">
                <h4 className="font-semibold">
                  {MODAL.SECTIONS.WHY_IT_MATTERS}
                </h4>
                <p className="leading-4">
                  {insightData.whyItMatters || (
                    <span className="text-gray-700">{MODAL.STATUS.LOADING}</span>
                  )}
                </p>
              </div>

              {isContentLoaded && (
                <>
                  <Button
                    onPress={handleCopySummary}
                    size="sm"
                    className="gap-1.5 mt-2 transition-opacity duration-300"
                  >
                    <Copy />
                    {MODAL.BUTTONS.COPY_SUMMARY}
                  </Button>

                  <Separator />
                </>
              )}

              <div className="mt-8">
                {isTermsExpanded && (
                  <>
                    <span className="text-gray-500 text-xs font-bold">
                      {MODAL.SECTIONS.TERMS_GUIDE}
                    </span>
                    <div className="space-y-2 text-xxs text-gray-900 mt-5">
                      {Object.entries(MODAL.TERMS).map(([key, term]) => (
                        <p key={key}>
                          <span className="font-bold text-gray-500">
                            {term.TITLE}
                          </span>{" "}
                          {term.DESCRIPTION}
                        </p>
                      ))}
                    </div>
                    <Button
                      onPress={handleCopyAll}
                      size="sm"
                      className="gap-1.5 my-6"
                    >
                      <Copy />
                      {MODAL.BUTTONS.COPY_SUMMARY_TERMS}
                    </Button>
                  </>
                )}

                {isContentLoaded && (
                  <Button
                    variant="link"
                    size="link"
                    onPress={() => setIsTermsExpanded(!isTermsExpanded)}
                    className="transition-opacity duration-300"
                  >
                    {isTermsExpanded
                      ? MODAL.BUTTONS.HIDE_TERMS
                      : MODAL.BUTTONS.SHOW_TERMS}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </DialogBody>
      </DialogContent>
    </DialogOverlay>
  );
}
