import { toast } from "@/components/ui/toast";
import { handleSessionExpired } from "@/hooks/useTokenManager";
import useAuth from "@/store/auth.slice";
import * as Sentry from "@sentry/react";
import { apiEndpointBaseUrl } from "./constants";
import { HTTP_STATUS } from "./httpConstants";
import { getAuthToken } from "./tokenManagement";
import type { FetchOptions } from "./types";

const getErrorMessage = (status: number, statusText: string) => {
  switch (status) {
    case HTTP_STATUS.BAD_REQUEST:
      return "Invalid request. Please check your input and try again.";
    case HTTP_STATUS.UNAUTHORIZED:
      return "You are not authorized to perform this action.";
    case HTTP_STATUS.FORBIDDEN:
      return "You don't have permission to access this resource.";
    case HTTP_STATUS.NOT_FOUND:
      return "The requested resource was not found.";
    case HTTP_STATUS.INTERNAL_SERVER_ERROR:
      return "An internal server error occurred. Please try again later.";
    default:
      return `HTTP Error: ${status} ${statusText}`;
  }
};

export const createHeaders = async (
  method: string,
  token?: string,
  tenant?: string,
  appId?: string,
): Promise<Headers> => {
  const headers = new Headers();

  if (method !== "GET") {
    headers.append("Content-Type", "application/json");
  }

  const authToken = await getAuthToken(token || "");

  if (authToken) headers.append("Authorization", `Bearer ${authToken}`);
  if (tenant) headers.append("X-NA-TENANT", tenant);
  if (appId) headers.append("X-NAAPI-APPLICATION", appId);

  return headers;
};

export const standardAuthFetch = async (
  input: RequestInfo | URL,
  init?: RequestInit,
) => {
  const token = useAuth.getState().accessToken || "";
  const method =
    init?.method || (input instanceof Request ? input.method : "GET") || "GET";
  const headers = await createHeaders(method, token);

  let request: Request;
  if (input instanceof Request) {
    const combinedHeaders = new Headers(input.headers);
    headers.forEach((value, key) => {
      combinedHeaders.set(key, value);
    });
    request = new Request(input, {
      ...init,
      headers: combinedHeaders,
    });
  } else {
    request = new Request(input, {
      ...init,
      headers,
    });
  }

  const response = await fetch(request);

  if (response.status === HTTP_STATUS.UNAUTHORIZED) {
    // Check if we're in the process of logging in
    const authState = useAuth.getState();
    const isInitialLogin = authState.status === "loading";

    if (!isInitialLogin) {
      // Only handle session expired if we're not in the login process
      handleSessionExpired();
      throw new Error("Session expired");
    }
  }

  return response;
};

const authenticatedFetch = async (
  endpoint: string,
  {
    data = {},
    method = "GET",
    token = useAuth.getState().accessToken || "",
    tenant,
    appId,
  }: FetchOptions = {},
) => {
  try {
    const url = `${apiEndpointBaseUrl}/${endpoint}`;
    const headers = await createHeaders(method, token, tenant, appId);
    const response = await fetch(url, {
      method,
      headers,
      body: method !== "GET" ? JSON.stringify(data) : undefined,
    });

    if (!response.ok) {
      let errorMessage: string;

      try {
        const errorData = await response.json();

        if (response.status === HTTP_STATUS.UNAUTHORIZED) {
          // Check if we're in the process of logging in
          const authState = useAuth.getState();
          const isInitialLogin = authState.status === "loading";

          if (!isInitialLogin) {
            // Only handle session expired if we're not in the login process
            handleSessionExpired();
            throw new Error(errorData?.detail || "Session expired");
          }
        }

        // Use specific error messages from API when available
        if (errorData?.detail) {
          errorMessage = errorData.detail;
        } else if (
          errorData.non_field_errors &&
          errorData.non_field_errors.length > 0
        ) {
          errorMessage = errorData.non_field_errors[0];
        } else {
          errorMessage = getErrorMessage(response.status, response.statusText);
        }
      } catch (jsonError) {
        if (response.status === HTTP_STATUS.UNAUTHORIZED) {
          // Check if we're in the process of logging in
          const authState = useAuth.getState();
          const isInitialLogin = authState.status === "loading";

          if (!isInitialLogin) {
            // Only handle session expired if we're not in the login process
            handleSessionExpired();
            throw new Error("Session expired");
          }
        }

        errorMessage = getErrorMessage(response.status, response.statusText);
      }

      toast.error(errorMessage);
      throw new Error(errorMessage);
    }

    return response;
  } catch (error) {
    console.error("Fetch error:", error);

    if (error instanceof TypeError) {
      toast.error("A network error occurred. Please check your connection.");
    }

    Sentry.captureException(error);
    throw error;
  }
};

export default authenticatedFetch;
