import { FeedContentLayout } from "@/components/layouts/FeedContentLayout";
import FeedContent from "@/components/news/feed/FeedContent";
import FeedFilters from "@/components/news/feed/FeedFilters";
import FeedHeader from "@/components/news/feed/FeedHeader";
import FeedSheets from "@/components/news/shared/FeedSheets";
import { ResizeHandle } from "@/components/ui/resize-handle";
import { useResizeHandle } from "@/hooks/useResizeHandle";
import { useAppNavigation } from "@/lib/navigation";
import { cn } from "@/lib/utils";
import { MIN_FILTER_WIDTH } from "@/store/news/layoutPreferences.slice";
import { useFilteredFeedItems } from "@/store/news/news.selectors";
import { useFeedParamsStore } from "@/store/useFeedStore";
import useLayoutPreferencesStore from "@/store/useLayoutPreferencesStore";
import { useLayoutEffect, useRef } from "react";

const COLLAPSED_SIDEBAR_WIDTH = 56; // w-14 = 3.5rem = 56px
const EXPANDED_SIDEBAR_WIDTH = 216; // Width of sidebar when expanded
const MAIN_CONTENT_MIN_WIDTH = 1024; // Minimum width for feed content

const useFilterWidth = (isVisible: boolean) => {
  const { filterPanelWidth, setFilterPanelWidth } = useLayoutPreferencesStore();

  const updateWidth = () => {
    const sidebarWidth = isVisible
      ? EXPANDED_SIDEBAR_WIDTH
      : COLLAPSED_SIDEBAR_WIDTH;
    const maxWidth = Math.min(
      window.innerWidth - sidebarWidth - MAIN_CONTENT_MIN_WIDTH,
      window.innerWidth * 0.4,
    );

    const newWidth = Math.max(
      Math.min(filterPanelWidth, maxWidth),
      MIN_FILTER_WIDTH,
    );
    if (newWidth !== filterPanelWidth) {
      setFilterPanelWidth(newWidth);
    }
  };

  useLayoutEffect(() => {
    updateWidth();

    let rafId: number;
    const handleResize = () => {
      rafId = requestAnimationFrame(updateWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(rafId);
    };
  }, [isVisible, filterPanelWidth, setFilterPanelWidth]);

  return {
    width: filterPanelWidth,
    setWidth: setFilterPanelWidth,
    maxWidth: window.innerWidth * 0.4,
  };
};

const FeedPage: React.FC = () => {
  const { getCurrentFeedId } = useAppNavigation();
  const feedId = getCurrentFeedId();
  const searchTerms = useFeedParamsStore((state) => state.searchTerms);
  const filteredFeedItems = useFilteredFeedItems();
  const isSidebarVisible = useLayoutPreferencesStore(
    (state) => state.leftSidebarVisible,
  );

  const { width, setWidth, maxWidth } = useFilterWidth(isSidebarVisible);
  const hasInitialRender = useRef(false);
  // const clearStoryCluster = useFeedParamsStore((state) => state.clearStoryCluster);

  const { handleMouseDown, isResizing } = useResizeHandle({
    minWidth: MIN_FILTER_WIDTH,
    maxWidth,
    initialWidth: width,
    onWidthChange: setWidth,
  });

  // // Clear story cluster when feedId changes
  // useEffect(() => {
  //   clearStoryCluster();
  // }, [feedId, clearStoryCluster]);

  // Skip initial animation after first render
  useLayoutEffect(() => {
    hasInitialRender.current = true;
  }, []);

  return (
    <>
      <FeedContentLayout>
        <FeedHeader />
        <main
          className={cn(
            "grid",
            // Only apply transition on initial render
            !hasInitialRender.current &&
              "transition-[grid-template-columns] duration-300 ease-in-out",
            // Mobile: Single column layout
            "grid-cols-[1fr]",
            // SM and up: Two column layout with dynamic width
            "sm:grid-cols-[auto_1fr]",
          )}
        >
          {feedId && (
            <>
              <div
                className={cn(
                  "hidden sm:block relative", // Simply hide on mobile, show on SM+
                  "w-full",
                  isResizing && "select-none",
                )}
                style={{ width }}
              >
                <FeedFilters />
                <ResizeHandle onMouseDown={handleMouseDown} />
              </div>
              <FeedContent
                feedId={feedId}
                searchTerms={searchTerms}
                feedItems={filteredFeedItems}
              />
            </>
          )}
        </main>
      </FeedContentLayout>
      <FeedSheets />
    </>
  );
};

export default FeedPage;
