import type { BarChartEventProps } from "@/components/ui/bar-chart/BarChart.types";
import toast from "@/components/ui/toast";
import type {
  CreateFeedMutationDataType,
  FeedMutationDataType,
  SideBarQueryFeedType,
} from "@/data-access/news";
import { CREATE_FEED_MUTATION, FEED_MUTATION } from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import { differenceInDays } from "date-fns";
import type { StateCreator } from "zustand";

export interface FilterData {
  selectedOptions: string[];
  title: string;
  categoryKey: string;
}

export type DerivedFilterItem = {
  id: string;
  value: string;
  totalCount: number;
  count: number;
  selected: boolean;
};

export type FeedManagementSlice = {
  creatingOrEditingFeed: boolean;
  createOrEditFeedState: "initial" | "complete";
  feedId: number | null;
  feedSidebarData: SideBarQueryFeedType;
  totalCount?: number;
  isDeleteFeedModalOpen: boolean;
  createFeed: (data: CreateFeedMutationDataType) => Promise<void>;
  editFeed: (data: FeedMutationDataType) => Promise<void>;
  updateFeedSideBarData: (data: SideBarQueryFeedType) => void;
};

export type FeedVolumeSlice = {
  feedVolumeSelectedDate: { startDate: string; endDate: string } | null;
  feedVolume: number | null;
  setFeedVolume: (volume: number | null) => void;
  stackFeedVolumeBy: null | string;
  setStackFeedVolumeBy: (stackBy: null | string) => void;
  selectedStackIds: number[];
  activeBar:
    | Omit<BarChartEventProps, "eventType" | "categoryClicked">
    | undefined;
  setActiveBar: (
    bar: Omit<BarChartEventProps, "eventType" | "categoryClicked"> | undefined,
  ) => void;
};

export type TierConfigSlice = {
  tier2Max: number;
  tier3Max: number;
  refreshingScore: boolean;
  tierConfig: Record<string, number>;
  setTierConfig: (config: Record<string, number>) => void;
};

// Combined type that represents the full state
export type FeedSlice = FeedManagementSlice & FeedVolumeSlice & TierConfigSlice;

/**
 * Return the period range type based on the start and end date
 *
 * @returns {string} - period range day | week | month
 * @param startDate
 * @param endDate
 * @param days
 */
export const getDateRangeType = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  days: number | undefined,
): "day" | "week" | "month" | null => {
  let daysDifference = days;

  if (startDate && endDate) {
    daysDifference = differenceInDays(endDate, startDate);
  }

  if (daysDifference !== undefined) {
    if (daysDifference <= 31) {
      return "day";
    }

    if (daysDifference < 90) {
      return "week";
    }

    if (daysDifference >= 90) {
      return "month";
    }
  }

  return null;
};

export const createFeedSlice: StateCreator<
  FeedSlice,
  [["zustand/devtools", never]],
  []
> = (set, get) => ({
  createOrEditFeedState: "initial",
  creatingOrEditingFeed: false,
  feedSidebarData: [],
  refreshingScore: false,
  isCustomDaysRange: false,
  isDeleteFeedModalOpen: false,
  feedName: "",
  feedId: null,
  feedTerms: [],
  knownTags: [],
  tier2Max: 80,
  tier3Max: 60,
  period: null,
  feedVolumeSelectedDate: null,
  feedVolume: null,
  stackFeedVolumeBy: null,
  selectedStackIds: [],
  activeBar: undefined,

  createFeed: async (data) => {
    set({
      creatingOrEditingFeed: true,
      createOrEditFeedState: "initial",
    });

    client
      .mutation(CREATE_FEED_MUTATION, {
        data,
      })
      .subscribe((res) => {
        if (res.error || res.data?.createFeed.__typename === "OperationInfo") {
          //@ts-ignore
          const messages = res.data?.createFeed?.messages || [
            { message: "An error occurred while creating the feed." },
          ];

          for (const { message } of messages) {
            toast.error(message);
          }

          set(
            { creatingOrEditingFeed: false },
            false,
            "created or edit feed error",
          );
          return;
        }

        if (res.data?.createFeed.__typename !== "FeedType") return;

        set({
          createOrEditFeedState: "complete",
          creatingOrEditingFeed: false,
          feedId: +res.data.createFeed.id,
        });
      });
  },

  updateFeedSideBarData: (data) => {
    set(
      {
        feedSidebarData: data,
      },
      false,
      "update feed sidebar w/ data",
    );
  },

  editFeed: async (data) => {
    const { feedId } = get();

    if (!feedId) return;

    set({
      creatingOrEditingFeed: true,
      createOrEditFeedState: "initial",
    });

    client
      .mutation(FEED_MUTATION, {
        data,
        feedId,
      })
      .subscribe((res) => {
        if (res.data?.updateFeed.__typename === "FeedType" || res.error) {
          set({
            creatingOrEditingFeed: false,
            createOrEditFeedState: res.error ? "initial" : "complete",
          });
        }
      });
  },

  tierConfig: {},
  setTierConfig: (config) =>
    set({ tierConfig: config }, false, "updated tier config"),

  setFeedVolume: (volume) =>
    set({ feedVolume: volume }, false, "updated volume"),
  setStackFeedVolumeBy: (stackBy) =>
    set({ stackFeedVolumeBy: stackBy }, false, "updated stack feed volume by"),
  setActiveBar: (bar) => set({ activeBar: bar }, false, "updated active bar"),
});
