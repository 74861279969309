import { cn } from "@/lib/utils";
import useLayoutPreferencesStore from "@/store/useLayoutPreferencesStore";
import React from "react";

interface HeaderLayoutProps {
  children: React.ReactNode;
}
const HeaderLayout: React.FC<HeaderLayoutProps> = ({ children }) => {
  const childrenArray = React.Children.toArray(children);

  const isSidebarVisible = useLayoutPreferencesStore(
    (state) => state.leftSidebarVisible,
  );

  if (childrenArray.length !== 2) {
    throw new Error("HeaderLayout expects exactly two children");
  }
  const [leftContent, rightContent] = childrenArray;
  return (
    <div className="flex flex-wrap justify-between items-center px-7 py-5 border-b border-slate-100">
      <div
        className={cn(
          "flex items-center flex-grow",
          !isSidebarVisible && "pl-3",
        )}
      >
        {leftContent}
      </div>
      <div className="flex items-center flex-grow justify-end">
        {rightContent}
      </div>
    </div>
  );
};

export default HeaderLayout;
