import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/data-display/badge";
import { Label } from "@/components/ui/field";
import { Checkbox } from "@/components/ui/forms/checkbox";
import { EditButton } from "@/components/ui/molecules/EditButton";
import { Popover, PopoverDialog } from "@/components/ui/popover";
import { Radio, RadioGroup } from "@/components/ui/radio-group";
import type {
  FeedWithAugTypes,
  UpdateArticleMutationInputType,
} from "@/data-access/news";
import type { CategoryQueryResponse } from "@/data-access/news/categoryQuery";
import { cn } from "@/lib/utils";
import {
  getPrettyName,
  toLowerCase,
  toSnakeCase,
} from "@/lib/utils/prettyName";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

type Props = Partial<FeedWithAugTypes> & {
  categories?: CategoryQueryResponse["categories"];
  className?: string;
  onSave?: (
    value: UpdateArticleMutationInputType,
  ) => Promise<boolean> | undefined;
};

const VALID_PROMINENCES = ["headline", "feature", "passing_mention"] as const;
type ValidProminenceType = (typeof VALID_PROMINENCES)[number];

const isValidProminence = (
  value: string,
): value is Uppercase<ValidProminenceType> => {
  return ["HEADLINE", "FEATURE", "PASSING_MENTION"].includes(value);
};

const desiredCategoryOrder = [
  "news",
  "press_release",
  "stocks",
  "owned_media",
  "market_research",
  "coupons",
  "crime",
  "sports",
  "recipes",
];

export const ArticleBadgeRow: React.FC<Props> = ({
  categories,
  prominence,
  articleNewsCategory,
  overriddenArticleNewsCategory,
  className,
  tierLevel,
  onSave,
}) => {
  type EditState = {
    isEditing: boolean;
    isHovered: boolean;
    selectedCategory?: string;
    selectedProminence?: string;
    selectedIsLede: boolean;
    savedSelectedCategory: string;
    savedIsLede: boolean;
    savedProminence?: string;
  };

  const triggerRef = useRef(null);
  const lastBadgeRef = useRef<HTMLDivElement>(null);
  const [editButtonPosition, setEditButtonPosition] = useState<number>(0);

  const currentProminence = useMemo(
    () =>
      prominence?.find(
        (p) =>
          p.toLowerCase() !== "lede" &&
          VALID_PROMINENCES.includes(p.toLowerCase() as ValidProminenceType),
      ),
    [prominence],
  );

  const [state, setState] = useState<EditState>(() => ({
    isEditing: false,
    isHovered: false,
    selectedCategory: undefined,
    selectedProminence: currentProminence?.toLowerCase() ?? undefined,
    savedProminence: currentProminence?.toLowerCase() ?? undefined,
    selectedIsLede: prominence?.includes("LEDE") ?? false,
    savedIsLede: prominence?.includes("LEDE") ?? false,
    savedSelectedCategory: getPrettyName(
      overriddenArticleNewsCategory ?? articleNewsCategory ?? "",
    ),
  }));

  const handleEditClick = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isEditing: true,
      isHovered: false,
      selectedProminence: currentProminence?.toLowerCase(),
      selectedIsLede: prominence?.includes("LEDE") ?? false,
    }));
  }, [currentProminence, prominence]);

  const badges = useMemo(() => {
    const categoryBadge =
      state.savedSelectedCategory &&
      state.savedSelectedCategory.toLowerCase() !== "unknown" &&
      state.savedSelectedCategory.trim() !== ""
        ? [state.savedSelectedCategory]
        : [];

    return [
      ...categoryBadge,
      ...(tierLevel ? [`Tier ${tierLevel}`] : []),
      ...(state.savedProminence ? [state.savedProminence.toUpperCase()] : []),
      ...(state.savedIsLede ? ["Lede"] : []),
    ].map(String);
  }, [
    state.savedSelectedCategory,
    state.savedIsLede,
    state.savedProminence,
    tierLevel,
  ]);

  const handleSaveChanges = useCallback(async () => {
    if (!onSave) return false;

    const updates: UpdateArticleMutationInputType = {};

    if (state.selectedCategory) {
      updates.category = toSnakeCase(state.selectedCategory);
    }

    const hasProminenceChange =
      state.selectedProminence !== currentProminence?.toLowerCase();
    const hasLedeChange = state.selectedIsLede !== state.savedIsLede;

    if (hasProminenceChange || hasLedeChange) {
      if (state.selectedProminence) {
        const upperProminence = state.selectedProminence.toUpperCase();
        if (isValidProminence(upperProminence)) {
          updates.prominence = upperProminence;
        }
      }
      updates.isLede = state.selectedIsLede;
    }

    const result = await onSave(updates);
    if (result) {
      setState((prev) => ({
        ...prev,
        savedSelectedCategory:
          state.selectedCategory ?? prev.savedSelectedCategory,
        savedIsLede: state.selectedIsLede,
        savedProminence: state.selectedProminence,
      }));
    }
    return result;
  }, [
    onSave,
    state.selectedCategory,
    state.selectedProminence,
    state.selectedIsLede,
    prominence,
    currentProminence,
  ]);

  const onOpenChange = useCallback(
    async (opened: boolean) => {
      setState((prev) => ({ ...prev, isEditing: opened }));
      if (!opened) {
        await handleSaveChanges();
      }
    },
    [handleSaveChanges],
  );

  const filteredAndSortedCategories = useMemo(
    () =>
      categories
        ?.filter(([value]) => {
          const normalizedValue = toSnakeCase(value ?? "").toLowerCase();
          return desiredCategoryOrder.includes(normalizedValue);
        })
        .sort((a, b) => {
          const aValue = toSnakeCase(a[1] ?? "").toLowerCase();
          const bValue = toSnakeCase(b[1] ?? "").toLowerCase();
          const aIndex = desiredCategoryOrder.indexOf(aValue);
          const bIndex = desiredCategoryOrder.indexOf(bValue);
          return aIndex - bIndex;
        }) ?? [],
    [categories],
  );

  const handleSave = useCallback(async () => {
    const result = await handleSaveChanges();
    if (result) {
      setState((prev) => ({ ...prev, isEditing: false }));
    }
    return result;
  }, [handleSaveChanges]);

  useEffect(() => {
    if (lastBadgeRef.current) {
      setEditButtonPosition(
        lastBadgeRef.current.offsetLeft + lastBadgeRef.current.offsetWidth + 8,
      );
    }
  }, [badges]);

  return (
    <div
      className={cn(
        "space-y-1 relative group items-center flex -my-2 -mx-2.5 rounded",
        className,
      )}
      onMouseEnter={() => setState((prev) => ({ ...prev, isHovered: true }))}
      onMouseLeave={() => setState((prev) => ({ ...prev, isHovered: false }))}
      onClick={handleEditClick}
      onKeyUp={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleEditClick();
        }
      }}
    >
      <div
        className={cn(
          "flex gap-2 p-2.5 pr-10",
          state.isHovered ? "bg-slate-200/20 cursor-pointer" : "",
        )}
      >
        {badges.map((badge) => (
          <div
            key={badge}
            ref={badge === badges[badges.length - 1] ? lastBadgeRef : null}
          >
            <Badge
              key={badge}
              status={false}
              variant="white"
              bordered={true}
              className="font-semibold p-1 cursor-pointer"
            >
              {getPrettyName(badge)}
            </Badge>
          </div>
        ))}
      </div>
      {categories && lastBadgeRef && (
        <>
          <EditButton
            ref={triggerRef}
            onEditClick={handleEditClick}
            onHoverChange={(hovered) =>
              setState((prev) => ({ ...prev, isHovered: hovered }))
            }
            className="top-2.5"
            style={{
              position: "absolute",
              left: editButtonPosition,
            }}
          />
          <Popover
            triggerRef={triggerRef}
            isOpen={state.isEditing}
            onOpenChange={onOpenChange}
            className="min-w-48"
          >
            <PopoverDialog
              variant="noPadding"
              className="min-w-[280px]"
              footer={
                <div className="flex justify-end gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onPress={() => {
                      setState((prev) => ({
                        ...prev,
                        isEditing: false,
                        selectedProminence: prev.savedProminence,
                        selectedIsLede: prev.savedIsLede,
                      }));
                    }}
                  >
                    Cancel
                  </Button>
                  <Button size="sm" onPress={handleSave}>
                    Save
                  </Button>
                </div>
              }
            >
              <div>
                <div className="space-y-5">
                  <div>
                    <Label className="text-xs m-4 mb-0 text-gray-500 font-medium pb-1">
                      Prominence
                    </Label>
                    <RadioGroup
                      defaultValue={state.savedProminence ?? ""}
                      onChange={(value: string) =>
                        setState((prev) => ({
                          ...prev,
                          selectedProminence: value as ValidProminenceType,
                        }))
                      }
                      className="mt-2 mx-4 space-y-1.5"
                    >
                      <Radio value="headline" className="text-xs font-normal">
                        Headline
                      </Radio>
                      <Radio value="feature" className="text-xs font-normal">
                        Feature
                      </Radio>
                      <Radio
                        value="passing_mention"
                        className="text-xs font-normal"
                      >
                        Passing Mention
                      </Radio>
                    </RadioGroup>

                    <div className="mt-4 border-t border-slate-200/40 mx-4 pt-4">
                      <Checkbox
                        defaultChecked={state.savedIsLede}
                        onCheckedChange={(checked: boolean) => {
                          setState((prev) => ({
                            ...prev,
                            selectedIsLede: checked,
                          }));
                        }}
                        className="text-xs font-normal"
                      >
                        Also mentioned in Lede
                      </Checkbox>
                    </div>
                  </div>

                  <div className="border-t border-slate-200/40">
                    <Label className="text-xxs m-4 mb-0 text-gray-500 pb-1 font-medium">
                      Classification
                    </Label>
                    {filteredAndSortedCategories && (
                      <RadioGroup
                        defaultValue={toLowerCase(
                          toSnakeCase(state.savedSelectedCategory ?? ""),
                        )}
                        onChange={(category) =>
                          setState((prev) => ({
                            ...prev,
                            selectedCategory: category,
                          }))
                        }
                        className="mx-4 my-2 space-y-1.5"
                      >
                        {filteredAndSortedCategories.map(([id, value]) => (
                          <Radio
                            key={id}
                            value={id ?? ""}
                            className="text-xs font-normal"
                          >
                            {value}
                          </Radio>
                        ))}
                      </RadioGroup>
                    )}
                  </div>
                </div>
              </div>
            </PopoverDialog>
          </Popover>
        </>
      )}
    </div>
  );
};
