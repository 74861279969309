import Card from "@/components/ui/card";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/navigation/popover";
import type {
  FeedWithAugTypes,
  UpdateArticleMutationInputType,
} from "@/data-access/news";
import { DotsThree, Trash } from "@phosphor-icons/react";
import type React from "react";
import { useEffect, useRef, useState } from "react";

import { hasSecondSection } from "@/components/news/shared/utils";

import PlaceHolderImage from "@/assets/images/article-placeholder.webp";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/controls/separator";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import type { CategoryQueryResponse } from "@/data-access/news/categoryQuery";
import { cn } from "@/lib/utils";
import { setSelectedArticles } from "@/store/news/articleManagement.actions";
import type { ArticleEditFn, AuthorType } from "@/types/article";
import ArticleImage from "../feed/NewsImage";
import ScoreDisplay from "../feed/ScoreDisplay";
import ScoreDetail from "../feed/score/ScoreDetail";
import { ArticleBadgeRow } from "../shared/ArticleBadgeDisplay";
import ArticleMentionText from "./ArticleMentionText";
import ArticleAuthor from "./FeedArticleAuthor";
import ArticleHeader from "./FeedArticleHeader";
import ArticleSummary from "./FeedArticleSummary";

export type FeedArticleProps = {
  categories: CategoryQueryResponse["categories"];
  article: Partial<FeedWithAugTypes>;
  handleClick?: () => void;
  onChange?: ArticleEditFn;
  handleDeleteClick?: () => void;
  image?: HTMLImageElement;
  staleScore?: boolean;
  isPreview?: boolean;
  isLoading?: boolean;
  isSelected?: boolean;
};

const FeedArticle = ({
  categories,
  article,
  handleClick,
  handleDeleteClick,
  onChange,
  image,
  staleScore = true,
  isPreview = false,
  isLoading = false,
  isSelected = false,
}: FeedArticleProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isCardFocused, setIsCardFocused] = useState(false);
  const [summary, setSummary] = useState(
    article.overriddenSummary || article.summary || "Add a summary...",
  );
  const onChangeSummary = (summary: string) => {
    setSummary(summary);
    if (onChange) onChange({ articleId: article.id ?? 0, summary });
  };

  const secondSection = hasSecondSection(article);
  const gridTemplateRows = secondSection
    ? "grid-rows-[auto,auto]"
    : "grid-rows-[auto]";

  const cardLayout =
    "p-5 text-left md:max-w-[900px] w-full relative grid grid-cols-[auto,1fr,1px,119px] gap-3.5 pb-2 overflow-visible";
  const centerSectionHeader = "flex flex-col gap-y-2";

  // Handle keyboard navigation within the card
  const handleCardKeyDown = (e: React.KeyboardEvent) => {
    // When in card navigation mode, trap focus within the card
    if (
      isCardFocused &&
      e.key === "Tab" &&
      cardRef.current &&
      cardRef.current.contains(document.activeElement)
    ) {
      if (!cardRef.current) return;

      // Find all focusable elements
      const focusableElements = Array.from(
        cardRef.current.querySelectorAll(
          'button, a, [href], input, select, textarea, [tabindex="0"]',
        ),
      ).filter((el) => el instanceof HTMLElement) as HTMLElement[];

      if (focusableElements.length === 0) return;

      // Get the currently focused element
      const focusedElement = document.activeElement;

      // If the focused element is not within the card, don't trap focus
      if (!cardRef.current.contains(focusedElement)) {
        return;
      }

      // Find the index of the currently focused element
      const currentIndex = focusableElements.findIndex(
        (el) => el === focusedElement,
      );

      // Calculate the next index based on shift key
      let nextIndex: number;
      if (e.shiftKey) {
        // Go to previous element, or wrap to the end
        nextIndex =
          currentIndex <= 0 ? focusableElements.length - 1 : currentIndex - 1;
      } else {
        // Go to next element, or wrap to the beginning
        nextIndex =
          currentIndex === focusableElements.length - 1 ? 0 : currentIndex + 1;
      }

      // Prevent default tab behavior
      e.preventDefault();

      // Focus the next element if it exists
      if (nextIndex >= 0 && nextIndex < focusableElements.length) {
        focusableElements[nextIndex]?.focus();
      }
      return;
    }

    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault(); // Prevent scrolling on space

      if (!isCardFocused) {
        // Enter the card's internal navigation
        setIsCardFocused(true);

        // Focus the first interactive element inside the card
        setTimeout(() => {
          if (cardRef.current) {
            // Find all focusable elements
            const focusableElements = cardRef.current.querySelectorAll(
              'button, a, [href], input, select, textarea, [tabindex="0"]',
            );

            // Focus the first one if it exists
            if (focusableElements.length > 0) {
              (focusableElements[0] as HTMLElement).focus();
            }
          }
        }, 0);
      } else {
        // If already in card navigation, trigger the click handler
        handleClick?.();
      }
    } else if (e.key === "Escape" && isCardFocused) {
      e.preventDefault(); // Prevent any default escape behavior
      setIsCardFocused(false);

      // Return focus to the card
      setTimeout(() => {
        cardRef.current?.focus();
      }, 0);
    }
  };

  // Apply tabindex to internal elements based on card focus state
  useEffect(() => {
    if (!cardRef.current) return;
    // Find all interactive elements
    const elements = cardRef.current.querySelectorAll(
      'button, a, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );
    // Set tabindex based on card focus state
    for (const el of elements) {
      if (el instanceof HTMLElement) {
        el.tabIndex = isCardFocused ? 0 : -1;
      }
    }
  }, [isCardFocused]);

  // Set initial tabindex values on mount
  useEffect(() => {
    if (!cardRef.current) return;
    // Find all interactive elements
    const elements = cardRef.current.querySelectorAll(
      'button, a, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );
    // Set initial tabindex to -1 to prevent tab navigation
    for (const el of elements) {
      if (el instanceof HTMLElement) {
        el.tabIndex = -1;
      }
    }
  }, []);

  if (isLoading) {
    return (
      <Card className={cn(cardLayout, gridTemplateRows)}>
        <Skeleton
          className="w-[310px] h-[232px] from-[oklch(75%_0.17_225/0.12)] via-[oklch(61%_0.27_301/0.08)] to-[oklch(75%_0.17_225/0.12)]"
          animate="wave"
        />
        <div className={centerSectionHeader}>
          <div className="space-y-2">
            <Skeleton className="w-20 h-4" />
            <Skeleton className="w-[350px] h-7" />
            <Skeleton className="w-[320px] h-7" />
            <div className="pt-3 flex flex-row gap-1.5">
              <Skeleton className="w-4 h-4 rounded-sm" />
              <Skeleton className="w-20 h-4 bg-blue-50" />
            </div>
            <div className="flex flex-row gap-1.5">
              <Skeleton className="w-4 h-4 rounded-sm" />
              <Skeleton className="w-24 h-4" />
            </div>
            <div className="pt-2 flex flex-row gap-1.5">
              <Skeleton className="w-30 h-4" />
              <Separator orientation="vertical" />
              <Skeleton className="w-30 h-4" />
              <Separator orientation="vertical" />
              <Skeleton className="w-20 h-4" />
            </div>
            <div className="pt-2 flex flex-row gap-1">
              <Skeleton className="w-16 h-6" />
              <Separator orientation="vertical" />
              <Skeleton className="w-16 h-6" />
              <Separator orientation="vertical" />
              <Skeleton className="w-16 h-6" />
            </div>
          </div>
        </div>
        <Separator
          orientation="vertical"
          className="mt-5 max-h-40 bg-gray-100"
        />
        <div className="flex flex-col pt-8 gap-2">
          <Skeleton className="w-20 h-4" />
          <Skeleton className="w-14 h-6 bg-violet-100" />
          <Separator orientation="horizontal" className="my-2 bg-gray-100" />
          <div className="flex gap-1">
            <Skeleton className="w-16 h-4" />
            <Skeleton className="w-4 h-4 bg-violet-100" />
          </div>
          <Skeleton className="w-20 h-6" />
        </div>
        <div className="flex flex-col gap-2 col-span-4 pr-6 pb-4">
          <Skeleton className="w-20 h-4" />
          <Skeleton className="w-[800px] h-3" />
          <Skeleton className="w-full h-3" />
          <Skeleton className="w-3/4 h-3" />
          <Separator orientation="horizontal" className="my-2 bg-gray-100" />
          <Skeleton className="w-20 h-4" />
          <Skeleton className="w-full h-3" />
        </div>
      </Card>
    );
  }

  return (
    <Card
      className={cn(
        cardLayout,
        isSelected && "border border-violet-800 transition-colors duration-150",
        isCardFocused && "ring-2 ring-violet-800 bg-violet-50/30 shadow-lg",
      )}
      style={{
        gridTemplateRows,
      }}
      ref={cardRef}
      tabIndex={0}
      // biome-ignore lint/a11y/useSemanticElements: <do not need it>
      role="article"
      aria-selected={isSelected}
      aria-expanded={isCardFocused}
      aria-label={`${article.headline || "Article"} ${isCardFocused ? "navigation mode active, press Escape to exit" : "press Enter to navigate inside"}`}
      aria-describedby={
        isCardFocused ? "card-navigation-instructions" : undefined
      }
      aria-roledescription={
        isCardFocused ? "Card with focus trap navigation" : undefined
      }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onKeyDown={handleCardKeyDown}
    >
      {isCardFocused && (
        <div id="card-navigation-instructions" className="sr-only">
          You are now in card navigation mode. Use tab to cycle through elements
          within this card. Press Escape to exit.
        </div>
      )}
      {article.imageUrl && (
        <ArticleImage
          size="medium"
          image={image}
          imageUrl={article.imageUrl}
          isHovering={isHovered}
        />
      )}
      {!article.imageUrl && (
        <ArticleImage
          size="medium"
          imageUrl={PlaceHolderImage}
          isHovering={isHovered}
        />
      )}
      <div className={centerSectionHeader}>
        <div className="space-y-0.5">
          <ArticleHeader
            articleId={article.id ?? 0}
            headline={article.headline ?? ""}
            overriddenHeadline={
              article.overriddenHeadline
                ? (article.overriddenHeadline as string)
                : undefined
            }
            articlePublisher={article.articlePublisher ?? null}
            overriddenArticlePublisher={
              article.overriddenArticlePublisher ?? null
            }
            articleUrl={article.articleUrl}
            handleHeadlineClick={handleClick}
            articleDate={
              article.overriddenDatePublished ?? article.articleLastUpdateDate
            }
            onHeadlineChange={(headline) => {
              if (onChange) onChange({ articleId: article.id ?? 0, headline });
            }}
          />
          <ArticleAuthor
            articleAuthors={article.articleAuthors || []}
            overriddenArticleAuthors={
              (article.overriddenArticleAuthors as AuthorType[]) ?? undefined
            }
            onAuthorChange={(author) => {
              if (onChange)
                onChange({ articleId: article.id ?? 0, author: author || "" });
            }}
          />
        </div>

        <ScoreDetail
          articleId={article.articleId}
          maxDomainAuthority={article.maxDomainAuthority}
          maxSocial={article.maxSocial}
          articleReadership={article.articleReadership}
        />
        <ArticleBadgeRow
          categories={categories}
          {...article}
          onSave={(value: UpdateArticleMutationInputType) => {
            if (onChange)
              return onChange({ articleId: article.id ?? 0, ...value });
          }}
        />
      </div>
      <Separator orientation="vertical" className="mt-5 max-h-40" />
      <ScoreDisplay
        impactScore={article.maxScore}
        staleScore={staleScore}
        articleSentiment={article.articleSentiment}
        overriddenArticleSentiment={article.overriddenArticleSentiment}
        onSentimentChange={(articleSentiment) => {
          onChange?.({
            articleId: article.id ?? 0,
            sentimentPolarity: articleSentiment?.polarity.toUpperCase(),
            sentimentRationale: articleSentiment?.rationale,
          });
        }}
      />
      {secondSection && (
        <div className="w-full flex flex-col space-y-3 col-span-4 text-xs tracking-wide leading-normal justify-center text-gray-500">
          <ArticleSummary
            summary={summary}
            overriddenSummary={
              article.overriddenSummary
                ? (article.overriddenSummary as string)
                : undefined
            }
            onSummaryChange={onChangeSummary}
          />
          {(article.searchHit || article.firstArticleMention) && <Separator />}
          {article.firstArticleMention && (
            <div className="gap-2 flex flex-col pb-2">
              <b className="uppercase leading-4">First Mention</b>
              <ArticleMentionText
                className="line-clamp-1"
                term={article.firstArticleMention.term}
                snippet={article.firstArticleMention.snippet}
              />
            </div>
          )}
          {article.searchHit && (
            <div className="gap-2 flex flex-col">
              <b className="uppercase leading-4">Search Hit</b>
              <ArticleMentionText
                className="line-clamp-1"
                term={article.searchHit.term}
                snippet={article.searchHit.mention}
              />
            </div>
          )}
        </div>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="z-100 absolute right-5 top-2 text-slate-700 hover:text-slate-800 w-8 h-8"
          >
            <DotsThree className="h-6 w-6" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0 w-max">
          {!isPreview && (
            <Button
              variant="ghost"
              className="text-text-destructive text-sm flex flex-row justify-start items-center px-3.5 text-slate-800"
              onPress={() => {
                setSelectedArticles([article.id ?? 0]);
                handleDeleteClick?.();
              }}
            >
              <Trash className="me-2 text-red-750" />
              Delete
            </Button>
          )}
        </PopoverContent>
      </Popover>
    </Card>
  );
};

export default FeedArticle;
