import ConfirmationDialog from "@/components/common/ConfirmationDialog";
import { DialogOverlay } from "@/components/ui/dialog.tsx";

type FeedRemoveProps = {
  isOpen: boolean;
  onClose: () => void;
  feedName: string;
  onDelete: () => void;
};

const FeedRemove = ({
  isOpen,
  onClose,
  feedName,
  onDelete,
}: FeedRemoveProps) => {
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <DialogOverlay isOpen={isOpen}>
      <ConfirmationDialog
        title={`Are you sure you want to delete "${feedName}"?`}
        onCancelClick={onClose}
        submitText="Yes, delete"
        submittingText="Deleting"
        submitVariant="destructive"
        onSubmitClick={handleDelete}
      >
        <p className="text-sm">
          If you delete this newsfeed, it can only be restored by contacting
          your system administrator.
        </p>
      </ConfirmationDialog>
    </DialogOverlay>
  );
};

export default FeedRemove;
