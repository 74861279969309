/**
 * Utility to collect system information for DataDog logging
 */

export interface SystemInfo {
  // Browser information
  browser: {
    name: string;
    version: string;
    userAgent: string;
    language: string;
    cookiesEnabled: boolean;
    doNotTrack: string | null;
  };
  // Screen and viewport information
  screen: {
    width: number;
    height: number;
    colorDepth: number;
    pixelRatio: number;
    orientation: string;
  };
  viewport: {
    width: number;
    height: number;
  };
  // Device information
  device: {
    platform: string;
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    touchPoints: number;
  };
  // Connection information
  connection: {
    effectiveType?: string;
    downlink?: number;
    rtt?: number;
    saveData?: boolean;
  };
  // Performance information
  performance: {
    memory?: {
      jsHeapSizeLimit?: number;
      totalJSHeapSize?: number;
      usedJSHeapSize?: number;
    };
  };
  // Hardware information
  hardware: {
    cpuCores?: number;
    deviceMemory?: number;
  };
  // Time information
  time: {
    timezone: string;
    timezoneOffset: number;
  };
}

// Define the NetworkInformation interface that's not fully included in standard TypeScript types
interface NetworkInformation {
  effectiveType?: string;
  downlink?: number;
  rtt?: number;
  saveData?: boolean;
}

// Extend Navigator interface to include connection property
interface ExtendedNavigator extends Navigator {
  connection?: NetworkInformation;
  mozConnection?: NetworkInformation;
  webkitConnection?: NetworkInformation;
}

/**
 * Detects browser name and version from user agent
 */
const detectBrowser = (): { name: string; version: string } => {
  const userAgent = navigator.userAgent;
  let name = "Unknown";
  let version = "Unknown";

  // Chrome
  if (
    /Chrome/.test(userAgent) &&
    !/Chromium|Edge|Edg|OPR|Opera/.test(userAgent)
  ) {
    name = "Chrome";
    version = userAgent.match(/Chrome\/(\d+\.\d+)/)?.[1] || "Unknown";
  }
  // Firefox
  else if (/Firefox/.test(userAgent)) {
    name = "Firefox";
    version = userAgent.match(/Firefox\/(\d+\.\d+)/)?.[1] || "Unknown";
  }
  // Safari
  else if (
    /Safari/.test(userAgent) &&
    !/Chrome|Chromium|Edge|Edg|OPR|Opera/.test(userAgent)
  ) {
    name = "Safari";
    version = userAgent.match(/Version\/(\d+\.\d+)/)?.[1] || "Unknown";
  }
  // Edge
  else if (/Edge|Edg/.test(userAgent)) {
    name = "Edge";
    version =
      userAgent.match(/Edge\/(\d+\.\d+)|Edg\/(\d+\.\d+)/)?.[1] || "Unknown";
  }
  // Opera
  else if (/OPR|Opera/.test(userAgent)) {
    name = "Opera";
    version =
      userAgent.match(/OPR\/(\d+\.\d+)|Opera\/(\d+\.\d+)/)?.[1] || "Unknown";
  }
  // IE
  else if (/MSIE|Trident/.test(userAgent)) {
    name = "Internet Explorer";
    version =
      userAgent.match(/MSIE (\d+\.\d+)|rv:(\d+\.\d+)/)?.[1] || "Unknown";
  }

  return { name, version };
};

/**
 * Detects device type based on user agent and screen size
 */
const detectDeviceType = (): {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
} => {
  const userAgent = navigator.userAgent;
  const width = window.innerWidth;

  // Mobile detection
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    ) && width < 768;

  // Tablet detection
  const isTablet =
    (/Android|webOS|iPad|iPod|BlackBerry/i.test(userAgent) &&
      width >= 768 &&
      width < 1024) ||
    /iPad/i.test(userAgent);

  // Desktop is everything else
  const isDesktop = !isMobile && !isTablet;

  return { isMobile, isTablet, isDesktop };
};

/**
 * Gets the screen orientation
 */
const getOrientation = (): string => {
  if (window.screen?.orientation) {
    return window.screen.orientation.type;
  }

  if (window.matchMedia) {
    if (window.matchMedia("(orientation: portrait)").matches) {
      return "portrait";
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
      return "landscape";
    }
  }

  return window.innerHeight > window.innerWidth ? "portrait" : "landscape";
};

/**
 * Collects all available system information
 */
const collectSystemInfo = (): SystemInfo => {
  const browserInfo = detectBrowser();
  const deviceType = detectDeviceType();

  // Get connection information if available
  const extendedNavigator = navigator as ExtendedNavigator;
  const connection =
    extendedNavigator.connection ||
    extendedNavigator.mozConnection ||
    extendedNavigator.webkitConnection ||
    {};

  // Get performance memory if available
  const performance = window.performance || {};
  // biome-ignore lint/suspicious/noExplicitAny: <performance.memory is not in standard types>
  const memory = (performance as any).memory || {};

  // Get hardware concurrency if available
  const cpuCores = navigator.hardwareConcurrency;
  // biome-ignore lint/suspicious/noExplicitAny: <deviceMemory is not in standard types>
  const deviceMemory = (navigator as any).deviceMemory;

  return {
    browser: {
      name: browserInfo.name,
      version: browserInfo.version,
      userAgent: navigator.userAgent,
      language: navigator.language,
      cookiesEnabled: navigator.cookieEnabled,
      doNotTrack: navigator.doNotTrack,
    },
    screen: {
      width: window.screen.width,
      height: window.screen.height,
      colorDepth: window.screen.colorDepth,
      pixelRatio: window.devicePixelRatio,
      orientation: getOrientation(),
    },
    viewport: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    device: {
      platform: navigator.platform,
      isMobile: deviceType.isMobile,
      isTablet: deviceType.isTablet,
      isDesktop: deviceType.isDesktop,
      touchPoints: navigator.maxTouchPoints || 0,
    },
    connection: {
      effectiveType: connection.effectiveType,
      downlink: connection.downlink,
      rtt: connection.rtt,
      saveData: connection.saveData,
    },
    performance: {
      memory: {
        jsHeapSizeLimit: memory.jsHeapSizeLimit,
        totalJSHeapSize: memory.totalJSHeapSize,
        usedJSHeapSize: memory.usedJSHeapSize,
      },
    },
    hardware: {
      cpuCores,
      deviceMemory,
    },
    time: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezoneOffset: new Date().getTimezoneOffset(),
    },
  };
};

/**
 * Formats system information for logging
 */
// biome-ignore lint/suspicious/noExplicitAny: <return type needs to be flexible>
export const getSystemInfoForLogging = (): Record<string, any> => {
  const systemInfo = collectSystemInfo();

  // Flatten the object for easier querying in DataDog
  return {
    "browser.name": systemInfo.browser.name,
    "browser.version": systemInfo.browser.version,
    "browser.language": systemInfo.browser.language,
    "screen.width": systemInfo.screen.width,
    "screen.height": systemInfo.screen.height,
    "screen.pixelRatio": systemInfo.screen.pixelRatio,
    "screen.orientation": systemInfo.screen.orientation,
    "viewport.width": systemInfo.viewport.width,
    "viewport.height": systemInfo.viewport.height,
    "device.platform": systemInfo.device.platform,
    "device.isMobile": systemInfo.device.isMobile,
    "device.isTablet": systemInfo.device.isTablet,
    "device.isDesktop": systemInfo.device.isDesktop,
    "device.touchPoints": systemInfo.device.touchPoints,
    "connection.effectiveType": systemInfo.connection.effectiveType,
    "connection.downlink": systemInfo.connection.downlink,
    "hardware.cpuCores": systemInfo.hardware.cpuCores,
    "hardware.deviceMemory": systemInfo.hardware.deviceMemory,
    "time.timezone": systemInfo.time.timezone,
  };
};

/**
 * Formats system information for Sentry
 *
 * This creates a format suitable for Sentry's context/tags
 */
export const getSystemInfoForSentry = (): {
  tags: Record<string, string | number | boolean>;
  // biome-ignore lint/suspicious/noExplicitAny: <contexts need to be flexible>
  contexts: Record<string, Record<string, any>>;
} => {
  const systemInfo = collectSystemInfo();

  // Create tags for important properties (for filtering/searching)
  const tags: Record<string, string | number | boolean> = {
    "browser.name": systemInfo.browser.name,
    "browser.version": systemInfo.browser.version,
    "device.type": systemInfo.device.isMobile
      ? "mobile"
      : systemInfo.device.isTablet
        ? "tablet"
        : "desktop",
    "screen.width": systemInfo.screen.width,
    "screen.height": systemInfo.screen.height,
    "viewport.width": systemInfo.viewport.width,
    "viewport.height": systemInfo.viewport.height,
    "device.platform": systemInfo.device.platform,
    "connection.effectiveType":
      systemInfo.connection.effectiveType || "unknown",
  };

  // Create contexts for detailed information (grouped by category)
  // biome-ignore lint/suspicious/noExplicitAny: <contexts need to be flexible>
  const contexts: Record<string, Record<string, any>> = {
    browser: {
      name: systemInfo.browser.name,
      version: systemInfo.browser.version,
      language: systemInfo.browser.language,
      userAgent: systemInfo.browser.userAgent,
      cookiesEnabled: systemInfo.browser.cookiesEnabled,
      doNotTrack: systemInfo.browser.doNotTrack,
    },
    screen: {
      width: systemInfo.screen.width,
      height: systemInfo.screen.height,
      colorDepth: systemInfo.screen.colorDepth,
      pixelRatio: systemInfo.screen.pixelRatio,
      orientation: systemInfo.screen.orientation,
    },
    viewport: {
      width: systemInfo.viewport.width,
      height: systemInfo.viewport.height,
    },
    device: {
      platform: systemInfo.device.platform,
      isMobile: systemInfo.device.isMobile,
      isTablet: systemInfo.device.isTablet,
      isDesktop: systemInfo.device.isDesktop,
      touchPoints: systemInfo.device.touchPoints,
    },
    connection: {
      effectiveType: systemInfo.connection.effectiveType,
      downlink: systemInfo.connection.downlink,
      rtt: systemInfo.connection.rtt,
      saveData: systemInfo.connection.saveData,
    },
    hardware: {
      cpuCores: systemInfo.hardware.cpuCores,
      deviceMemory: systemInfo.hardware.deviceMemory,
    },
    time: {
      timezone: systemInfo.time.timezone,
      timezoneOffset: systemInfo.time.timezoneOffset,
    },
  };

  return { tags, contexts };
};
