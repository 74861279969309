import { CaretLeft } from "@phosphor-icons/react";
import { type CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { HandraiseLogoMark } from "@/assets/handraise";
import { AuthForm } from "@/components/auth/AuthForm";
import BackgroundGradientLayout from "@/components/common/Gradient";
import { Button } from "@/components/ui/button";
import { registerSchema } from "@/lib/auth/authValidation";
import { cn } from "@/lib/utils";
import { startGoogleLogin, startRegister } from "@/store/auth.actions";
import useAuth from "@/store/auth.slice";
import type { z } from "zod";

const RegisterScreen = () => {
  const navigate = useNavigate();
  const authStatus = useAuth((state) => state.status);
  const loggedIn = useAuth((state) => state.loggedIn);

  const handleGoogleLogin = (response: CredentialResponse) => {
    if (response.credential) {
      startGoogleLogin({
        client_id: response.clientId || "",
        access_token: response.credential,
      });
    }
  };

  const handleRegister = async (data: z.infer<typeof registerSchema>) => {
    try {
      await startRegister({
        email: data.email,
        password1: data.password1,
        password2: data.password2,
      });
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  useEffect(() => {
    if (loggedIn && authStatus === "succeeded") {
      navigate("/newsfeeds", { replace: true });
    }
  }, [loggedIn, authStatus, navigate]);

  return (
    <BackgroundGradientLayout>
      <div className="flex justify-center items-center h-screen">
        <div className="absolute top-5 left-5">
          <Button
            variant="ghost"
            onPress={() => navigate("/auth/login")}
            className="text-gray-600 flex items-center"
          >
            <CaretLeft className="mr-2" />
            Back to Login
          </Button>
        </div>

        <div
          className={cn(
            "w-full max-w-md px-8 pt-6 pb-5 mb-28",
            "bg-white/75 backdrop-blur-sm shadow-xl rounded-lg",
            "border border-gray-100/30",
          )}
        >
          <div className="w-full flex items-center justify-center mt-3 mb-6">
            <HandraiseLogoMark className="w-48" />
          </div>

          <h1 className="text-center text-lg font-serif text-gray-700 mb-2">
            Create your account
          </h1>
          <p className="text-center text-gray-500 text-sm mb-6">
            Start your AI-powered news journey
          </p>

          <div className="mb-6 w-full flex items-center justify-center">
            <GoogleLogin
              onSuccess={handleGoogleLogin}
              theme="outline"
              text="signup_with"
            />
          </div>

          <div className="relative mb-6 flex items-center justify-center">
            <div className="flex-grow border-t border-gray-300" />
            <span className="px-4 text-gray-500">or</span>
            <div className="flex-grow border-t border-gray-300" />
          </div>

          <AuthForm<typeof registerSchema>
            schema={registerSchema}
            onSubmit={handleRegister}
            fields={[
              {
                name: "email",
                label: "Email",
                type: "email",
              },
              {
                name: "password1",
                label: "Password",
                type: "password",
              },
              {
                name: "password2",
                label: "Confirm Password",
                type: "password",
              },
            ]}
            submitLabel="Create Account"
          />

          <div className="mt-4 text-center text-sm text-gray-600">
            Already have an account?{" "}
            <Link to="/auth/login" className="text-violet-700 hover:underline">
              Log in
            </Link>
          </div>

          <div className="mt-8 text-center text-xs text-gray-500">
            By creating an account, you agree to our terms of service and
            privacy policy
          </div>
        </div>
      </div>
    </BackgroundGradientLayout>
  );
};

export default RegisterScreen;
