import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogOverlay,
} from "@/components/ui/dialog";
import { JollyTextField } from "@/components/ui/textfield";
import {
  createKeyMessageGroup,
  updateKeyMessageGroup,
} from "@/store/key-messages/key-message-async.actions";
import {
  setKeyMessageGroupName,
  setSelectedGroup,
} from "@/store/key-messages/key-message.actions";
import useKeyMessageStore from "@/store/key-messages/key-message.slice";
import useFeedStore from "@/store/useFeedStore";

type CreateKeyMessageGroupModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
const CreateKeyMessageGroupModal = ({
  onClose,
  isOpen,
}: CreateKeyMessageGroupModalProps) => {
  const groupName = useKeyMessageStore((state) => state.keyMessageGroupName);

  const feedId = useFeedStore((state) => state.feedId);

  const selectedGroup = useKeyMessageStore((state) => state.selectedGroup);

  const isEditingKeyMessageGroup = useKeyMessageStore(
    (state) => state.isEditingKeyMessageGroup,
  );

  const handleCancel = () => {
    onClose();
    setKeyMessageGroupName("");
  };

  const handleSave = () => {
    const input = {
      feedId: feedId?.toString() as string,
      name: groupName,
    };
    if (groupName.length) {
      return createKeyMessageGroup(input).then((res) => {
        if (res && !selectedGroup) {
          setSelectedGroup(res);
        }
        handleCancel();
      });
    }

    return;
  };

  const handleUpdate = () => {
    const input = {
      feedId: feedId?.toString() as string,
      groupId: selectedGroup?.id as string,
      name: groupName,
    };

    if (groupName.length) {
      return updateKeyMessageGroup(input).then(() => {
        handleCancel();
      });
    }

    return;
  };

  return (
    <DialogOverlay isOpen={isOpen} aria-labelledby={"Create Key Message Group"}>
      <DialogContent size={"sm"} closeButton={false}>
        <DialogBody className="space-y-2">
          <span className="text-xs font-medium text-gray-700">
            {isEditingKeyMessageGroup ? "Rename" : "New"} group name
          </span>
          <JollyTextField
            autoFocus
            value={groupName}
            onChange={(e) => setKeyMessageGroupName(e)}
            size={"sm"}
          />
        </DialogBody>
        <DialogFooter
          primaryLabel={"Save"}
          primaryAction={isEditingKeyMessageGroup ? handleUpdate : handleSave}
          secondaryLabel={"Cancel"}
          secondaryAction={handleCancel}
        />
      </DialogContent>
    </DialogOverlay>
  );
};
export default CreateKeyMessageGroupModal;
