import { create } from "@/data-access/core/createStore";
import type { UpdateArticleMutationInputType } from "@/data-access/news";

type ArticleManagementState = {
  selectedArticleIds: number[];
  deletedArticleIds: number[];
  isDeleteModalOpen: boolean;
  isEditModalOpen: boolean;
  editChanges: UpdateArticleMutationInputType;
};

const initialState: ArticleManagementState = {
  selectedArticleIds: [],
  deletedArticleIds: [],
  isDeleteModalOpen: false,
  isEditModalOpen: false,
  editChanges: {},
};

const useArticleManagementStore = create<ArticleManagementState>(
  "article-management",
)(() => initialState);

export default useArticleManagementStore;
