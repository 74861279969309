import { FilterCategory } from "@/store/news/feed.types";

export type ParamsState = {
  selected: Record<string, string[]>;
  searchTerms?: string;
  // Additional params that can be stored in URL
  filterDays?: number;
  page?: number;
  isEnabled?: boolean;
  isHidden?: boolean;
  nullValue?: null;
  [key: string]: unknown;
};

const DEFAULT_SELECTED = {
  [FilterCategory.CATEGORY]: ["News", "Press Release", "Owned Media"],
} as const;

const DEFAULT_PARAMS = {
  filterDays: 30,
  isCustomDaysRange: false,
  period: "day",
  sortOrder: "DATE_DESC",
} as const;

const SOCIAL_DELIMITER = "|";
const COMMA_PLACEHOLDER = "_C_";

const UrlStateManager = {
  isDefaultSelected(category: string, values: string[] = []): boolean {
    const defaultValues =
      DEFAULT_SELECTED[category as keyof typeof DEFAULT_SELECTED];
    return defaultValues
      ? JSON.stringify(defaultValues) === JSON.stringify(values)
      : values.length === 0;
  },

  isDefaultParam(key: string, value: unknown): boolean {
    const defaultValue = DEFAULT_PARAMS[key as keyof typeof DEFAULT_PARAMS];
    return defaultValue !== undefined && value === defaultValue;
  },

  parseFromUrl(searchParams: URLSearchParams): Partial<ParamsState> {
    const state: Partial<ParamsState> = {
      selected: {},
    } as Partial<ParamsState>;

    for (const [key, value] of searchParams.entries()) {
      if (key.startsWith("selected.")) {
        const category = key.replace("selected.", "");
        const decodedValue = decodeURIComponent(value);

        // Empty string means explicitly cleared category
        if (decodedValue === "") {
          state.selected = state.selected ?? {};
          state.selected[category] = [];
          continue;
        }

        // Handle non-empty values
        const delimiter =
          category === FilterCategory.SOCIAL ? SOCIAL_DELIMITER : ",";
        const values = decodedValue.split(delimiter);
        const processedValues = values.map((v) =>
          v.replaceAll(COMMA_PLACEHOLDER, ","),
        );

        // Only set if values exist
        if (processedValues.length > 0) {
          state.selected = state.selected ?? {};
          state.selected[category] = processedValues;
        }
      } else if (value !== "") {
        let parsedValue: string | number | boolean | null = value;

        // Handle type coercion for non-selected params
        if (value === "true") parsedValue = true;
        else if (value === "false") parsedValue = false;
        else if (value === "null") parsedValue = null;
        else if (!Number.isNaN(Number(value))) parsedValue = Number(value);

        (state as Record<string, unknown>)[key] = parsedValue;
      }
    }

    // Handle searchTerms restoration
    const searchTerms = searchParams.get("searchTerms");
    if (searchTerms) {
      state.searchTerms = searchTerms;
    }

    // Apply defaults for missing params
    for (const [key, value] of Object.entries(DEFAULT_PARAMS)) {
      if (!(key in state)) {
        (state as Record<string, unknown>)[key] = value;
      }
    }

    // Apply default CATEGORY only if it's not present in URL at all
    if (
      !searchParams.has("selected.CATEGORY") &&
      DEFAULT_SELECTED[FilterCategory.CATEGORY]
    ) {
      state.selected = state.selected ?? {};
      state.selected[FilterCategory.CATEGORY] = [
        ...DEFAULT_SELECTED[FilterCategory.CATEGORY],
      ];
    }

    return state;
  },

  convertToUrlParams(
    state: Partial<ParamsState>,
    skipDefaults = false,
  ): URLSearchParams {
    const searchParams = new URLSearchParams();

    if (state.selected) {
      for (const [key, values] of Object.entries(state.selected)) {
        // Skip default values if requested
        if (skipDefaults && this.isDefaultSelected(key, values)) {
          continue;
        }

        // Handle empty arrays
        if (values.length === 0) {
          // For CATEGORY, keep empty string to indicate explicit clearing
          if (key !== FilterCategory.CATEGORY) {
            continue; // Skip adding to URL for non-CATEGORY empty arrays
          }
          // Only CATEGORY gets empty string in URL
          searchParams.set(`selected.${key}`, "");
          continue;
        }

        // Handle non-empty arrays
        const encodedValues = values.map((v) =>
          v.replaceAll(",", COMMA_PLACEHOLDER),
        );
        const delimiter =
          key === FilterCategory.SOCIAL ? SOCIAL_DELIMITER : ",";
        searchParams.set(`selected.${key}`, encodedValues.join(delimiter));
      }
    }

    // Add other non-selected params
    for (const [key, value] of Object.entries(state)) {
      if (
        key !== "selected" &&
        !(key === "searchTerms" && !value) && // Only exclude empty searchTerms
        value !== undefined &&
        value !== null &&
        typeof value !== "function" &&
        // Skip default values
        !this.isDefaultParam(key, value)
      ) {
        searchParams.set(key, value.toString());
      }
    }

    return searchParams;
  },
};

export default UrlStateManager;
