import { isDevelopment } from "@/config";
import {
  type StateCreator,
  type StoreApi,
  type StoreMutatorIdentifier,
  create as zustandCreate,
} from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: {
      connect: (options: unknown) => {
        unsubscribe: () => void;
      };
    };
  }
}

interface SerializedSet {
  _isSet: true;
  values: unknown[];
}

type SetStateWithAction<T> = {
  (
    partial: T | Partial<T> | ((state: T) => T | Partial<T>),
    replace?: false,
  ): void;
  (state: T | ((state: T) => T), replace: true): void;
  (
    state: T | Partial<T> | ((state: T) => T | Partial<T>),
    replace: boolean,
    action?: string,
  ): void;
};

interface Store<T> {
  getInitialState: () => T;
  setState: SetStateWithAction<T>;
}

// biome-ignore lint/suspicious/noExplicitAny: <can be any type of store>
const storeRegistry = new Map<string, Store<any>>();

/**
 * Register a store to be cleared on logout
 * @param name Unique name for the store
 * @param store Store instance with getInitialState and setState methods
 */
export const registerStore = <T>(name: string, store: Store<T>) => {
  // Check if store already exists
  if (storeRegistry.has(name)) {
    return; // Skip registration if store already exists
  }
  // Validate required methods
  if (!store.getInitialState) {
    throw new Error(`Store ${name} must implement getInitialState`);
  }
  storeRegistry.set(name, store);
};

/**
 * Register a store for cleanup
 */
function registerStoreForCleanup<T>(store: StoreApi<T>, storeName: string) {
  const name = storeName || extractStoreNameFromStackTrace();
  const state = store.getState();

  // Only process if state is an object
  const initialState =
    typeof state === "object" && state !== null
      ? Object.entries(state).reduce(
          // biome-ignore lint/suspicious/noExplicitAny: <allowed here>
          (acc: Record<string, any>, [key, value]) => {
            if (typeof value !== "function") {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, unknown>,
        )
      : state;

  registerStore<T>(name, {
    getInitialState: () => initialState as T,
    setState: store.setState as SetStateWithAction<T>,
  });
}

/**
 * Helper function to extract store name from stack trace
 */
function extractStoreNameFromStackTrace(): string {
  try {
    const stackLines = new Error().stack?.split("\n") || [];

    // Find the line that called create
    // Usually it's 3-4 levels up in the stack
    for (let i = 3; i < Math.min(stackLines.length, 6); i++) {
      const line = stackLines[i] || "";

      // Look for a line that contains a .ts or .tsx file
      if (line.includes(".ts")) {
        // Extract file path
        const match = line.match(/([^/]+\.ts[x]?)/);
        if (match?.[1]) {
          // Remove extension
          return match[1].replace(/\.[^/.]+$/, "");
        }
      }
    }

    return "unknown-store";
  } catch (error) {
    console.error("Error extracting store name:", error);
    return "unknown-store";
  }
}

/**
 * Create a store with automatic registration for cleanup and devtools in development
 */
export const create = <T extends object>(name?: string) => {
  return <Mos extends [StoreMutatorIdentifier, unknown][] = []>(
    initializer: StateCreator<T, [], Mos>,
  ) => {
    const storeName = name ?? extractStoreNameFromStackTrace();

    // Create store with devtools in development
    if (
      isDevelopment &&
      typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION__
    ) {
      window.__REDUX_DEVTOOLS_EXTENSION__.connect({
        name: storeName,
        serialize: {
          replacer: (_: string, value: unknown) => {
            if (value instanceof Set) {
              return {
                _isSet: true,
                values: Array.from(value),
              };
            }
            return value;
          },
          reviver: (_: string, value: unknown) => {
            const serialized = value as SerializedSet;
            if (
              value &&
              typeof value === "object" &&
              "_isSet" in value &&
              "values" in value &&
              Array.isArray(serialized.values)
            ) {
              // Create a Set with the standard methods
              const set = new Set<unknown>(serialized.values);
              // Ensure the Set has the required methods
              if (!set.intersection || !set.difference) {
                Object.setPrototypeOf(set, Set.prototype);
              }
              return set;
            }
            return value;
          },
        },
      });
    }

    const store = isDevelopment
      ? zustandCreate<T>()(
          devtools(initializer, {
            name: storeName,
            enabled: true,
            anonymousActionType: storeName,
          }),
        )
      : zustandCreate<T>()(initializer);

    // Register store for cleanup
    registerStoreForCleanup(store, storeName);

    return store;
  };
};

// Re-export middleware from Zustand for convenience
export { persist, createJSONStorage };

// Re-export types from Zustand for convenience
export type { StateCreator };

// Flag to prevent multiple clearing operations
let isClearing = false;

/**
 * Resets all application stores to their initial state
 * @param redirectUrl Optional URL to redirect to after clearing stores
 */
export const clearAllStores = (redirectUrl?: string) => {
  // Prevent multiple clearing operations
  if (isClearing) {
    return;
  }

  isClearing = true;

  try {
    // Reset all registered stores to their initial states
    for (const store of storeRegistry.values()) {
      try {
        const initialState = store.getInitialState();
        store.setState(initialState, true, "[Auth]: Clear all stores");
      } catch (error: unknown) {
        console.error("Error resetting store:", error);
      }
    }

    // Clear all browser storage
    window.localStorage.clear();
    window.sessionStorage.clear();

    // Clear any cached credentials
    if (window.navigator?.credentials) {
      navigator.credentials.preventSilentAccess();
    }

    // Clear the registry to allow fresh registrations
    storeRegistry.clear();

    // Only redirect if URL is provided
    if (redirectUrl) {
      // Add cache busting parameter
      const url = new URL(redirectUrl, window.location.origin);
      url.searchParams.set("t", Date.now().toString());
      window.location.href = url.toString();
    }
  } finally {
    isClearing = false;
  }
};
