// import { StoryClusterInsightModal } from "@/components/news/story-cluster-insight-modal";
import { cn } from "@/lib/utils";
// import { FilterDisplayNames } from "@/store/news/constants";
import type { DerivedFilterItem } from "@/store/news/feed.slice";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import AddNewFilter from "../filters/AddNewFilter";
import FilterHeader from "../filters/FilterHeader";
import SearchBar from "../filters/FilterSearchBar";
import ShowMoreButton from "../filters/ShowMoreButton";
// import { StoryClusterMenu } from "../filters/StoryClusterMenu";
import { useFilterSorting } from "../hooks/useFilterSorting";
import { useFilterState } from "../hooks/useFilterState";
import { useFilterVisibility } from "../hooks/useFilterVisibility";
import FilterList from "./FilterList";

interface FilterContainerProps {
  filterKey: string;
  title: string;
  filters: DerivedFilterItem[];
  filterByTags: (tags: string[]) => void;
  loading: boolean;
  sorted: boolean;
  editKnownTag?: (tags: string[]) => void;
  handleEditClick?: (tier: string) => Promise<void>;
}

function FilterContainer({
  filterKey,
  title,
  filters,
  filterByTags,
  loading,
  sorted,
  editKnownTag,
  handleEditClick,
}: FilterContainerProps) {
  const {
    searchTerm,
    isOpen,
    showMore,
    displayAddTopic,
    isTransitioning,
    handleFilterChange,
    handleSearchChange,
    handleClearSearch,
    toggleOpen,
    toggleShowMore,
    toggleAddTopic,
    setIsTransitioning,
  } = useFilterState(filters, filterByTags, filterKey);

  const contentRef = useRef<HTMLDivElement>(null);

  const filteredFilters = useFilterSorting(filters, searchTerm, sorted, title);

  const { needsShowMore, showSearch: shouldShowSearch } = useFilterVisibility(
    filteredFilters.length,
    filters.length,
    !!searchTerm,
  );

  const addEditFilter = useCallback(
    (updatedValue: string, filterId?: string) => {
      if (editKnownTag) {
        const updatedFilters = filterId
          ? filters.map((fil, index) => {
              if (String(index) === filterId) return updatedValue;
              return fil.value;
            })
          : [...filters.map((fil) => fil.value), updatedValue];

        editKnownTag(updatedFilters);
      }
    },
    [editKnownTag, filters],
  );

  // Handle generating a story from a cluster
  // const handleGenerateStory = useCallback(() => {
  //   // Here you would implement the logic to generate a story from the cluster
  //   // This might involve calling an API, opening a modal, etc.
  //   console.log("Generate AI Summary from cluster:");
  //   setIsAISummaryModalOpen(true);
  // }, []);

  const [contentHeight, setContentHeight] = useState<number>(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen, filters, showMore, filteredFilters, loading, searchTerm]);

  return (
    <div className="w-full pl-2 pt-2 pb-1 relative">
      {/* Use StoryClustersHeader for Story Clusters, FilterHeader for other filters */}

      <FilterHeader
        title={title}
        isOpen={isOpen}
        onToggle={() => {
          setIsTransitioning(true);
          toggleOpen();
        }}
        length={loading ? 0 : filters.length}
        loading={loading}
      />

      <div
        className={cn(
          "transition-[height,opacity] duration-200 -mx-1.5 ease-in-out overflow-hidden contain-layout will-change-[height,opacity] backface-visibility-hidden",
          isOpen && !isTransitioning ? "opacity-100" : "opacity-0",
        )}
        style={{
          height: isOpen ? `${contentHeight}px` : "0px",
        }}
        onTransitionEnd={() => {
          setIsTransitioning(false);
        }}
      >
        <div ref={contentRef}>
          {shouldShowSearch && !loading && (
            <div className="sticky top-0 bg-white z-10 contain-layout">
              <SearchBar
                searchTerm={searchTerm}
                title={title}
                onSearchChange={handleSearchChange}
                onClearSearch={handleClearSearch}
                isOpen={isOpen}
              />
            </div>
          )}

          <div
            className={cn(
              showMore ? "overflow-y-auto" : "overflow-hidden",
              "contain-layout will-change-transform backface-visibility-hidden",
              "transform-gpu",
            )}
          >
            {loading ? (
              <>
                {/* {Array.from({ length: filters.length || 3 }).map((_, index) => (
                  <Skeleton
                    // biome-ignore lint/suspicious/noArrayIndexKey: <this is fine>
                    key={index}
                    className={cn(
                      "h-4 w-[calc(100%-8px)] bg-gray-200/45 ml-2 ",
                      index === 0 ? "mt-1" : "mt-2.5",
                    )}
                  />
                ))} */}
              </>
            ) : (
              <>
                {isOpen && (
                  <FilterList
                    items={filteredFilters}
                    isOpen={isOpen}
                    handleFilterChange={handleFilterChange}
                    handleEditClick={handleEditClick}
                    editKnownTag={editKnownTag}
                    filters={filters}
                    addEditFilter={addEditFilter}
                    showMore={showMore}
                  />
                )}

                {needsShowMore && isOpen && (
                  <ShowMoreButton
                    showMore={showMore}
                    onToggle={toggleShowMore}
                  />
                )}

                {displayAddTopic && editKnownTag && (
                  <AddNewFilter
                    onConfirm={(newValue) => {
                      toggleAddTopic();
                      addEditFilter(newValue);
                    }}
                    onCancel={toggleAddTopic}
                  />
                )}

                {filteredFilters.length === 0 && searchTerm && (
                  <p className="text-sm text-slate-500 pl-2">
                    {`No ${title.toLowerCase()} found`}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(FilterContainer);
