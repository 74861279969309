import { graphql } from "@/data-access/graphql";

export const CreateKeyMessageGroup = graphql(`
    mutation createKeyMessageGroup($input: CreateKeyMessageGroupInput!) {
        createKeyMessageGroup(input: $input) {
            ... on KeyMessageGroupType {
                id
                name
            }
        }
    }
`);

export const CreateKeyMessage = graphql(`
    mutation createKeyMessage($input: CreateKeyMessageItemInput!) {
        createKeyMessageItem(input: $input) {
            ... on KeyMessageItemType {
                id
                name
                group {
                    id
                    name
                }
                searchTerms
                feedArticleFilters
                feedArticleIds
                total
            }
        }
    }
`);

// newly added query
export const GetKeyMessagesAndGroups = graphql(`
    query getKeyMessageAndGroups($filters: FeedArticleFilter!) {
        keyMessageGroups(filters: $filters) {
            id
            name
            keyMessageItems {
                id
                name
                group {
                    id
                    name
                }
                searchTerms
                feedArticleIds
                feedArticleFilters
                total
            }
        }
    }
`);

export const GetKeyMessages = graphql(`
    query getKeyMessages($filters: FeedArticleFilter!) {
        keyMessageItems(filters: $filters) {
            id
            name
            group {
                id
                name
            }
            searchTerms
            feedArticleFilters
            feedArticleIds
            total
        }
    }
`);

export const UpdateKeyMessageGroup = graphql(`
    mutation updateKeyMessageGroup($input: UpdateKeyMessageGroupInput!) {
        updateKeyMessageGroup(input: $input) {
            ... on KeyMessageGroupType {
                id
                name
            }
        }
    }
`);

export const UpdateKeyMessage = graphql(`
    mutation updateKeyMessage($input: UpdateKeyMessageItemInput!) {
        updateKeyMessageItem(input: $input) {
            ... on KeyMessageItemType {
                id
                feedId
                name
                searchTerms
                feedArticleFilters
                feedArticleIds
                total
                group {
                    id
                    name
                }
            }
        }
    }
`);

export type CreateKeyMessageGroupVariablesType = Parameters<
  Exclude<typeof CreateKeyMessageGroup.__apiType, undefined>
>[0];

export type CreateKeyMessageVariablesType = Parameters<
  Exclude<typeof CreateKeyMessage.__apiType, undefined>
>[0];

export type GetKeyMessagesAndGroupsVariablesType = Parameters<
  Exclude<typeof GetKeyMessagesAndGroups.__apiType, undefined>
>[0];

export type GetKeyMessagesVariablesType = Parameters<
  Exclude<typeof GetKeyMessages.__apiType, undefined>
>[0];

// export type GetKeyMessagesResponseType = ReturnType<
//   Exclude<typeof GetKeyMessages.__apiType, undefined>
// >;

export type UpdateKeyMessageGroupVariablesType = Parameters<
  Exclude<typeof UpdateKeyMessageGroup.__apiType, undefined>
>[0];

export type UpdateKeyMessageVariablesType = Parameters<
  Exclude<typeof UpdateKeyMessage.__apiType, undefined>
>[0];
