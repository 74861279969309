import type React from "react";
import { useEffect, useState } from "react";

interface GooglyEyesLogoProps {
  className?: string;
}

export const GooglyEyesLogo: React.FC<GooglyEyesLogoProps> = ({
  className,
}) => {
  const [eyePosition, setEyePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Generate smooth eye movements within a larger range
      const randomX = Math.sin(Date.now() / 500) * 5; // Reduced range
      const randomY = Math.cos(Date.now() / 750) * 2; // Reduced range
      setEyePosition({ x: randomX, y: randomY });
    }, 100); // Update eye position more frequently

    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []);

  return (
    <svg
      width="480" // Increased size
      height="477" // Increased size
      viewBox="0 0 360 358"
      fill="currentColor"
      stroke="black" // Added border
      strokeWidth="4" // Added border width
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={`fill-slate-900 ${className}`}
      aria-label="Handraise with Googly Eyes"
    >
      {/* Main Handraise Logo Shape */}
      <path
        d="M293.416 71.7521V287.054C293.416 326.03 302.612 333.475 335.457 336.541V352.306H195.756V336.541C229.039 333.475 236.484 325.592 236.484 287.054V203.846H98.9731V287.054C98.9731 326.468 107.732 333.475 139.701 336.541V352.306H0V336.541C34.1588 333.475 42.4796 325.154 42.4796 287.054V120.754C42.4796 81.778 33.283 74.3331 0 71.2676V55.5019H139.701V71.2676C106.856 74.3331 98.9731 82.2159 98.9731 120.754V182.825H236.484V71.7521C236.484 32.3381 228.164 18.8312 195.756 15.7656V0.000181838L335.457 0V15.7656C301.737 18.8312 293.416 33.6519 293.416 71.7521Z"
        fill="currentColor"
        stroke="black"
        strokeWidth="4"
      />
      {/* Googly Eyes - Oval Shapes with Eyebrows */}
      <ellipse cx="120" cy="120" rx="48" ry="36" fill="white" />{" "}
      {/* Increased eye size, moved lower */}
      <ellipse cx="240" cy="120" rx="48" ry="36" fill="white" />{" "}
      {/* Increased eye size, moved lower */}
      <ellipse
        cx={120 + eyePosition.x}
        cy={120 + eyePosition.y}
        rx="24"
        ry="18"
        fill="black"
      />{" "}
      {/* Increased pupil size */}
      <ellipse
        cx={240 + eyePosition.x}
        cy={120 + eyePosition.y}
        rx="24"
        ry="18"
        fill="black"
      />{" "}
      {/* Pupil */}
      {/* Eyebrows */}
      <path
        d="M90 100 C 110 85, 130 85, 150 100"
        stroke="black"
        strokeWidth="4"
        fill="none"
      />{" "}
      {/* Improved eyebrow */}
      <path
        d="M210 100 C 230 85, 250 85, 270 100"
        stroke="black"
        strokeWidth="4"
        fill="none"
      />{" "}
      {/* Improved eyebrow */}
    </svg>
  );
};
