import FeedVolumeGraphHeader from "@/components/news/feed/FeedVolumeGraph/FeedVolumeGraphHeader";
import type { BarChartEventProps } from "@/components/ui/bar-chart/BarChart.types";
import BarChartWithStates from "@/components/ui/bar-chart/BarChartWithStates";
import { cn } from "@/lib/utils";
import { addCommasToNumbers } from "@/lib/utils/prettyName";
import {
  setFeedVolumeSelectedDate,
  setPeriod,
  setSelectedStackIds,
} from "@/store/news/feed.actions";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import FeedVolumeGraphTooltip from "./FeedVolumeGraphTooltip";
import {
  type StackByKey,
  getColorsForStackBy,
  getFormatterForPeriod,
  useGetFeedVolume,
} from "./useFeedVolumeGraphData";

const FeedVolumeGraph = () => {
  const {
    feedVolume,
    fetching: isFetchingFeedVolume,
    stackByLabels,
  } = useGetFeedVolume();

  const setFeedVolume = useFeedStore((state) => state.setFeedVolume);
  const fetchingFilters = useFeedStore((state) => state.fetchingFilters);

  const isLoading = isFetchingFeedVolume || fetchingFilters;
  const stackFeedVolumeBy = useFeedStore((state) => state.stackFeedVolumeBy);
  const setStackFeedVolumeBy = useFeedStore(
    (state) => state.setStackFeedVolumeBy,
  );
  const period = useFeedParamsStore((state) => state.period);
  const filterDays = useFeedParamsStore((state) => state.filterDays);

  const activeBar = useFeedStore((state) => state.activeBar);
  const setActiveBar = useFeedStore((state) => state.setActiveBar);

  const resetView = () => {
    setFeedVolume(null);
    setFeedVolumeSelectedDate(null);
    setActiveBar(undefined);
    setSelectedStackIds([]);
  };

  const stackBy = stackFeedVolumeBy
    ? stackFeedVolumeBy === "sentiment"
      ? ["Negative", "Neutral", "Positive"]
      : stackByLabels.toReversed()
    : ["volume"];

  const handleBarClick = (v: BarChartEventProps) => {
    const { eventType, categoryClicked, ...rest } = v || {};

    setFeedVolume(v && typeof v.volume === "number" ? v.volume : null);
    setActiveBar(v ? rest : undefined);
    setFeedVolumeSelectedDate(
      v
        ? {
            startDate:
              typeof v.startOfPeriod === "string" ? v.startOfPeriod : "",
            endDate: typeof v.endOfPeriod === "string" ? v.endOfPeriod : "",
          }
        : null,
    );

    if (stackFeedVolumeBy) {
      const stackIds = v?.[`${categoryClicked}-Ids`];
      setSelectedStackIds(
        Array.isArray(stackIds) &&
          stackIds.every((id) => typeof id === "number")
          ? stackIds
          : [],
      );
    }
  };

  return (
    <div
      className={cn(
        "lg:max-w-[932px] lg:mx-auto flex flex-col gap-9 pt-2 transition-all duration-300 ease-in-out",
      )}
    >
      <div className="flex justify-end">
        <FeedVolumeGraphHeader
          period={period}
          resetView={resetView}
          setPeriod={setPeriod}
          stackFeedVolumeBy={stackFeedVolumeBy}
          setStackFeedVolumeBy={setStackFeedVolumeBy}
        />
      </div>
      <BarChartWithStates
        key={stackFeedVolumeBy ?? "default"}
        className="h-80 transition-all duration-300 ease-in-out"
        isLoading={isLoading}
        data={feedVolume}
        index={"startOfPeriod"}
        categories={stackBy}
        onValueChange={(v) => handleBarClick(v)}
        colors={getColorsForStackBy(
          stackFeedVolumeBy as StackByKey,
        )?.toReversed()}
        borderRadius={2}
        showLegend={!!stackFeedVolumeBy}
        intervalType={filterDays === 90 && period === "day" ? 3 : 0}
        formatXAxisTick={(_v, index) => {
          const item = feedVolume?.[index];

          return getFormatterForPeriod(period, {
            startDate:
              typeof item?.startOfPeriod === "string" ? item.startOfPeriod : "",
            endDate:
              typeof item?.endOfPeriod === "string" ? item.endOfPeriod : "",
          });
        }}
        valueFormatter={(v) => (v ? addCommasToNumbers(v) : "0")}
        rotateXAxisTick={feedVolume?.length > 12}
        defaultActiveBar={activeBar}
        mode={stackFeedVolumeBy ? "solid" : "gradient"}
        type={stackFeedVolumeBy ? "stacked" : "default"}
        customTooltip={(props) => {
          return (
            <FeedVolumeGraphTooltip
              {...props}
              stackedBy={stackFeedVolumeBy}
              label={props.label}
              period={period}
            />
          );
        }}
      />
    </div>
  );
};

export default FeedVolumeGraph;
