import { graphql } from "../graphql";

const tierFragment = graphql(`
  fragment Tier on TierConfigurationType {
    tier
    lowerBound
    upperBound
  }
`);

export const tierConfigurationFragment = graphql(
  `
    fragment ReadershipTierConfig on TierConfigurationResponseType {
      __typename
      tiers {
        ...Tier
      }
    }
  `,
  [tierFragment],
);

export const CREATE_OR_UPDATE_TIERING_CONFIGURATION = graphql(
  `
    mutation CreateOrUpdateTieringConfiguration(
      $input: TenantTierConfigInput!
    ) {
      createOrUpdateTieringConfiguration(input: $input) {
        ... on TenantTierConfigType {
          __typename
          factor
          tier1LowerBound
          tier2LowerBound
        }
      }
    }
  `,
);

export const UPDATE_PUBLISHERS_TIERING_OVERRIDE = graphql(`
  mutation UpdatePublishersTieringOverride($publishers: [Int!]!, $tier: TierChoices!) {
    updatePublishersTieringOverride(publishers: $publishers, tier: $tier) {
      ... on SuccessResponse {
        __typename
        message
      }
      ... on OperationInfo {
        __typename
        messages {
          code
          message
        }
      }
    }
  }
`);

export const TIERING_CONFIGURATION_QUERY = graphql(`
  query TieringConfiguration {
    tieringConfiguration {
      factor
      tenant {
        website
        defaultTierFactor
        enableCustomTierScoring
        enableReadership
        name
        slug
        users {
          pk
        }
        id
      }
      tier1LowerBound
      tier2LowerBound
    }
  }
`);

export const PUBLISHER_TIERING_OVERRIDES_QUERY = graphql(`
  query PublisherTieringOverrides($overrideTier: TierChoices!) {
    publisherTieringOverrides(overrideTier: $overrideTier) {
      override
      tier
      publisher {
        logoUrl
        id
        domain
        name
        url
        logos {
          image {
            url
          }
        }
      }
    }
  }
`);

export const TOGGLE_TENANT_TIER_FACTOR = graphql(`
  mutation ToggleTenantTierFactor($factor: TierFactorChoices!) {
    toggleTenantTierFactor(factor: $factor) {
      ... on TenantType {
        id
        name
        defaultTierFactor
      }
    }
  }
`);

export const UPSERT_READERSHIP_CONFIGURATION = graphql(
  `
    mutation UpsertReadershipConfiguration($input: TierConfigurationInput!) {
      upsertReadershipConfiguration(input: $input) {
        ... on TierConfigurationResponseType {
          ...ReadershipTierConfig
        }
      }
    }
  `,
  [tierConfigurationFragment],
);

export type PublisherConfigurationType = ReturnType<
  Exclude<typeof PUBLISHER_TIERING_OVERRIDES_QUERY.__apiType, undefined>
>;

export type PublisherType = NonNullable<
  Exclude<
    ReturnType<NonNullable<typeof PUBLISHER_TIERING_OVERRIDES_QUERY.__apiType>>,
    undefined
  >["publisherTieringOverrides"]
>[number]["publisher"];
