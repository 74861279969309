/** Create key message with advanced boolean and see related articles*/
// noinspection DuplicatedCode

import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
} from "@/components/ui/dialog";
import Checkbox from "@/components/ui/forms/checkbox";
import { JollyTextField } from "@/components/ui/textfield";
import BaseEditor from "@/components/ui/tiptap-editor/TiptapEditor.tsx";
import {
  createKeyMessage,
  updateKeyMessage,
} from "@/store/key-messages/key-message-async.actions";
import {
  setCreateKeyMessageGroupModalOpen,
  setEditingKeyMessageId,
  setIsEditingKeyMessage,
  setKeyMessageName,
  setSelectedGroup,
} from "@/store/key-messages/key-message.actions";
import { getSelectedFilters } from "@/store/key-messages/key-message.selectors";
import useKeyMessageStore from "@/store/key-messages/key-message.slice";
import type { FilterData } from "@/store/news/feed.slice.ts";
import useFeedStore from "@/store/useFeedStore";
import { Funnel } from "@phosphor-icons/react";
import type { Editor } from "@tiptap/react";
import { useRef } from "react";
import { GroupDropdown } from "./SaveSearchAsKeyMessageModal.tsx";

interface CreateKeyMessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  filterData: FilterData[];
}

const FilterHeader = ({ filterData }: { filterData: FilterData[] }) => {
  const appliedFilters = filterData.filter(
    ({ selectedOptions }) => selectedOptions && selectedOptions.length > 0,
  );

  const totalSelectedOptions = appliedFilters.reduce(
    (sum, { selectedOptions }) => sum + selectedOptions.length,
    0,
  );

  return (
    <div
      className={"flex text-xs text-gray-600 items-center tracking-wide mb-4"}
    >
      <Funnel className={"text-gray-600"} />
      <span className={"mr-1"}>({totalSelectedOptions})</span>
      {appliedFilters.map(({ title, selectedOptions }) => (
        <div key={title} className="flex mx-1.5 font-normal">
          <span>{title}:</span>
          <span className="font-medium ml-0.5">
            {selectedOptions.length > 3
              ? selectedOptions.length
              : selectedOptions.join(", ")}
          </span>
        </div>
      ))}
    </div>
  );
};

const CreateKeyMessageModal = ({
  isOpen,
  onClose,
  filterData,
}: CreateKeyMessageModalProps) => {
  const keyMessageName = useKeyMessageStore((state) => state.keyMessageName);

  const selectedGroup = useKeyMessageStore((state) => state.selectedGroup);

  const expandedSearchTerm = useKeyMessageStore(
    (state) => state.expandedSearchTerm,
  );
  const setExpandedSearchTerm = useKeyMessageStore(
    (state) => state.setExpandedSearchTerm,
  );

  const isNestedInaGroup = Boolean(selectedGroup?.id);

  const editorRef = useRef<Editor | null>(null);

  /** set up for save input */
  const feedId = useFeedStore((state) => state.feedId);

  const isCreatingKeyMessage = useKeyMessageStore(
    (state) => state.isCreatingKeyMessage,
  );
  const isEditingKeyMessage = useKeyMessageStore(
    (state) => state.isEditingKeyMessage,
  );
  const editingKeyMessageId = useKeyMessageStore(
    (state) => state.editingKeyMessageId,
  );

  const handleCancel = () => {
    onClose();
    setKeyMessageName("");
    setCreateKeyMessageGroupModalOpen(false);
    setExpandedSearchTerm("");
    setSelectedGroup(null);
    setEditingKeyMessageId(null);
    setIsEditingKeyMessage(false);
  };

  const handleSave = () => {
    const input = {
      feedId: feedId?.toString() as string,
      searchTerms: expandedSearchTerm,
      name: keyMessageName,
      groupId: selectedGroup?.id ?? null,
      selectedFilters: getSelectedFilters(filterData),
    };

    createKeyMessage(input).then(() => handleCancel());
  };

  // const handleSearch = () => {
  //   if (editorRef.current) {
  //     const plainText = editorRef.current.getText();
  //     setExpandedSearchTerm(plainText);
  //     console.log(plainText);
  //     // fetch feed items
  //   }
  // };

  const handleCheckboxChange = () => {
    if (isNestedInaGroup) {
      // Uncheck and clear selectedGroup
      setSelectedGroup(null);
    }
  };

  const handleUpdateKeyMessage = () => {
    if (!editingKeyMessageId) return;

    const input = {
      feedId: feedId?.toString() as string,
      groupId: selectedGroup?.id ?? null,
      itemId: editingKeyMessageId,
      name: keyMessageName,
      searchTerms: expandedSearchTerm,
      selectedFilters: getSelectedFilters(filterData),
    };

    updateKeyMessage(input).then(() => handleCancel());
  };

  return (
    <DialogOverlay isOpen={isOpen}>
      <DialogContent onClose={handleCancel} size="lg">
        <DialogHeader title="New Key Message" />
        <DialogBody>
          <FilterHeader filterData={filterData} />
          <JollyTextField
            value={keyMessageName}
            onChange={(e) => setKeyMessageName(e)}
            placeholder={"Add a name..."}
            required={true}
            label={"Key Message name"}
            size={"sm"}
            labelVariant={"secondary"}
            autoFocus
          />
          <div className={"mt-5 mb-3.5"}>
            <Checkbox
              onCheckedChange={handleCheckboxChange}
              checked={isNestedInaGroup}
            >
              <span className={"text-xs"}>Nest under group:</span>
            </Checkbox>
          </div>
          <GroupDropdown
            selectedGroup={selectedGroup}
            setSelectedGroup={(group) => {
              setSelectedGroup(group);
            }}
          />
          <div className={"flex flex-col mt-5"}>
            <span
              className={"text-xs text-gray-700 tracking-wide mb-2 font-medium"}
            >
              Search terms*
            </span>
            <BaseEditor
              ref={editorRef}
              placeholder={"Add search terms..."}
              content={expandedSearchTerm}
              className={"min-h-24 mb-2"}
              onUpdate={(editor) => setExpandedSearchTerm(editor.getText())}
            />
            {/*hiding this for now*/}
            {/*<Button*/}
            {/*  variant={"outline"}*/}
            {/*  className={"ml-auto"}*/}
            {/*  onPressChange={handleSearch}*/}
            {/*  size={"sm"}*/}
            {/*>*/}
            {/*  <MagnifyingGlass size={13} className={"text-gray-600"} />*/}
            {/*  <span className={"ml-2 text-xs"}>Search</span>*/}
            {/*</Button>*/}
          </div>
        </DialogBody>
        <DialogFooter
          primaryAction={
            isEditingKeyMessage ? handleUpdateKeyMessage : handleSave
          }
          secondaryAction={handleCancel}
          primaryLabel={"Create key message"}
          secondaryLabel={"Cancel"}
          primaryDisabled={Boolean(
            !keyMessageName.length || !expandedSearchTerm.length,
          )}
          isPending={isCreatingKeyMessage}
        />
      </DialogContent>
    </DialogOverlay>
  );
};
export default CreateKeyMessageModal;
