import { Button } from "@/components/ui/button";
import IconButton from "@/components/ui/icon-button";
import { MenuTrigger } from "@/components/ui/menu";
import { cn } from "@/lib/utils";
import { CaretDown, Plus } from "@phosphor-icons/react";
import { type ReactNode, memo } from "react";

interface FilterHeaderProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  length: number;
  loading: boolean;
  children?: ReactNode;
}

const filtersWithCount = [
  "Publications",
  "Themes",
  "People",
  "Authors",
  "Initiatives",
  "Events",
  "Topics",
] as const;

const containerStyles = cn(
  "text-sm text-gray-700 leading-none",
  "text-left",
  "contain-layout",
  "w-full",
  "ring-offset-0",
  "hidden",
  "md:inline-flex items-center justify-between", // Added justify-between
);

const titleStyles = cn("overflow-hidden text-ellipsis whitespace-nowrap");

const titleTextStyles = "font-medium text-xs";
const countStyles = "font-normal text-xs";

const FilterHeader = memo(
  ({
    title,
    isOpen,
    onToggle,
    length,
    loading,
    children,
  }: FilterHeaderProps) => {
    const getTitleAndCount = () => {
      if (
        !filtersWithCount.includes(title as (typeof filtersWithCount)[number])
      ) {
        return <span className={titleTextStyles}>{title}</span>;
      }
      return (
        <>
          <span className={titleTextStyles}>{title}</span>
          {!loading && <span className={countStyles}>{` (${length})`}</span>}
        </>
      );
    };

    return (
      <div className={"relative inline-flex w-full"}>
        <Button
          onPress={onToggle}
          className={containerStyles}
          variant="none"
          size="label"
        >
          <span className={titleStyles}>{getTitleAndCount()}</span>

          <CaretDown
            size={14}
            className={
              isOpen
                ? "rotate-180 transition-transform transform-gpu will-change-transform"
                : "transition-transform transform-gpu will-change-transform"
            }
          />
        </Button>
        {children && (
          <MenuTrigger>
            <IconButton
              icon={<Plus className={"text-gray-600"} />}
              aria-label={`Add ${title}`}
              variant={"ghost"}
              className={"absolute right-6 top-1/2 -translate-y-1/2"}
              tooltipLabel={"Add Key Message or Group"}
              size={"sm"}
            />
            {children}
          </MenuTrigger>
        )}
      </div>
    );
  },
);

FilterHeader.displayName = "FilterHeader";

export default FilterHeader;
