import toast from "@/components/ui/toast";
import { FeedArticlesQuery, deleteFeedMutation } from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import { CombinedError } from "urql";
import useFeedStore, { useFeedParamsStore } from "../useFeedStore";
import useArticleDeletionStore from "./articleManagement.slice";
import useFeedPanelStore from "./feedPanel.slice";
import {
  fetchFilters,
  filterBySearchTerms,
  getDateParameters,
} from "./filterSearch.actions";
import { getSortParams, handleFeedItems, mapNodeToFeedItem } from "./shared";

const ARTICLES_PER_PAGE = 6;

const navigate = (path: string) => {
  window.history.pushState({}, "", path);
  // Trigger a popstate event so React Router can handle the navigation
  window.dispatchEvent(new PopStateEvent("popstate"));
};

const navigateToNewsfeeds = () => {
  navigate("/newsfeeds");
};

// Helper function to get reset filters
function getResetFilters() {
  const { categoryAppliedFilters } = useFeedStore.getState();
  return Object.entries(categoryAppliedFilters).reduce(
    (acc, [key, filter]) => {
      acc[key] = {
        ...filter,
        itemCounts: filter.itemCounts ? {} : undefined,
      };
      return acc;
    },
    {} as typeof categoryAppliedFilters,
  );
}

function resetFeedState(feedId: number | undefined, reason: string) {
  const resetFilters = getResetFilters();

  useFeedStore.setState(
    {
      feedId,
      hasNextPage: false,
      cursor: null,
      feedItems: [],
      filterFeedIdSearch: [],
      fetchingFilters: true,
      fetchingFeed: true,
      createOrEditFeedState: "initial",
      categoryAppliedFilters: resetFilters,
      filteredFeedIds: new Set(),
      allFeedIds: new Set(),
    },
    false,
    reason,
  );
}

export function resetFeed() {
  resetFeedState(undefined, "[User] Feed: Selected overview");
}

export function setFeedId(feedId: number | null) {
  const { feedId: existingFeed } = useFeedStore.getState();
  const feedChanged = feedId !== existingFeed;

  const { unsubscribeFetchFilters, unsubscribeFetchDynamicFilters } =
    useFeedStore.getState();

  if (!feedId || feedChanged) {
    unsubscribeFetchFilters?.();
    unsubscribeFetchDynamicFilters?.();
  }

  if (feedChanged) {
    resetFeedState(feedId ?? undefined, `[User] Feed: Selected #${feedId}`);
    // Reset feed params manual to only update the search terms
    useFeedParamsStore.setState(
      {
        searchTerms: "",
      },
      false,
      "[Effect]: Reset search terms",
    );

    // Reset stores to initial state
    useArticleDeletionStore.setState(
      useArticleDeletionStore.getInitialState(),
      false,
      "[Effect]: Reset article deletion state",
    );

    useFeedPanelStore.setState(
      useFeedPanelStore.getInitialState(),
      false,
      "[Effect]: Reset feed panel state",
    );
  }

  if (!feedId) return;

  useFeedStore.setState(
    {
      totalCount: undefined,
    },
    false,
    "[Effect] Feed: Set to paged mode",
  );

  fetchFilters(feedId);
}

export const updatePagedFeed = () => {
  const { feedId } = useFeedStore.getState();

  if (!feedId) return;

  useFeedStore.setState(
    {
      feedId,
      hasNextPage: false,
      cursor: null,
      feedItems: [],
      fetchingFeed: true,
      continueFetchingFeed: false,
    },
    false,
    "[User] Feed: updated filters for page feed refetch",
  );

  fetchFeed(feedId);
};

let currentFeedController: AbortController | null = null;

const UNAUTHORIZED_MESSAGE = "You are not authorized to view this feed.";

/**
 * Helper function to handle unauthorized feed access errors.
 * Shows a toast notification and redirects to the newsfeeds overview page.
 */
const handleUnauthorizedFeedError = (error: unknown): boolean => {
  // Check GraphQL errors in the response
  if (error instanceof CombinedError) {
    const isUnauthorized = error.graphQLErrors.some(
      (e) => e.message === UNAUTHORIZED_MESSAGE,
    );
    if (isUnauthorized) {
      navigateToNewsfeeds();
      return true;
    }
  }

  // Check for regular Error objects
  let errorMessage = "";
  if (error instanceof Error) {
    errorMessage = error.message;
  }

  const hasUnauthorizedMessage = errorMessage.includes(UNAUTHORIZED_MESSAGE);

  if (hasUnauthorizedMessage) {
    navigateToNewsfeeds();
    return true;
  }
  return false;
};

const fetchFeed = async (feedId: number) => {
  // Cancel any ongoing requests
  if (currentFeedController) {
    currentFeedController.abort();
  }
  currentFeedController = new AbortController();
  const controller = currentFeedController;

  const { startDateFormatted: startDate, endDateFormatted: endDate } =
    getDateParameters();

  const sortOrder = useFeedParamsStore.getState().sortOrder;

  const { scoreSort: score, dateSort: datePublished } =
    getSortParams(sortOrder);
  const ids = getIdsForPaged();

  try {
    const [feedResult] = await Promise.all([
      client
        .query(
          FeedArticlesQuery,
          {
            feedId,
            startDate,
            endDate,
            after: "",
            first: ARTICLES_PER_PAGE,
            score,
            datePublished,
            ids,
          },
          { fetchOptions: { signal: controller.signal } },
        )
        .toPromise(),
    ]);

    // Check if this request was aborted or if feed changed
    if (
      controller.signal.aborted ||
      feedId !== useFeedStore.getState().feedId
    ) {
      return;
    }

    // Check for unauthorized error in the response
    if (feedResult.error) {
      if (handleUnauthorizedFeedError(feedResult.error)) return;
    }

    if (!feedResult.data?.feedArticles) return;

    const { edges, pageInfo } = feedResult.data.feedArticles;
    const feedItems = edges?.map(({ node }) => mapNodeToFeedItem(node)) ?? [];
    handleFeedItems(feedItems, feedId, pageInfo);
  } catch (error: unknown) {
    // Ignore aborted request errors
    if (error instanceof Error && error.name === "AbortError") return;

    // Handle unauthorized feed error
    if (handleUnauthorizedFeedError(error)) return;

    console.error("Error processing feed data:", error);
    toast.error("Failed to process feed data");
  }
};

export const deleteFeed = async () => {
  const feedId = useFeedStore.getState().feedId;

  if (!feedId) return;

  client
    .mutation(deleteFeedMutation, {
      feedId: String(feedId),
      accepted: true,
    })
    .subscribe((res) => {
      if (res.data?.deleteFeed.__typename === "SuccessResponse" && !res.error) {
        navigateToNewsfeeds();
      }
    });
};

export function openDeleteFeedModal() {
  useFeedStore.setState({ isDeleteFeedModalOpen: true });
}

export function closeDeleteFeedModal() {
  useFeedStore.setState({ isDeleteFeedModalOpen: false });
}

export const fetchNextPage = async () => {
  const { feedId, cursor, fetchingFeed, continueFetchingFeed } =
    useFeedStore.getState();
  const { sortOrder } = useFeedParamsStore.getState();

  if (!feedId || fetchingFeed || continueFetchingFeed || !cursor) return;

  const { startDateFormatted: startDate, endDateFormatted: endDate } =
    getDateParameters();

  useFeedStore.setState(
    { continueFetchingFeed: true },
    false,
    "[User] Feed: Scrolled to fetch next page",
  );

  try {
    const { scoreSort: score, dateSort: datePublished } =
      getSortParams(sortOrder);
    const ids = getIdsForPaged();

    const feedItemsQuery = client.query(FeedArticlesQuery, {
      feedId,
      startDate,
      endDate,
      after: cursor,
      first: ARTICLES_PER_PAGE,
      score,
      datePublished,
      ids,
    });
    const [feedResult] = await Promise.all([feedItemsQuery.toPromise()]);

    // Check for unauthorized error in the response
    if (feedResult.error) {
      if (handleUnauthorizedFeedError(feedResult.error)) return;
    }

    if (!feedResult.data?.feedArticles) return;
    const { edges, pageInfo } = feedResult.data.feedArticles;
    const newItems = edges?.map(({ node }) => mapNodeToFeedItem(node)) ?? [];
    handleFeedItems(newItems, feedId, pageInfo, true);

    useFeedStore.setState(
      { continueFetchingFeed: false },
      false,
      "[API] Feed: Loading next items",
    );
  } catch (error: unknown) {
    // Handle unauthorized feed error
    if (handleUnauthorizedFeedError(error)) return;

    // Handle other errors
    console.error("Error fetching next page:", error);
    useFeedStore.setState(
      { continueFetchingFeed: false },
      false,
      "[System] Feed: Next page failed",
    );
  }
};

export const updateDateRange = (
  days?: number,
  startDate?: Date,
  endDate?: Date,
  isCustomDaysRange?: boolean,
) => {
  useFeedParamsStore.setState(
    {
      filterDays: days,
      startDate: startDate,
      endDate: endDate,
      isCustomDaysRange,
    },
    false,
    "[User] Date: Updated range",
  );

  setFeedId(useFeedStore.getState().feedId);
};

export const setFeedVolumeSelectedDate = (
  dateRange: { startDate: string; endDate: string } | null,
) => {
  useFeedStore.setState(
    {
      feedVolumeSelectedDate: dateRange,
    },
    false,
    "[User] Feed: Updated volume date",
  );

  updatePagedFeed();
};

export const setPeriod = (period: "day" | "week" | "month" | null) =>
  useFeedParamsStore.setState({ period }, false, "[User] Period: Updated");

function getIdsForPaged() {
  const { filteredFeedIds, filterFeedIdSearch, selectedStackIds } =
    useFeedStore.getState();
  const { searchTerms } = useFeedParamsStore.getState();
  const { deletedArticleIds } = useArticleDeletionStore.getState();
  const deleteIdsSet = new Set(deletedArticleIds);

  let idSets = filteredFeedIds;

  if (selectedStackIds.length > 0) {
    const stackIds = new Set(selectedStackIds);
    idSets = filteredFeedIds.intersection(stackIds);
  }

  // Get base IDs from either search or filtered set
  const baseIds =
    searchTerms.length > 0
      ? filterFeedIdSearch.map(([id]) => id)
      : Array.from(idSets);

  // Filter out deleted IDs
  return baseIds.filter((id) => !deleteIdsSet.has(id));
}

export const setSelectedStackIds = (ids: number[]) => {
  useFeedStore.setState(
    {
      selectedStackIds: ids,
    },
    false,
    "[User] selected a new stack",
  );
  filterBySearchTerms();
};
