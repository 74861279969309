import { GooglyEyesLogo } from "@/components/GooglyEyesLogo";
import {
  useStoryInsightsSubscription,
} from "@/data-access/news/use-story-insights-subscription";
import { cn } from "@/lib/utils";
import { type ReactElement, useCallback, useEffect, useState } from "react";
import {
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogOverlay,
} from "../ui/dialog";
import { JollyTextField } from "../ui/textfield";
import { MetricsStepper } from "./MetricsStepper";
import type { StoryInsightUpdate } from "@/data-access/news/story-insights.gql";

interface StoryInsightsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function StoryInsightPlaygroundModal({
  isOpen,
  onClose,
}: StoryInsightsModalProps): ReactElement {
  const [storyId, setStoryId] = useState("");
  const [whatToKnow, setWhatToKnow] = useState("");
  const [whyItMatters, setWhyItMatters] = useState("");
  const [status, setStatus] = useState("");
  const [metricsHistory, setMetricsHistory] = useState<
    Record<string, string | number>[]
  >([]);
  const [currentStep, setCurrentStep] = useState(-1);
  const [whatToKnowDone, setWhatToKnowDone] = useState(false);
  const [whyItMattersDone, setWhyItMattersDone] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  // const [showClusters, setShowClusters] = useState(false);
  const [autoAdvance, setAutoAdvance] = useState(true);

  const handleUpdate = useCallback((update: StoryInsightUpdate) => {
    // Helper function to clean content from JSON-like wrappers
    const cleanContent = (text: string): string => {
      // Extremely aggressive regex to remove any JSON-like wrappers
      if (!text) return "";

      let cleaned = text.trim();

      // Check if it's a complete JSON object with content field
      try {
        const parsed = JSON.parse(cleaned);
        if (parsed) {
          // Handle array case
          if (Array.isArray(parsed)) {
            return parsed
              .map((item) =>
                typeof item === "object" && item.content ? item.content : item,
              )
              .join(" ");
          }
          // Handle object case
          if (typeof parsed === "object" && parsed.content) {
            return parsed.content;
          }
        }
      } catch (e) {
        // Not valid JSON, continue with regex cleaning
      }

      // Match the exact pattern: "content": "text"
      const contentMatch = cleaned.match(/["']content["']\s*:\s*["'](.+?)["']/);
      if (contentMatch?.[1]) {
        return contentMatch?.[1];
      }

      // Match any JSON-like structure with content
      const jsonLikeMatch = cleaned.match(
        /\{\s*["']?\s*content["']?:?\s*["'](.+?)["']\s*\}/,
      );
      if (jsonLikeMatch?.[1]) {
        return jsonLikeMatch?.[1];
      }

      // If all else fails, just strip obvious JSON markers
      cleaned = cleaned
        .replace(/^\s*\{|\}\s*$/g, "") // Remove outer braces
        .replace(/^\s*["']?content["']?\s*:\s*["']?/g, "") // Remove content prefix
        .replace(/["']?\s*\}\s*$/g, "") // Remove trailing quote and brace
        .replace(/^\s*["']\s*/g, "") // Remove leading quotes with possible space
        .replace(/\s*["']\s*$/g, "") // Remove trailing quotes with possible space
        .replace(/^\s*\[|\]\s*$/g, "") // Remove array brackets
        .replace(/\}\s*\]/g, ""); // Remove trailing brace and bracket

      return cleaned;
    };

    if (update.whatToKnow) {
      try {
        let content = update.whatToKnow;
        // Try to parse as JSON first
        try {
          const parsed =
            typeof update.whatToKnow === "string"
              ? JSON.parse(update.whatToKnow)
              : update.whatToKnow;
          if (parsed.content) {
            content = parsed.content;
          }
        } catch {
          // If parsing fails, clean the content directly
          content = cleanContent(content);
        }

        setWhatToKnow((prevText) =>
          prevText ? `${prevText} ${content}` : content,
        );
      } catch (e) {
        const cleanedContent = cleanContent(update.whatToKnow || "");
        setWhatToKnow((prevText) =>
          prevText ? `${prevText} ${cleanedContent}` : cleanedContent,
        );
      }
    }

    if (update.whyItMatters) {
      try {
        let content = update.whyItMatters;
        // Try to parse as JSON first
        try {
          const parsed =
            typeof update.whyItMatters === "string"
              ? JSON.parse(update.whyItMatters)
              : update.whyItMatters;
          if (parsed.content) {
            content = parsed.content;
          }
        } catch {
          // If parsing fails, clean the content directly
          content = cleanContent(content);
        }
        setWhyItMatters((prevText) =>
          prevText ? `${prevText} ${content}` : content,
        );
      } catch (e) {
        const cleanedContent = cleanContent(update.whyItMatters || "");
        setWhyItMatters((prevText) =>
          prevText ? `${prevText} ${cleanedContent}` : cleanedContent,
        );
      }
      setWhyItMattersDone(true);
    }

    if (update.status) {
      setStatus(update.status);
    }

    if (update.metrics) {
      // Add new metrics to history if they have data
      if (Object.keys(update.metrics).length > 0) {
        setMetricsHistory((prev) => {
          const lastMetrics = prev[prev.length - 1] || {};
          if (JSON.stringify(lastMetrics) !== JSON.stringify(update.metrics)) {
            // New metrics received, update the step if auto-advance is enabled
            if (update.metrics) {
              const metricsRecord = update.metrics.reduce((acc, { key, value }) => {
                acc[key] = value;
                return acc;
              }, {} as Record<string, string | number>);
            
              return [...prev, metricsRecord];
            }
            return prev;
          }
          return prev;
        });
      }
    }
  }, []);

  useEffect(() => {
    // When metrics history changes, advance to the latest step if auto-advance is enabled
    if (metricsHistory.length > 0 && (autoAdvance || currentStep === -1)) {
      setCurrentStep(metricsHistory.length - 1);
    }
  }, [metricsHistory, autoAdvance, currentStep]);

  const handleStoryIdChange = (value: string) => {
    setStoryId(value);
    setIsSubscribed(false);
    setWhatToKnow("");
    setWhyItMatters("");
    setMetricsHistory([]);
    setCurrentStep(-1);
    setStatus("");
    setWhatToKnowDone(false);
    setWhyItMattersDone(false);
    setAutoAdvance(true);
    // setShowClusters(false);
  };

  const handleStoryIdKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && storyId) {
      setIsSubscribed(true);
    }
  };

  const { isLoading, error } = useStoryInsightsSubscription({
    storyId,
    onUpdate: handleUpdate,
    enabled: isSubscribed,
  });

  return (
    <DialogOverlay isOpen={isOpen}>
      <DialogContent
        onClose={onClose}
        size="lg"
        className="bg-white border border-gray-200 rounded-xl shadow-lg p-0 overflow-hidden max-w-4xl"
      >
        <DialogHeader className="bg-gradient-to-r from-indigo-50 via-violet-50 to-indigo-50 p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold text-gray-900 tracking-tight">
              Story Insight Playground
            </h2>
            <div className="flex items-center space-x-4">
              <GooglyEyesLogo className="h-10 w-10 text-violet-600 mr-10" />
            </div>
          </div>
        </DialogHeader>
        <DialogBody className="p-6 bg-white">
          <div className="flex flex-col gap-6">
            {/* Logo section for empty state */}
            {!storyId && (
              <GooglyEyesLogo className=" text-violet-500 opacity-90" />
            )}
            <JollyTextField
              label="Story ID"
              value={storyId}
              onChange={handleStoryIdChange}
              onKeyDown={handleStoryIdKeyDown}
              placeholder="Enter story ID and press Enter..."
              size="sm"
              className="bg-white rounded-lg border border-gray-300 shadow-sm focus-within:border-violet-500 focus-within:ring-1 focus-within:ring-violet-500 transition-all duration-300 mx-auto"
            />
            {status && (
              <div className="bg-gray-50 rounded-lg p-5 shadow-md animate-in slide-in-from-bottom duration-500 border border-gray-200">
                <div className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200">
                  <p
                    className={cn(
                      "text-sm font-bold flex items-center gap-2",
                      status.includes("Complete")
                        ? "text-green-600"
                        : "text-blue-900 animate-pulse",
                    )}
                  >
                    {status}
                  </p>
                  {status.includes("Processing") && (
                    <div className="flex items-center">
                      <GooglyEyesLogo className="h-8 w-8 text-violet-500 animate-pulse" />
                    </div>
                  )}
                  {status.includes("Starting") && (
                    <div className="flex items-center">
                      <GooglyEyesLogo className="h-8 w-8 text-indigo-500 animate-pulse" />
                    </div>
                  )}
                </div>
                {status.includes("Complete") && (
                  <div className="mt-3 text-xs text-green-700 font-bold flex items-center gap-1 bg-green-50 p-2 rounded-md">
                    <div className="w-2 h-2 bg-green-500 rounded-full" />
                    Analysis complete
                  </div>
                )}

                {metricsHistory.length > 0 && (
                  <div className="mt-4">
                    <MetricsStepper
                      metricsHistory={metricsHistory}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      autoAdvance={autoAdvance}
                      setAutoAdvance={setAutoAdvance}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="space-y-4">
              {error ? (
                <div className="bg-red-50 rounded-lg p-4 shadow-md animate-in fade-in duration-300 border border-red-200">
                  <div className="flex items-center space-x-3">
                    <div className="w-3 h-3 bg-red-500 rounded-full animate-ping" />
                    <p className="text-red-700 font-bold">
                      Oops! Something went wrong.
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-red-600 pl-6">
                    Please try again with a different story ID.
                  </p>
                </div>
              ) : (
                <>
                  <div className="space-y-2">
                    <h3 className="text-base font-bold text-gray-900 mb-2">
                      What to Know
                    </h3>
                    <div
                      className={cn(
                        "bg-white rounded-lg p-5 min-h-[120px] border border-gray-200 shadow-md",
                        isLoading &&
                          !whatToKnowDone &&
                          !whatToKnow &&
                          "animate-pulse",
                      )}
                    >
                      {whatToKnow ? (
                        <p className="text-gray-800 leading-relaxed text-base">
                          {whatToKnow}
                        </p>
                      ) : (
                        <div className="space-y-2">
                          {isLoading ? (
                            <>
                              <div className="h-4 bg-blue-100 rounded animate-pulse w-3/4" />
                              <div className="h-4 bg-indigo-100 rounded animate-pulse w-1/2 delay-100" />
                              <div className="h-4 bg-violet-100 rounded animate-pulse w-2/3 delay-200" />
                            </>
                          ) : isSubscribed ? (
                            <div className="flex items-center space-x-2 text-violet-700">
                              <GooglyEyesLogo className="h-6 w-6 text-violet-500 mr-2" />
                              <div className="w-2 h-2 bg-violet-500 rounded-full animate-pulse delay-100" />
                              <div className="w-2 h-2 bg-violet-500 rounded-full animate-pulse delay-200" />
                            </div>
                          ) : (
                            <span className="text-gray-500">
                              Enter a story ID and press Enter to see
                              insights...
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="space-y-2">
                    <h3 className="text-base font-bold text-gray-900 mb-2">
                      Why it Matters
                    </h3>
                    <div
                      className={cn(
                        "bg-white rounded-lg p-5 min-h-[120px] border border-gray-200 shadow-md",
                        isLoading &&
                          !whyItMattersDone &&
                          !whyItMatters &&
                          "animate-pulse",
                      )}
                    >
                      {whyItMatters ? (
                        <p className="text-gray-800 leading-relaxed text-base">
                          {whyItMatters}
                        </p>
                      ) : (
                        <div className="space-y-2">
                          {isLoading ? (
                            <>
                              <div className="h-4 bg-violet-100 rounded animate-pulse w-2/3" />
                              <div className="h-4 bg-indigo-100 rounded animate-pulse w-4/5 delay-100" />
                              <div className="h-4 bg-blue-100 rounded animate-pulse w-3/4 delay-200" />
                            </>
                          ) : isSubscribed ? (
                            <div className="flex items-center space-x-2 text-violet-700">
                              <GooglyEyesLogo className="h-6 w-6 text-violet-500 mr-2" />
                              <div className="w-2 h-2 bg-violet-500 rounded-full animate-pulse delay-100" />
                              <div className="w-2 h-2 bg-violet-600 rounded-full animate-pulse delay-200" />
                            </div>
                          ) : (
                            <span className="text-gray-500">
                              Enter a story ID and press Enter to see
                              insights...
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </DialogBody>
      </DialogContent>
    </DialogOverlay>
  );
}
