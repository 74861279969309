import { useCallback, useLayoutEffect, useRef, useState } from "react";
import type { MouseEvent as ReactMouseEvent } from "react";

interface UseResizeHandleOptions {
  minWidth: number;
  maxWidth: number;
  onWidthChange: (width: number) => void;
  initialWidth: number;
}

export const useResizeHandle = ({
  minWidth,
  maxWidth,
  onWidthChange,
  initialWidth,
}: UseResizeHandleOptions) => {
  const [isResizing, setIsResizing] = useState(false);
  const startXRef = useRef<number>(0);
  const startWidthRef = useRef<number>(initialWidth);

  const handleMouseDown = useCallback(
    (e: ReactMouseEvent) => {
      e.preventDefault();
      setIsResizing(true);
      startXRef.current = e.clientX;
      startWidthRef.current = initialWidth;
    },
    [initialWidth],
  );

  const handleMouseMove = useCallback(
    (e: globalThis.MouseEvent) => {
      if (!isResizing) return;

      requestAnimationFrame(() => {
        const delta = e.clientX - startXRef.current;
        const newWidth = Math.min(
          Math.max(startWidthRef.current + delta, minWidth),
          maxWidth,
        );
        onWidthChange(newWidth);
      });
    },
    [isResizing, maxWidth, minWidth, onWidthChange],
  );

  const handleMouseUp = useCallback(() => {
    if (isResizing) {
      setIsResizing(false);
    }
  }, [isResizing]);

  useLayoutEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, handleMouseMove, handleMouseUp]);

  return {
    handleMouseDown,
    isResizing,
  };
};
