import { graphql } from "@/data-access/graphql";

export const StoryInsightsSubscription = graphql(`
  subscription StoryInsights($storyId: ID!) {
    storyInsights(storyId: $storyId) {
      storyId
      startDate
      endDate
      status
      metrics {
        key
        value
      }
      whatToKnow
      whyItMatters
    }
  }
`);

export type StoryInsightUpdate = ReturnType<
  Exclude<typeof StoryInsightsSubscription.__apiType, undefined>
>["storyInsights"]